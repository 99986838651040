<template>
    <div>
        <div class="acc-subscribes">
            <div class="acc-subscribes__title">
                Управление подписками
            </div>
            <div class="acc-subscribes__cards">
                <div class="acc-subscribes__card" v-for="item in cards" :key="item.id">
                    <div class="acc-subscribes__card-img">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="acc-subscribes__card-title">
                        {{item.title}}
                    </div>
                    <button class="acc-subscribes__button">
                        Подписаться
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bonuses: 213,
            cards: [
                {
                    id: 0,
                    img: require('../../assets/icons/acc/sub1.svg'),
                    title: 'Новые поступления'
                },
                {
                    id: 1,
                    img: require('../../assets/icons/acc/sub2.svg'),
                    title: 'Распродажи и акции'
                },
                {
                    id: 2,
                    img: require('../../assets/icons/acc/sub1.svg'),
                    title: 'COVID-19'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.acc-subscribes {
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #2C3131;
    }

    &__cards {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 20px;
        flex-wrap: wrap;
    }

    &__card {
        width: 274px;
        height: 168px;
        background: linear-gradient(147.28deg, #5AE1E1 -35.63%, #D4FCF2 151.87%);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-title {
            margin-top: 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #157594;
        }


    }

    &__button {
        margin-top: 10px;
        width: 168px;
        height: 51px;
        background: #FE8326;
        border-radius: 8px;
        border: none;
        color: #fff;
        font-size: 14px;
        line-height: 17px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            background-color: #fff;
            border: 1px solid #FE8326;
            color: #FE8326;
        }
    }
}
</style>