<template>
    <div class="singleShop" v-if="renderComponent">
        <div class="content">
            <div class="singleCategory__breadcrumbs">
                <span><a href="">Главная</a></span>
                <span><a href=""> • Магазин</a></span>
                <span><a href=""> • Каталог поставщика {{ shop_info.name }}</a></span>
            </div>
            <div class="singleShop__header">
                <div class="singleShop__title">
                    Каталог поставщика {{ shop_info.name }}
                </div>
                <div class="singleShop__link">
                    <div class="singleShop__header-icon">
                        <img src="../assets/icons/card/like.svg" alt="">
                    </div>
                    <span>В избранные</span>
                </div>
                <div class="singleShop__link">
                    <div class="singleShop__header-icon">
                        <img src="../assets/icons/card/share.svg" alt="">
                    </div>
                    <span>Поделиться</span>
                </div>
            </div>
            <div class="singleShop__info">
                <StarsRating :rating="shop_info.rating"></StarsRating>
                <div class="singleShop__info__item">
                    <div class="singleShop__info__icon">
                        <img src="../assets/icons/card/bill.svg" alt="">
                    </div>
                    <div class="singleShop__info__text">
                        Мин.сумма доставки: {{ shop_info.minAmount }} ₸
                    </div>
                </div>
                <div class="singleShop__info__item">
                    <div class="singleShop__info__icon">
                        <img src="../assets/icons/basket/car.svg" alt="">
                    </div>
                    <div class="singleShop__info__text">
                        Доставка - {{ shop_info.deliveryTime }}, {{ shop_info.deliveryCost }}
                    </div>
                </div>
            </div>
            <div class="home__category-items">
                <div class="home__category-item" v-for="item in category" :key="item.id">
                    <div class="home__category-img">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="home__category-text">
                        {{ item.text }}
                    </div>
                </div>
            </div>

            <div class="singleShop__main">
                <div class="singleShop__menu">
                    <div class="singleShop__clear">
                        <div class="singleShop__clear-icon">
                            <img src="../assets/icons/components_icon/close.svg" alt="">
                        </div>
                        <span>Очистить фильтры</span>
                    </div>

                    <div class="singleCategory__type">
                        <div class="singleCategory__type-title">Бренды</div>
                        <div class="singleCategory__type-items">
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Мегастрой</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Мегастрой</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Мегастрой</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Мегастрой</span>
                            </div>
                        </div>
                        <div class="singleCategory__more">
                            Посмотреть все
                        </div>
                    </div>
                </div>
                <div class="singleCategory__main">
                    <div class="singleCategory__main-input">
                        <select>
                            <div class="singleCategory__main-input-sort">
                                <img src="../assets/icons/components_icon/sort.svg" alt="">
                            </div>
                            <optgroup>
                                <option value="Цена по возрастанию">Цена по возрастанию</option>
                                <option value="Цена по убыванию">Цена по убыванию</option>
                            </optgroup>
                        </select>
                        <div class=" singleCategory__main-input-filter" @click="filter = true">
                            <div class="singleCategory__main-input-icon">
                                <img src="../assets/icons/mobile/filters.svg" alt="">
                            </div>
                            Фильтр
                        </div>

                        <!-- mobile filter modal -->
                        <div class="singleCategory__filter" v-show="filter">
                            <div class="singleCategory__filter-header">
                                <div class="singleCategory__filter-header-title">
                                    Фильтр
                                </div>
                                <div class="singleCategory__filter-header-close" @click="filter = false">
                                    <img src="../assets/icons/components_icon/close.svg" alt="">
                                </div>
                            </div>
                            <div class="singleCategory__filter-main">
                                <div class="singleCategory__filter-categories">
                                    <div class="singleCategory__filter-category singleCategory__filter-category-cost">
                                        <p>Цена</p>
                                        <div class="singleCategory__filter-category-inputs">
                                            <input type="number">
                                            <input type="number">
                                        </div>
                                    </div>
                                    <div class="singleCategory__filter-category" v-for="(item, i) in filter_items"
                                        :key="i">
                                        <div class="singleCategory__filter-category-top"
                                            @click="item.isOpen = !item.isOpen">
                                            <p>{{ item.title }}</p>
                                            <div class="singleCategory__filter-category-arrow"
                                                :class="{ 'singleCategory__filter-category-arrow-reverse': item.isOpen }">
                                                <img src="../assets/icons/home/arrow_right.png" alt="">
                                            </div>
                                        </div>
                                        <div v-if="item.isOpen">
                                            <div class="singleCategory__filter-category-bottom"
                                                v-for="i in item.sub_titles" :key="i.id">
                                                <div class="singleCategory__filter-category-bottom-checkbox basket__info-input"
                                                    v-bind:class="{ filter_checkbox_checked: i.isChecked }">
                                                    <input type="checkbox" value="newsletter"
                                                        @click="i.isChecked = !i.isChecked">
                                                </div>
                                                <p>{{ i.title }}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- mobile filter modal end! -->
                    </div>
                    <div class="singleCategory__main-items">
                        <div class="singleCategory__main-item" v-for="item in cards" :key="item.id">
                            <Card @add-basket-card="add_basket_card" :is-liked="false" :fixcount="item.fix_count"
                                :image="item.image" :id="item.id" :discription="item.discription"
                                :salePersents="item.sale_persents" :count="item.count" :price="item.price"
                                :salePrice="item.old_price" :link="item.link"></Card>
                        </div>
                    </div>
                    <div class="singleCategory__main-pagination">
                        <div class="singleCategory__main-count">
                            <span class="active_page">1</span>
                            <span>2</span>
                            <span>3</span>
                            <span>4</span>
                        </div>
                        <div class="singleCategory__main-icon">
                            <img src="../assets/icons/components_icon/next_page.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { Swiper } from "swiper/vue/swiper";
// import { SwiperSlide } from "swiper/vue/swiper-slide";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import StarsRating from '../components/StarsRating.vue'
import Card from '../components/GoodsCard.vue'
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            isCheckboxChecked: false,
            filter: false,
            renderComponent: true,
            filter_items: [
                {
                    id: 0,
                    title: 'Категории',
                    isChecked: false,
                    sub_titles: [
                        {
                            id: 0,
                            title: 'Лекарственные средства ',
                            isChecked: false,
                        },
                        {
                            id: 1,
                            title: 'Витамины и БАД',
                            isChecked: false,
                        },
                        {
                            id: 2,
                            title: 'Оптика',
                            isChecked: false,
                        },
                        {
                            id: 3,
                            title: 'Ортопедия',
                            isChecked: false,
                        },
                        {
                            id: 4,
                            title: 'Медицинские приборы',
                            isChecked: false,
                        },
                        {
                            id: 4,
                            title: 'Медицинские изделия',
                            isChecked: false,
                        },
                    ],

                },
                {
                    id: 2,
                    title: 'Бренды',
                    isChecked: false,
                    sub_titles: [
                        {
                            id: 0,
                            title: 'Боли в горле',
                            isChecked: false,
                        },
                        {
                            id: 1,
                            title: 'Отхаркивающие ',
                            isChecked: false,
                        },
                        {
                            id: 2,
                            title: 'От вирусов и микробов',
                            isChecked: false,
                        },
                        {
                            id: 3,
                            title: 'Простуда и грипп',
                            isChecked: false,
                        },
                    ],

                },
                {
                    id: 3,
                    title: 'Медицинские приборы',
                    isChecked: false,
                    sub_titles: [
                        {
                            id: 0,
                            title: 'Простуда и грипп',
                            isChecked: false,
                        },
                        {
                            id: 1,
                            title: 'От температуры ',
                            isChecked: false,
                        },
                        {
                            id: 2,
                            title: 'Насморк',
                            isChecked: false,
                        },
                    ],

                },
            ],
            cards: [
                {
                    id: 0,
                    image: require('../assets/img/good2.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: 19390,
                    old_price: '33 490 ₸',
                    count: 12,
                    link: 'product',
                    fix_count: 12,
                },
                {
                    id: 1,
                    image: require('../assets/img/good3.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: 19390,
                    old_price: '33 490 ₸',
                    count: 12,
                    link: 'product',
                    fix_count: 12,
                },
                {
                    id: 2,
                    image: require('../assets/img/good2.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: 19390,
                    old_price: '33 490 ₸',
                    count: 12,
                    link: 'product',
                    fix_count: 12,
                },
                {
                    id: 3,
                    image: require('../assets/img/good2.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: 19390,
                    old_price: '33 490 ₸',
                    count: 12,
                    link: 'product',
                    fix_count: 12,
                },
                {
                    id: 4,
                    image: require('../assets/img/good3.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: 19390,
                    old_price: '33 490 ₸',
                    count: 12,
                    link: 'product',
                    fix_count: 12,
                },
                {
                    id: 5,
                    image: require('../assets/img/good3.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: 19390,
                    old_price: '33 490 ₸',
                    count: 12,
                    link: 'product',
                    fix_count: 12,
                },
                {
                    id: 6,
                    image: require('../assets/img/good2.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: 19390,
                    old_price: '33 490 ₸',
                    count: 12,
                    link: 'product',
                    fix_count: 12,
                },
                {
                    id: 7,
                    image: require('../assets/img/good2.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: 19390,
                    old_price: '33 490 ₸',
                    count: 12,
                    link: 'product',
                    fix_count: 12,
                },

            ],
            breakpoints: {
                330: {
                    slidesPerView: 2,
                },
                576: {
                    slidesPerView: 3,
                },
                991: {
                    slidesPerView: 5,
                }
            },
            shopNavigation: {
                prevEl: '.market-swiper-left',
                nextEl: '.market-swiper-right',
            },
            market_category: [
                {
                    id: 0,
                    img: require('../assets/icons/market/item_1.png'),
                    title: 'Избранные товары',
                },
                {
                    id: 1,
                    img: require('../assets/icons/market/item_2.png'),
                    title: 'Строительные материалы',
                    count: '5083',
                },
                {
                    id: 2,
                    img: require('../assets/icons/market/item_3.png'),
                    title: 'Электроинстру-менты',
                    count: '5083',
                },
                {
                    id: 3,
                    img: require('../assets/icons/market/item_4.png'),
                    title: 'Электроинстру-менты',
                    count: '5083',
                },
                {
                    id: 4,
                    img: require('../assets/icons/market/item_5.png'),
                    title: 'Электроинстру-менты',
                    count: '5083',
                },
                {
                    id: 5,
                    img: require('../assets/icons/market/item_6.png'),
                    title: 'Электроинстру-менты',
                    count: '5083',
                },
                {
                    id: 6,
                    img: require('../assets/icons/market/item_7.png'),
                    title: 'Электроинстру-менты',
                    count: '5083',
                },
                {
                    id: 7,
                    img: require('../assets/icons/market/item_8.png'),
                    title: 'Электроинстру-менты',
                    count: '5083',
                },
                {
                    id: 8,
                    img: require('../assets/icons/market/item_9.png'),
                    title: 'Электроинстру-менты',
                    count: '5083',
                },
            ],
            shop_info: [],
            category: [
                {
                    id: 0,
                    img: require('../assets/img/home/category1.1.png'),
                    text: 'Мед.техника',
                },
                {
                    id: 1,
                    img: require('../assets/img/home/category2.1.png'),
                    text: 'Защитные средства ',
                },
                {
                    id: 2,
                    img: require('../assets/img/home/category3.1.png'),
                    text: 'Для малышей ',
                },
                {
                    id: 3,
                    img: require('../assets/img/home/category4.1.png'),
                    text: 'Витамины',
                },
                {
                    id: 4,
                    img: require('../assets/img/home/category5.1.png'),
                    text: 'Оптика',
                },
                {
                    id: 5,
                    img: require('../assets/img/home/category1.1.png'),
                    text: 'Мед.техника',
                },
                {
                    id: 6,
                    img: require('../assets/img/home/category2.1.png'),
                    text: 'Защитные средства ',
                },
                {
                    id: 7,
                    img: require('../assets/img/home/category3.1.png'),
                    text: 'Для малышей ',
                },
                {
                    id: 8,
                    img: require('../assets/img/home/category4.1.png'),
                    text: 'Витамины',
                },
                {
                    id: 9,
                    img: require('../assets/img/home/category5.1.png'),
                    text: 'Оптика',
                },

            ],
        }
    },
    async created() {
        await this.render()
    },
    computed: {
        ...mapGetters([
            'STATE'
        ])
    },
    watch: {
        $route() {
            this.render()
            console.log(this.$route, 'asdasdd');
        }
    },
    methods: {
        render() {
            this.renderComponent = false
            window.scrollTo(0, 0)
            this.setShopInfo()
            this.renderComponent = true
        },
        setShopInfo() {
            this.shop_info = this.STATE.singleMarketShop
        },
        add_basket_card() {
            console.log('asdasd');
        },
        onSwiper(swiper) {
            console.log(swiper)
        },
        onSlideChange() {
            console.log('slide change')
        },

    },
    // Swiper, SwiperSlide,
    components: {
        Card, StarsRating,
    }

}
</script>

<style lang="scss">
@import '../assets/style/singeShop.scss';
</style>