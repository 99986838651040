<template>
  <div class="product" v-if="product_info">
    <Modal v-show="isModal" @open-modal="isModal = false"></Modal>
    <div class="content">
      <h2 class="product__name">
        {{ product.name }}
      </h2>
      <div class="product__info">
        <div class="product__info-good desctop">
          <div class="product__info-code">
            Код товара: <span>{{ product.articul }}</span>
          </div>

          <div class="product__info-rating">
            <StarsRating :rating="product_info.raiting || 0"></StarsRating>
          </div>

          <div class="product__info-feedback">
            <span> {{ product_info.feedback_count || 0 }} отзывов </span>
            <div class="devider"></div>
            <span>{{ product_info.quetion_count || 0 }} вопроса</span>
          </div>
        </div>
        <div class="product__info-links">
          <div class="product__info-link">
            <div
              class="product__info-icon product__info-icon-like"
              @click="addProductToFavorite()"
              :class="{ product__info_empty: product.isLiked }"
            ></div>

            <div class="product__info-text">В избранные</div>
          </div>
          <div class="product__info-link">
            <div class="product__info-icon">
              <img src="../assets/icons/share.svg" alt="" />
            </div>
            <div class="product__info-text">Поделиться</div>
          </div>
        </div>
      </div>
      <div class="product__good">
        <div class="product__picture">
          <div class="product__picture-big">
            <img :src="$cdn + product.image" alt="" />
          </div>
        </div>
        <div class="desctop">
          <div class="product__about">
            <div
              class="product__about-item"
              v-for="(item, i) in product_info.info"
              :key="i"
            >
              <div class="product__about-info">
                {{ item.title }}
              </div>
              <div class="product__about-info">
                {{ item.key }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product__feedbacks">
        <div class="product__feedbacks-titles">
          <div
            class="product__feedbacks-title"
            v-for="title in titles"
            :key="title.id"
            @click="clickOnTitle(title.id)"
            v-bind:class="{ active: title.id === count }"
          >
            {{ title.text }}
          </div>
        </div>
        <div class="product__mobile">
          <div class="product__shop" v-show="count === 0">
            <div class="product__shop-item" v-for="item in 5" :key="item.id">
              <div class="product__shop-item-left">
                <div class="product__shop-name">
                  <p>Название магазина</p>
                  <StarsRating :rating="3"></StarsRating>
                </div>
                <div class="product__shop-name">
                  <p>Доставка - завтра, <span>21 мая</span>, бесплатно</p>
                  <p>Самовызов - сегодня</p>
                </div>
              </div>
              <div class="product__shop-item-right">
                <button
                  class="product__shop-button"
                  @click="addProductToBasket(item)"
                >
                  В корзину 12 шт
                </button>
                <div class="product__shop-price">19 390 ₸/шт</div>
                <div class="product__shop-allcount">232 680 ₸</div>
              </div>
            </div>
          </div>
        </div>
        <div class="product__desctop">
          <div class="product__shop" v-show="count === 0">
            <div class="product__shop-item" v-for="item in 5" :key="item.id">
              <div class="product__shop-name">
                <p>Название магазина</p>
                <StarsRating :rating="3"></StarsRating>
              </div>
              <div class="product__shop-name">
                <p>Доставка - завтра, 21 мая, бесплатно</p>
                <p>Самовызов - сегодня</p>
              </div>
              <div class="product__shop-price">19 390 ₸/шт</div>
              <button
                class="product__shop-button"
                @click="addProductToBasket(item)"
              >
                В корзину
              </button>
              <div class="product__shop-allcount">232 680 ₸</div>
            </div>
          </div>
        </div>
        <div class="product__comments" v-show="count === 1">
          <FeedbackBlock
            :rating="product_info.raiting"
            :allFeedback="1000"
            :feedbacks="feedbacks_items"
            @open-feedback-modal="openFeedbackModal"
          ></FeedbackBlock>
          <div class="product__comments-sort">
            <p>Сортировать по</p>
            <select placeholder="Сортировать" v-model="select_value">
              <option value="all">Все</option>
              <option value="better">Сначала хорошие</option>
              <option value="worst">Сначала плохие</option>
            </select>
          </div>
          <div class="product__comments-items" v-if="comments">
            <div
              class="product__comments-item"
              v-for="item in comments"
              :key="item"
            >
              <div class="product__comments-ratings">
                <StarsRating
                  :rating="item.rating"
                  :numbers="false"
                ></StarsRating>
                <div class="product__comments-name">
                  {{ item.user }}
                </div>
                <div class="product__comments-date">
                  {{ item.time }}
                </div>
              </div>
              <div class="product__comments-wrapper">
                <div class="product__comments-content">
                  <div class="product__comments-title">Отзыв</div>
                  <div class="product__comments-text">
                    {{ item.advantages }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="button button_comments" v-if="comments.length > 7">
            Показать ещё
          </button>
        </div>
        <div class="product__characteristics" v-show="count === 2">
          <div class="product__about">
            <div
              class="product__about-item"
              v-for="item in product_info.info"
              :key="item.id"
            >
              <div class="product__about-info">
                {{ item.title }}
              </div>
              <div class="product__about-devider"></div>
              <div class="product__about-info">
                {{ item.key }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="product__feedbacks-feedback"
          v-show="count === 3"
          v-html="product.description"
        ></div>
      </div>

      <div class="product__same">
        <div class="product__same-title">С этим товаром берут</div>
        <div class="product__same-items">
          <div class="product__same-item" v-for="item in cards" :key="item.id">
            <Card
              :is-liked="false"
              :fixcount="item.fix_count"
              :image="item.image"
              :id="item.id"
              :discription="item.discription"
              :salePersents="item.sale_persents"
              :count="item.count"
              :price="item.price"
              :salePrice="item.old_price"
              :link="item.link"
            ></Card>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isCommentModal">
      <CommentModal
        @open-comment-modal="openFeedbackModal"
        @send-info="sendInfo"
      ></CommentModal>
    </div>
  </div>
</template>

<script>
import StarsRating from "./StarsRating.vue";
import Card from "./GoodsCard.vue";
import Modal from "./NotBuyModal.vue";
import FeedbackBlock from "./FeedbackBlock.vue";

import CommentModal from "./CommentModal.vue";
import { mapGetters, mapActions } from "vuex";
// import ProductDiscription from './ProductDiscription.vue'
// import ProductShow from './ProductShow.vue'
export default {
  data() {
    return {
      isCommentModal: false,
      select_value: null,
      isActive: false,
      isModal: false,
      count: 0,
      bigPic: require("../assets/img/good.png"),
      product: [],
      product_info: [], // дополнительная информация
      comments: [], // отзывы
      sorted_comments: [],
      pictures: [
        {
          id: 0,
          img: require("../assets/img/good.png"),
        },
        {
          id: 1,
          img: require("../assets/img/good2.png"),
        },
        {
          id: 2,
          img: require("../assets/img/good3.png"),
        },
        {
          id: 4,
          img: require("../assets/img/good2.png"),
        },
        {
          id: 5,
          img: require("../assets/img/good.png"),
        },
        {
          id: 6,
          img: require("../assets/img/good3.png"),
        },
      ],
      discription: [
        {
          id: 0,
          text: "Max крутящий момент",
          value: "24 Нм",
        },
        {
          id: 1,
          text: "Тип аккумулятора",
          value: "Li-lon",
        },
        {
          id: 2,
          text: "Напряжение аккумулятора",
          value: "10.8 В",
        },
        {
          id: 3,
          text: "Max диаметр сверления (металл)",
          value: "10 мм",
        },
        {
          id: 4,
          text: "Max диаметр сверления (дерево)",
          value: "21 мм",
        },
        {
          id: 5,
          text: "Вес нетто",
          value: "1 кг",
        },
        {
          id: 6,
          text: "Тип",
          value: "аккумуляторный",
        },
        {
          id: 7,
          text: "Тип двигателя",
          value: "щеточный",
        },
      ],
      titles: [
        {
          id: 0,
          text: "Поставщики",
          open: false,
        },
        {
          id: 1,
          text: "Отзывы",
          open: false,
        },
        {
          id: 2,
          text: "Характеристики",
          open: false,
        },
        {
          id: 3,
          text: "Описание",
          open: false,
        },
      ],
      cards: [
        {
          id: 0,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 1,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 2,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 3,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
      ],
      feedbacks_items: [
        {
          id: 0,
          number: 5,
          count: 258,
        },
        {
          id: 1,
          number: 4,
          count: 325,
        },
        {
          id: 2,
          number: 3,
          count: 123,
        },
        {
          id: 3,
          number: 2,
          count: 199,
        },
        {
          id: 4,
          number: 1,
          count: 95,
        },
      ],
    };
  },
  watch: {
    select_value(newValue) {
      switch (newValue) {
        case "all":
          this.comments.sort(function (a, b) {
            return a.id - b.id;
          });
          break;
        case "better":
          this.comments.sort(function (a, b) {
            return b.rating - a.rating;
          });
          break;
        case "worst":
          this.comments.sort(function (a, b) {
            return a.rating - b.rating;
          });
          break;
      }
    },
  },
  async created() {
    window.scrollTo(0, 0);
    await this.$axios
      .get(`/products?slug=${this.$route.params.id}`)
      .then((res) => (this.product = res.data[0]))
      .catch((err) => console.log(err));
  },
  methods: {
    sendInfo(data) {
      console.log("feedbakc", data);

      this.POST_FEEDBACK({
        id: Date.now(),
        user: "New User " + Date.now(),
        time: "26.09.2022",
        rating: data.rating,
        advantages: data.text,
      });
      this.clickOnTitle(1);
      console.log("новый отзыв это", this.$store.state.allFeedbacks);
    },
    openFeedbackModal() {
      this.isCommentModal = !this.isCommentModal;
      console.log("openFeedbackModal 123");
    },
    ...mapActions([
      "POST_FEEDBACK",
      "GET_SINGLE_PRODUCT_INFO",
      "GET_ALL_PRODUCTS_INFO",
      "GET_ALL_FEEDBACKS",
    ]),
    async getSingleProduct(id) {
      let info = this.$store.state.allProductInfo;
      for (let i = 0; i < info.length; i++) {
        if (info[i].id == id) {
          this.product_info = info[i];
        }
      }
    },
    addProductToFavorite() {
      this.product.isLiked = !this.product.isLiked;
      if (this.product.isLiked == true) {
        this.$store.commit("SET_LIKED", this.product);
      } else {
        this.$store.commit("DELETE_LIKED_GOOD", this.product.id);
      }
    },
    addProductToBasket(item) {
      this.$store.commit("SET_CARD", item);
      console.log("товар добавлен");
      alert("Ваш товар добавлен");
    },
    async clickOnTitle(id) {
      this.count = id;
      this.isActive = !this.isActive;
      if (id == 1) {
        await this.GET_ALL_FEEDBACKS();
        this.comments = this.STATE.allFeedbacks;
      } else {
        console.log("123");
      }
    },
  },

  computed: {
    ...mapGetters(["STATE"]),
  },
  props: [
    "countOfQuestions",
    "countOfFeedbacks",
    "starRating",
    "productCode",
    "fullName",
  ],
  components: {
    StarsRating,
    Card,
    Modal,
    FeedbackBlock,
    CommentModal,
  },
};
</script>

<style lang="scss">
.product__desctop {
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
}

.product__mobile {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.product__info {
  &-icon {
    height: 16px;
    width: 20px;

    &-like {
      background-image: url("../assets/icons/card/like.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &_empty {
    background-image: url("../assets/icons/card/like_filled.svg");
  }
}

@import "../assets/style/product.scss";
</style>