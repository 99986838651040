<template>
  <div class="header">
    <Modal
      v-show="isModal"
      @open-modal="(isModal = false), (iconX = false)"
    ></Modal>
    <CitiesModal
      v-show="isCitiesModal"
      @select-city="selectCity"
      @open-cities-modal="openCitiesModal()"
    >
    </CitiesModal>
    <div class="header__up">
      <div class="content">
        <div class="header__wrapper">
          <div class="header__city" @click="isCitiesModal = true">
            <div class="header__icon">
              <img
                src="../assets/icons/location_black.png"
                alt="Location Icon"
              />
            </div>
            <div class="header__name">
              {{ city }}
            </div>
          </div>
          <ul class="header__list">
            <li
              class="header__item"
              v-for="item in list"
              :key="item.id"
              @click="routeTo(item.link)"
            >
              {{ item.text }}
            </li>
          </ul>
          <div class="header__phone">
            <SelectComponent
              :type="'lang'"
              :options="languages"
              @select="getLang"
            ></SelectComponent>
          </div>
        </div>
      </div>
    </div>
    <div class="header__down">
      <div class="content">
        <div class="header__wrapper">
          <div class="header__logo" @click="routeToHome">
            <img src="../assets/img/main_logo.png" alt="Main logo" />
          </div>
          <div class="header__catalog" @click="activatedBurgerMenu()">
            <div class="header__burger">
              <img
                v-if="!iconX"
                src="../assets/icons/catalog_button_logo.svg"
                alt=""
              />
              <img v-else src="../assets/icons/x.png" alt="" />
            </div>
            <p>Каталог</p>
          </div>

          <!-- старт поисковик по сайту  -->
          <div class="header__search">
            <input
              type="text"
              style="outline: none"
              placeholder="Поиск"
              v-model="siteSearch"
            />
            <div class="header__button" @click="searchResult(siteSearch)">
              <div class="header__button-img">
                <img src="../assets/icons/search_white.png" alt="Search" />
              </div>
            </div>
          </div>
          <!-- конец поисковик по сайту  -->

          <div class="header__actives">
            <div class="header__active" @click="routeToBasket">
              <div class="header__icon">
                <img src="../assets/icons/buy.png" alt="" />
              </div>
              <p>Корзина</p>
              <div class="header__active-count" v-show="basket_count">
                {{ getCountCard }}
              </div>
            </div>
            <div class="header__active">
              <div class="header__icon" @click="$router.push('/likeds')">
                <img src="../assets/icons/like.png" alt="" />
              </div>
              <p>Избранное</p>
              <div class="header__active-count" v-show="liked_count">
                {{ getCountLiked }}
              </div>
            </div>
            <div class="header__active" v-if="getIsAuth">
              <div
                class="header__icon header__icon-active"
                @click="$router.push('/acc')"
              >
                <img src="../assets/icons/login.png" alt="" />
              </div>
              <p>Профиль</p>
            </div>
            <div class="header__active" v-else>
              <div
                class="header__icon header__icon-active"
                @click="$router.push('/auth')"
              >
                <img src="../assets/icons/login.png" alt="" />
              </div>
              <p>Войти</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-mobile">
      <div class="footer-mobile__items">
        <router-link
          to="/"
          class="footer-mobile__item"
          @click="mobile_menu_open = 0"
        >
          <div
            class="footer-mobile__icon"
            v-bind:class="{ activeMenu: mobile_menu_open === 0 }"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.338 0.833116C12.9707 0.502553 12.4941 0.319641 12 0.319641C11.5059 0.319641 11.0293 0.502553 10.662 0.833116L0 10.4291V20.8291C0 21.6778 0.337142 22.4917 0.937258 23.0919C1.53737 23.692 2.35131 24.0291 3.2 24.0291H20.8C21.6487 24.0291 22.4626 23.692 23.0627 23.0919C23.6629 22.4917 24 21.6778 24 20.8291V10.4291L13.338 0.833116ZM15 22.0261H9V17.0001C9 16.2045 9.31607 15.4414 9.87868 14.8788C10.4413 14.3162 11.2044 14.0001 12 14.0001C12.7956 14.0001 13.5587 14.3162 14.1213 14.8788C14.6839 15.4414 15 16.2045 15 17.0001V22.0261ZM22 20.8261C22 21.1444 21.8736 21.4496 21.6485 21.6746C21.4235 21.8997 21.1183 22.0261 20.8 22.0261H17V17.0001C17 15.674 16.4732 14.4023 15.5355 13.4646C14.5979 12.5269 13.3261 12.0001 12 12.0001C10.6739 12.0001 9.40215 12.5269 8.46447 13.4646C7.52678 14.4023 7 15.674 7 17.0001V22.0261H3.2C2.88174 22.0261 2.57652 21.8997 2.35147 21.6746C2.12643 21.4496 2 21.1444 2 20.8261V11.3191L12 2.31912L22 11.3191V20.8261Z"
                fill="#282828"
              />
            </svg>
          </div>
          <div
            class="footer-mobile__text"
            v-bind:class="{ activeMenu: mobile_menu_open === 0 }"
          >
            Главная
          </div>
        </router-link>
        <router-link
          to="/catalog"
          class="footer-mobile__item"
          @click="mobile_menu_open = 1"
        >
          <div
            class="footer-mobile__icon"
            v-bind:class="{ activeMenu: mobile_menu_open === 1 }"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 22.788L19.9809 18.7697C21.055 17.3873 21.5617 15.6474 21.398 13.9044C21.2343 12.1614 20.4124 10.5463 19.0997 9.38808C17.7869 8.22982 16.0821 7.61546 14.3323 7.67011C12.5824 7.72475 10.9192 8.44428 9.68134 9.68219C8.44343 10.9201 7.72389 12.5833 7.66925 14.3331C7.61461 16.0829 8.22897 17.7878 9.38723 19.1005C10.5455 20.4133 12.1606 21.2352 13.9035 21.3989C15.6465 21.5626 17.3864 21.0558 18.7689 19.9817L22.788 24L24 22.788ZM14.5714 19.7143C13.5543 19.7143 12.5599 19.4127 11.7142 18.8476C10.8685 18.2825 10.2093 17.4792 9.82005 16.5395C9.4308 15.5998 9.32895 14.5657 9.52739 13.5681C9.72583 12.5705 10.2156 11.6541 10.9349 10.9349C11.6541 10.2156 12.5705 9.72583 13.5681 9.52739C14.5657 9.32895 15.5998 9.4308 16.5395 9.82005C17.4792 10.2093 18.2825 10.8685 18.8476 11.7142C19.4127 12.5599 19.7143 13.5543 19.7143 14.5714C19.7127 15.9349 19.1704 17.2421 18.2062 18.2062C17.2421 19.1704 15.9349 19.7127 14.5714 19.7143ZM0 8.57143H6.85714V10.2857H0V8.57143ZM0 0H13.7143V1.71429H0V0ZM0 4.28571H13.7143V6H0V4.28571Z"
                fill="#727272"
              />
            </svg>
          </div>
          <div
            class="footer-mobile__text"
            v-bind:class="{ activeMenu: mobile_menu_open === 1 }"
          >
            Каталог
          </div>
        </router-link>
        <router-link
          to="/basket"
          class="footer-mobile__item"
          @click="mobile_menu_open = 2"
        >
          <div
            class="footer-mobile__icon"
            v-bind:class="{ activeMenu: mobile_menu_open === 2 }"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 3H4.242L4.2 2.649C4.11405 1.91942 3.76338 1.24673 3.21449 0.758478C2.6656 0.270223 1.95663 0.000341793 1.222 0L0 0V2H1.222C1.46693 2.00003 1.70334 2.08996 1.88637 2.25272C2.06941 2.41547 2.18634 2.63975 2.215 2.883L3.8 16.351C3.88595 17.0806 4.23662 17.7533 4.78551 18.2415C5.3344 18.7298 6.04337 18.9997 6.778 19H20V17H6.778C6.53291 16.9999 6.29638 16.9099 6.11333 16.7469C5.93027 16.5839 5.81343 16.3594 5.785 16.116L5.654 15H21.836L24 3ZM20.164 13H5.419L4.478 5H21.607L20.164 13Z"
                fill="#727272"
              />
              <path
                d="M7 24.0006C8.10456 24.0006 8.99999 23.1052 8.99999 22.0006C8.99999 20.896 8.10456 20.0006 7 20.0006C5.89543 20.0006 5 20.896 5 22.0006C5 23.1052 5.89543 24.0006 7 24.0006Z"
                fill="#727272"
              />
              <path
                d="M17 24.0006C18.1046 24.0006 19 23.1052 19 22.0006C19 20.896 18.1046 20.0006 17 20.0006C15.8954 20.0006 15 20.896 15 22.0006C15 23.1052 15.8954 24.0006 17 24.0006Z"
                fill="#727272"
              />
            </svg>
          </div>
          <div
            class="footer-mobile__text"
            v-bind:class="{ activeMenu: mobile_menu_open === 2 }"
          >
            Корзина
          </div>
          <div class="header__active-count" v-show="basket_count">
            {{ basket_count }}
          </div>
        </router-link>
        <router-link
          to="/likeds"
          class="footer-mobile__item"
          @click="mobile_menu_open = 3"
        >
          <div
            class="footer-mobile__icon"
            v-bind:class="{ activeMenu: mobile_menu_open === 3 }"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5002 0.917175C16.3742 0.93469 15.2727 1.24903 14.307 1.82845C13.3413 2.40786 12.5456 3.23184 12.0002 4.21718C11.4548 3.23184 10.6591 2.40786 9.69344 1.82845C8.72774 1.24903 7.62625 0.93469 6.5002 0.917175C4.70514 0.995166 3.01391 1.78043 1.79599 3.10141C0.578062 4.42239 -0.0675481 6.1717 0.000202432 7.96718C0.000202432 14.7422 10.9562 22.5672 11.4222 22.8992L12.0002 23.3082L12.5782 22.8992C13.0442 22.5692 24.0002 14.7422 24.0002 7.96718C24.068 6.1717 23.4223 4.42239 22.2044 3.10141C20.9865 1.78043 19.2953 0.995166 17.5002 0.917175ZM12.0002 20.8462C8.7472 18.4162 2.0002 12.4462 2.0002 7.96718C1.93184 6.70189 2.36657 5.46091 3.20957 4.51488C4.05256 3.56885 5.23544 2.99453 6.5002 2.91718C7.76497 2.99453 8.94784 3.56885 9.79084 4.51488C10.6338 5.46091 11.0686 6.70189 11.0002 7.96718H13.0002C12.9318 6.70189 13.3666 5.46091 14.2096 4.51488C15.0526 3.56885 16.2354 2.99453 17.5002 2.91718C18.765 2.99453 19.9478 3.56885 20.7908 4.51488C21.6338 5.46091 22.0686 6.70189 22.0002 7.96718C22.0002 12.4482 15.2532 18.4162 12.0002 20.8462Z"
                fill="#727272"
              />
            </svg>
          </div>
          <div
            class="footer-mobile__text"
            v-bind:class="{ activeMenu: mobile_menu_open === 3 }"
          >
            Избранное
          </div>
          <div class="header__active-count" v-show="liked_count">
            {{ liked_count }}
          </div>
        </router-link>
        <router-link
          :to="getIsAuth ? '/acc' : '/auth'"
          class="footer-mobile__item"
          @click="mobile_menu_open = 4"
        >
          <div
            class="footer-mobile__icon"
            v-bind:class="{ activeMenu: mobile_menu_open === 4 }"
          >
            <svg
              width="18"
              height="24"
              viewBox="0 0 18 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 24.0006H16V18.9576C15.9992 18.1736 15.6874 17.4219 15.133 16.8676C14.5787 16.3132 13.827 16.0014 13.043 16.0006H4.957C4.173 16.0014 3.42134 16.3132 2.86696 16.8676C2.31259 17.4219 2.00079 18.1736 2 18.9576V24.0006H0V18.9576C0.00158783 17.6434 0.524351 16.3835 1.45363 15.4542C2.3829 14.525 3.64281 14.0022 4.957 14.0006H13.043C14.3572 14.0022 15.6171 14.525 16.5464 15.4542C17.4756 16.3835 17.9984 17.6434 18 18.9576V24.0006Z"
                fill="#727272"
              />
              <path
                d="M9 12C7.81332 12 6.65328 11.6481 5.66658 10.9888C4.67989 10.3295 3.91085 9.39246 3.45673 8.2961C3.0026 7.19975 2.88378 5.99335 3.11529 4.82946C3.3468 3.66558 3.91825 2.59648 4.75736 1.75736C5.59648 0.918247 6.66558 0.346802 7.82946 0.115291C8.99335 -0.11622 10.1997 0.00259972 11.2961 0.456726C12.3925 0.910851 13.3295 1.67989 13.9888 2.66658C14.6481 3.65328 15 4.81331 15 6C14.9984 7.59081 14.3658 9.11602 13.2409 10.2409C12.116 11.3658 10.5908 11.9984 9 12V12ZM9 2C8.20888 2 7.43552 2.2346 6.77772 2.67412C6.11993 3.11365 5.60724 3.73836 5.30448 4.46927C5.00173 5.20017 4.92252 6.00444 5.07686 6.78036C5.2312 7.55629 5.61217 8.26902 6.17158 8.82843C6.73099 9.38784 7.44372 9.7688 8.21964 9.92314C8.99557 10.0775 9.79983 9.99827 10.5307 9.69552C11.2616 9.39277 11.8864 8.88008 12.3259 8.22228C12.7654 7.56449 13 6.79113 13 6C13 4.93914 12.5786 3.92172 11.8284 3.17158C11.0783 2.42143 10.0609 2 9 2Z"
                fill="#727272"
              />
            </svg>
          </div>
          <div
            class="footer-mobile__text"
            v-if="getIsAuth"
            v-bind:class="{ activeMenu: mobile_menu_open === 4 }"
          >
            Профиль
          </div>
          <div
            class="footer-mobile__text"
            v-else
            v-bind:class="{ activeMenu: mobile_menu_open === 4 }"
          >
            Войти
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import Modal from "../components/Catalog.vue";
import CitiesModal from "./Cities.vue";
import SelectComponent from "./UI/SelectComponent.vue";
export default {
  data() {
    return {
      city: "Атырауская область",
      isCitiesModal: false,
      siteSearch: "",
      mobile_menu_open: 0,
      basket_count: 0,
      liked_count: 0,
      iconX: false,
      languages: [
        {
          id: 1,
          value: "ru",
          text: "Рус",
          img: require("@/assets/img/langs/ru.jpg"),
        },
        {
          id: 1,
          value: "kz",
          text: "Қаз",
          img: require("@/assets/img/langs/kz.png"),
        },
        {
          id: 1,
          value: "en",
          text: "Eng",
          img: require("@/assets/img/langs/en.png"),
        },
      ],
      isModal: false,
      additionaly_title: "Электроинструменты",
      list: [
        {
          id: 0,
          text: "Аптеки",
          link: "market-shop",
        },
        {
          id: 1,
          text: "Стать партнером",
          link: "news",
        },
      ],
      products: [
        {
          id: 0,
          icon: require("../assets/icons/modal/modal1.png"),
          title: "Строительные материалы",
        },
        {
          id: 1,
          icon: require("../assets/icons/modal/modal2.png"),
          title: "Электроинструменты",
        },
        {
          id: 2,
          icon: require("../assets/icons/modal/modal3.png"),
          title: "Ручные инструменты",
        },
        {
          id: 3,
          icon: require("../assets/icons/modal/modal4.png"),
          title: "Расходные материалы",
        },
        {
          id: 4,
          icon: require("../assets/icons/modal/modal5.png"),
          title: "Автозапчасти",
        },
        {
          id: 5,
          icon: require("../assets/icons/modal/modal6.png"),
          title: "Бытовая электроника",
        },
        {
          id: 6,
          icon: require("../assets/icons/modal/modal7.png"),
          title: "Спецодежда",
        },
        {
          id: 7,
          icon: require("../assets/icons/modal/modal8.png"),
          title: "Электротовары",
        },
        {
          id: 8,
          icon: require("../assets/icons/modal/modal9.png"),
          title: "Лакокрасочные материалы",
        },
      ],
      items: [
        {
          id: 0,
          title: "Сверление и завинчивание",
        },
        {
          id: 1,
          title: "Перфораторы",
        },
        {
          id: 2,
          title: "Шлифовальные и полировальные машины ",
        },
        {
          id: 0,
          title: "Пиление",
        },
        {
          id: 0,
          title: "Фрезерование",
        },
        {
          id: 0,
          title: "Многофункциональный инструмент",
        },
        {
          id: 0,
          title: "Пылесосы",
        },
        {
          id: 0,
          title: "Воздуходувки",
        },
        {
          id: 0,
          title: "Сверление и завинчивание",
        },
        {
          id: 1,
          title: "Перфораторы",
        },
        {
          id: 2,
          title: "Шлифовальные и полировальные машины ",
        },
        {
          id: 0,
          title: "Пиление",
        },
        {
          id: 0,
          title: "Фрезерование",
        },
        {
          id: 0,
          title: "Многофункциональный инструмент",
        },
        {
          id: 0,
          title: "Пылесосы",
        },
        {
          id: 0,
          title: "Воздуходувки",
        },
        {
          id: 0,
          title: "Сверление и завинчивание",
        },
        {
          id: 1,
          title: "Перфораторы",
        },
        {
          id: 2,
          title: "Шлифовальные и полировальные машины ",
        },
        {
          id: 0,
          title: "Пиление",
        },
        {
          id: 0,
          title: "Фрезерование",
        },
        {
          id: 0,
          title: "Многофункциональный инструмент",
        },
        {
          id: 0,
          title: "Пылесосы",
        },
        {
          id: 0,
          title: "Воздуходувки",
        },
        {
          id: 0,
          title: "Сверление и завинчивание",
        },
        {
          id: 1,
          title: "Перфораторы",
        },
        {
          id: 2,
          title: "Шлифовальные и полировальные машины ",
        },
        {
          id: 0,
          title: "Пиление",
        },
        {
          id: 0,
          title: "Фрезерование",
        },
        {
          id: 0,
          title: "Многофункциональный инструмент",
        },
        {
          id: 0,
          title: "Пылесосы",
        },
        {
          id: 0,
          title: "Воздуходувки",
        },
      ],
    };
  },

  async created() {
    this.requestUser();
    console.log(this.liked, this.card, "liked card");
    // this.setActivityIcons();
    this.$store.watch(
      function (state) {
        return state.liked, state.card;
      },
      function () {
        console.log("qeqewqwe finaly qwork");
        // this.setActivityIcons();
      }
      // {
      //     deep: true //add this if u need to watch object properties change etc.
      // }
    );
  },
  watch: {
    $route() {
      this.siteSearch = "";
    },
    isModal() {
      this.isModal
        ? (document.documentElement.style.overflowY = "hidden")
        : (document.documentElement.style.overflowY = "auto");
    },
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "SET_LIKED":
          this.liked_count = state.liked.length + state.marketItem.length;
          break;
        case "DELETE_LIKED_GOOD":
          this.liked_count = state.liked.length + state.marketItem.length;
          break;
        case "SET_MARKET_ITEM":
          this.liked_count = state.card.length + state.marketItem.length;
          break;
        case "DELETE_MARKET_ITEM":
          this.liked_count = state.card.length + state.marketItem.length;
          break;
        case "SET_CARD":
          this.basket_count = state.card.length;
          break;
        case "DELETE_BASKET_ITEM":
          this.basket_count = state.card.length;
          break;
      }
    });
  },
  computed: {
    ...mapGetters([
      "STATE",
      "SEARCH_VALUE",
      "getIsAuth",
      "getCountCard",
      "getCountLiked",
    ]),
    ...mapState(["liked", "card"]),
  },
  methods: {
    ...mapActions(["requestUser"]),
    ...mapMutations(["UPDATE_LANG"]),

    getLang(option) {
      this.$i18n.locale = option.value;
      this.UPDATE_LANG(option.value);
      this.$axios.defaults.params = {
        lang: this.$store.state.lang,
      };
      location.reload();
    },
    selectCity(my_city) {
      this.city = my_city;
      this.isCitiesModal = false;
    },
    openCitiesModal() {
      this.isCitiesModal = false;
    },
    ...mapActions(["SET_SEARCH_VALUE_TO_VUEX"]),
    searchProductsBySearchValue(value) {
      if (value) {
        this.products = this.products.filter(function (item) {
          return item.title.toLowerCase().includes(value);
        });
      } else {
        this.setProducts();
      }
    },
    async searchResult(value) {
      if (value) {
        await this.SET_SEARCH_VALUE_TO_VUEX(value);
        this.$router.push("/search/" + value);
      } else {
        console.log("Вы ничего не написали");
      }
    },
    // setActivityIcons() {
    //   console.log("setactivity items");
    //   let liked = this.STATE.liked;
    //   let basket = this.STATE.card;
    //   // this.$set(this, 'basket_count', basket.length)
    //   console.log("basket.lengthbasket.length", this.basket_count);
    //   this.basket_count = basket.length;
    //   this.liked_count = liked.length;
    // },
    activatedBurgerMenu() {
      this.isModal = true;
      this.iconX = true;
    },
    routeTo(link) {
      this.$router.push("/" + link);
    },
    routeToBasket() {
      this.$router.push("/basket");
    },
    routeToHome() {
      this.$router.push("/");
    },
  },
  components: {
    Modal,
    CitiesModal,
    SelectComponent,
  },
};
</script>

<style lang="scss">
.close__burger {
  transform: translateY(-50%);

  span {
    transition: 0.7s all;
  }

  :nth-child(1) {
    position: absolute;
    transform: translateY(3px) rotate(45deg);
  }

  :nth-child(2) {
    position: absolute;
    transform: translateY(3px) rotate(-45deg);
  }

  :nth-child(3) {
    display: none;
  }
}

@import "../assets/style/header.scss";
</style>