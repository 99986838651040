<template>
    <div class="header-modal">
        <div class="header-modal__bg" @click.self="$emit('open-comment-modal')">
            <div class="modal__take">
                <div class="modal__take-close">
                    <div class="modal__take-icon" @click="$emit('open-comment-modal')">
                        <img src="../assets/icons/components_icon/close.svg" alt="">
                    </div>
                </div>
                <div class="modal__take-end" v-show="isFeedbackPosted">
                    <div class="modal__take-end-text">
                        Ваш отзыв отправлен
                    </div>
                    <button class="button button_take" @click="closeButton">
                        Хорошо
                    </button>
                </div>
                <div class="modal__take-wrapper" v-show="!isFeedbackPosted">
                    <div class="modal__take-title">
                        Оставить отзыв
                    </div>
                    <div class="modal__take-rating">
                        <div class="modal__take-rating-stars">
                            <RatingSelectVue @radioPickedValue="radioPicked"></RatingSelectVue>
                        </div>
                        <div class="modal__take-rating-text">
                            Ваша оценка
                        </div>
                    </div>
                    <div class="modal__take-input">
                        <textarea v-model="feedback.advantages" type="text" maxlength="1000"
                            placeholder="Напишите отзыв"></textarea>
                       
                    </div>
                    <button class="button button_take" @click="clickOnSend()">
                        Отправить
                    </button>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
import RatingSelectVue from './RatingSelect.vue';
export default {
    data() {
        return {
            feedback: {
                rating: null,
                advantages: '',
                disadvantages: ''
            },
            isFeedbackPosted: false,
        }
    },
    created() {
        this.isFeedbackPosted = false
    },
    methods: {
        closeButton() {
            this.isFeedbackPosted = false
            this.$emit('open-comment-modal')

        },
        radioPicked(value) {
            this.feedback.rating = value
        },
        clickOnSend() {
            if (this.feedback.rating == null || this.feedback.text == '') {
                alert('введите данные')
            } else {
                this.$emit('send-info', this.feedback)
                this.feedback = {
                    rating: null,
                    text: ''
                }
                this.isFeedbackPosted = true
            }


        }
    },
    components: {
        RatingSelectVue
    },
}
</script>

<style lang="scss" scoped>
.header {
    &-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0;
        z-index: 99999999;
        display: flex;
        align-items: center;
        justify-content: center;

        &__bg {
            position: fixed;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.36);
            z-index: -1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.modal__take {
    padding: 16px 16px 20px;
    width: 800px;
    height: auto;
    background: #FFFFFF;

    &-end {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-close {
        display: flex;
        justify-content: flex-end;
    }

    &-wrapper {
        padding: 0 39px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 576px) {
            padding: 0 10px;
        }
    }

    &-icon {
        width: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &-title {
        margin-top: 40px;
        font-weight: 600;
        font-size: 32px;
        color: #202020;
        text-align: center;
    }

    &-subtitle {
        margin-top: 32px;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #727272;
    }

    &-rating {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        &-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #202020;
        }
    }

    &-input {
        margin-top: 30px;
        width: 100%;

        textarea {
            width: 100%;
            border: 1px solid #727272;
            padding: 15px;
            height: 150px;
            overflow-wrap: break-word;
            color: #282828;
            font-size: 16px;
            margin-top: 20px;
        }
    }

}
</style>