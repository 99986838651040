<template>
  <div class="home">
    <div class="content">
      <div class="home__wrapper">
        <div class="home__search mobile">
          <input placeholder="Поиск" type="text" />
          <div class="home__search-btn">
            <div class="home__search-icon">
              <img src="../assets/icons/search_white.png" alt="" />
            </div>
          </div>
        </div>
        <section class="home__main">
          <swiper
            :pagination="swiper.pagination_main"
            :breakpoints="swiper.breakpoint_main"
            :allowTouchMove="true"
            :lazy="true"
            :loop="true"
          >
            <swiper-slide
              v-for="item in mainData.main_banners"
              :autoHeight="true"
              :key="item.id"
            >
              <div class="home__main-item">
                <div class="home__main-content">
                  <h2 class="home__title">{{ item.name }}</h2>
                  <h3 class="home__subtitle">{{ item.description }}</h3>
                  <button
                    class="button button_home button_home-white"
                    @click="$router.push(`${item.link}`)"
                  >
                    Перейти
                  </button>
                </div>
                <div class="home__main-bg">
                  <img :src="$cdn + item.image" alt="" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </section>
        <section class="home__sales home__sales-rating">
          <div class="home__sales-up">
            <h2 class="home__title-section">Товары от VIP аптек</h2>
            <div class="home__sales-arrows">
              <div class="home__sales-left">
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <div class="home__sales-right">
                <img src="../assets/icons/home/arrow_right.png" alt="" />
              </div>
            </div>
          </div>
          <div class="home__sales-items">
            <div class="home__slider">
              <div class="home__sales-swiper">
                <swiper
                  class="home__sales-item"
                  :space-between="25"
                  :watchSlidesProgress="true"
                  :breakpoints="swiper.breakpoints_good_card"
                  :autoplay="autoplay"
                  :allowTouchMove="false"
                  :lazy="true"
                  :navigation="swiper.salesNavigation"
                >
                  <swiper-slide v-for="item in mainData.vip" :key="item.id">
                    <Card
                      :is-liked="false"
                      :fixcount="item.fix_count"
                      :hot="true"
                      :profit="true"
                      :service="'vip'"
                      :pharmacy="'europharma'"
                      :image="$cdn + item.image"
                      :id="item.id"
                      :discription="item.name"
                      :salePersents="item.sale_persents"
                      :count="item.count"
                      :price="item.pharmacy_inventories[0].price"
                      :salePrice="item.old_price"
                      :link="item.slug"
                    ></Card>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </section>
        <section class="home__category">
          <div class="home__title-section">Популярные категории</div>

          <div class="market-slide">
            <div class="market-slide-wrapper">
              <swiper
                class="market-swiper"
                :breakpoints="swiper.breakpoints"
                :lazy="true"
                :space-between="30"
                :navigation="marketSwiperNavigation"
                :loop="false"
                @swiper="onSwiper"
              >
                <swiper-slide v-for="item in category" :key="item.id">
                  <div class="market__category">
                    <div
                      class="market__category-item"
                      @click="openCategory(item.id)"
                    >
                      <div class="market__category-img">
                        <img :src="item.img" alt="" />
                      </div>
                      <div class="market__category-name">
                        {{ item.text }}
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="market-swiper-left">
              <img src="../assets/icons/arrow_left.png" alt="Arrow" />
            </div>
            <div class="market-swiper-right">
              <img src="../assets/icons/arrow_right.png" alt="Arrow" />
            </div>
          </div>

          <button
            class="button button_category"
            @click="$router.push('/catalog')"
          >
            Перейти в каталог
          </button>
        </section>
        <section class="home__sales home__sales-rating">
          <div class="home__sales-up">
            <h2 class="home__title-section">Товары от горящих аптек</h2>
            <div class="home__sales-arrows">
              <div class="home__sales-left">
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <div class="home__sales-right">
                <img src="../assets/icons/home/arrow_right.png" alt="" />
              </div>
            </div>
          </div>
          <div class="home__sales-items">
            <div class="home__slider">
              <div class="home__sales-swiper">
                <swiper
                  class="home__sales-item"
                  :space-between="25"
                  :watchSlidesProgress="true"
                  :breakpoints="swiper.breakpoints_good_card"
                  :autoplay="autoplay"
                  :allowTouchMove="false"
                  :lazy="true"
                  :navigation="swiper.salesNavigation"
                >
                  <swiper-slide v-for="item in mainData.hot" :key="item.id">
                    <Card
                      :is-liked="false"
                      :fixcount="item.fix_count"
                      :hot="true"
                      :profit="true"
                      :service="'hot'"
                      :pharmacy="'europharma'"
                      :image="$cdn + item.image"
                      :id="item.id"
                      :discription="item.name"
                      :salePersents="item.sale_persents"
                      :count="item.count"
                      :price="item.pharmacy_inventories[0].price"
                      :salePrice="item.old_price"
                      :link="item.slug"
                    ></Card>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </section>

        <section class="home__sales home__sales-rating">
          <div class="home__sales-up">
            <h2 class="home__title-section">Хиты продаж</h2>
            <div class="home__sales-arrows">
              <div class="home__sales-left">
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <div class="home__sales-right">
                <img src="../assets/icons/home/arrow_right.png" alt="" />
              </div>
            </div>
          </div>
          <div class="home__sales-items">
            <div class="home__slider">
              <div class="home__sales-swiper">
                <swiper
                  class="home__sales-item"
                  :space-between="25"
                  :watchSlidesProgress="true"
                  :breakpoints="swiper.breakpoints_good_card"
                  :autoplay="autoplay"
                  :allowTouchMove="false"
                  :lazy="true"
                  :navigation="swiper.salesNavigation"
                >
                  <swiper-slide v-for="item in mainData.best" :key="item.id">
                    <Card
                      :is-liked="false"
                      :fixcount="item.fix_count"
                      :hot="true"
                      :profit="true"
                      :service="'top'"
                      :pharmacy="'europharma'"
                      :image="$cdn + item.image"
                      :id="item.id"
                      :discription="item.name"
                      :salePersents="item.sale_persents"
                      :count="item.count"
                      :price="item.pharmacy_inventories[0].price"
                      :salePrice="item.old_price"
                      :link="item.slug"
                    ></Card>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </section>

        <section class="home__about">
          <div class="home__about-items">
            <div class="home__about-item" v-for="item in about" :key="item.id">
              <div class="home__about-img">
                <img :src="item.img" alt="" />
              </div>
              <div class="home__about-title">{{ item.title }}</div>
            </div>
          </div>
        </section>

        <section class="home__brands">
          <div class="home__title-section">Аптеки</div>
          <div class="home__slider">
            <div class="home__brands-swiper">
              <swiper
                class="home__brands-item"
                :space-between="30"
                :breakpoints="swiper.breakpoints"
                :lazy="true"
                :navigation="swiper.brandNavigation"
                :autoplay="swiper.autoplay"
              >
                <swiper-slide
                  v-for="item in mainData.pharmacies"
                  :key="item.id"
                >
                  <div class="home__brands-img">
                    <img :src="$cdn + item.image" alt="Img" />
                  </div>
                </swiper-slide>
              </swiper>
              <div class="home__brands-left">
                <img src="../assets/icons/arrow_left.png" alt="Arrow" />
              </div>
              <div class="home__brands-right">
                <img src="../assets/icons/arrow_right.png" alt="Arrow" />
              </div>
            </div>
          </div>
        </section>

        <section class="home__main">
          <swiper
            :pagination="swiper.pagination_main"
            :breakpoints="swiper.breakpoint_main"
            :allowTouchMove="true"
            :lazy="true"
            :loop="true"
          >
            <swiper-slide
              v-for="item in mainData.bottom_banners"
              :autoHeight="true"
              :key="item.id"
            >
              <div class="home__main-item">
                <div class="home__main-content">
                  <h2 class="home__title">{{ item.name }}</h2>
                  <h3 class="home__subtitle">{{ item.description }}</h3>
                  <button
                    class="button button_home button_home-white"
                    @click="$router.push(`${item.link}`)"
                  >
                    Перейти
                  </button>
                </div>
                <div class="home__main-bg">
                  <img :src="$cdn + item.image" alt="" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </section>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
// props: ['image', 'salePersents', 'price', 'discription', 'count', 'salePrice', 'link'],

import Card from "../components/GoodsCard.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      mainData: [],
      some_product: [],
      marketSwiperNavigation: {
        prevEl: ".market-swiper-left",
        nextEl: ".market-swiper-right",
      },
      brands: [
        {
          id: 0,
          img: require("../assets/img/home/brand1.png"),
        },
        {
          id: 1,
          img: require("../assets/img/home/brand2.png"),
        },
        {
          id: 2,
          img: require("../assets/img/home/brand3.png"),
        },
      ],
      shop: [
        {
          id: 0,
          img: require("../assets/img/home/shop.png"),
        },
        {
          id: 1,
          img: require("../assets/img/home/shop.png"),
        },
        {
          id: 2,
          img: require("../assets/img/home/shop.png"),
        },
        {
          id: 3,
          img: require("../assets/img/home/shop.png"),
        },
        {
          id: 4,
          img: require("../assets/img/home/shop.png"),
        },
        {
          id: 5,
          img: require("../assets/img/home/shop.png"),
        },
      ],
      category: [
        {
          id: 0,
          img: require("../assets/img/home/category1.1.png"),
          text: "Мед.техника",
        },
        {
          id: 1,
          img: require("../assets/img/home/category2.1.png"),
          text: "Защитные средства ",
        },
        {
          id: 2,
          img: require("../assets/img/home/category3.1.png"),
          text: "Для малышей ",
        },
        {
          id: 3,
          img: require("../assets/img/home/category4.1.png"),
          text: "Витамины",
        },
        {
          id: 4,
          img: require("../assets/img/home/category5.1.png"),
          text: "Оптика",
        },
        {
          id: 5,
          img: require("../assets/img/home/category1.1.png"),
          text: "Мед.техника",
        },
        {
          id: 6,
          img: require("../assets/img/home/category2.1.png"),
          text: "Защитные средства ",
        },
        {
          id: 7,
          img: require("../assets/img/home/category3.1.png"),
          text: "Для малышей ",
        },
        {
          id: 8,
          img: require("../assets/img/home/category4.1.png"),
          text: "Витамины",
        },
        {
          id: 9,
          img: require("../assets/img/home/category5.1.png"),
          text: "Оптика",
        },
      ],
      about: [
        {
          id: 0,
          img: require("../assets/img/home/about1.png"),
          title: "Набор для профилактики",
        },
        {
          id: 1,
          img: require("../assets/img/home/about2.png"),
          title: "Для ваших питомцев",
        },
        {
          id: 2,
          img: require("../assets/img/home/about3.png"),
          title: "Средства реабилитации",
        },
      ],
      main_banners: [
        {
          id: 0,
          title: "210 товаров розыгрываются бесплатно!",
          subtitle: "Успей!!! Успей!!!Успей!!!Успей!!!Успей!!!",
          background: require("../assets/img/home/home_bg1.png"),
          button: {
            title: "Клик",
            route: "catalog",
          },
        },
        {
          id: 1,
          title: "Первый B2B маркетплейс по электрооборудованию",
          subtitle:
            "В своём стремлении улучшить пользовательский опыт мы упускаем, независимые государства",
          background: require("../assets/img/home/home_bg2.png"),
          button: {
            title: "Перейти",
            route: "catalog",
          },
        },
        {
          id: 2,
          title: "210 товаров розыгрываются бесплатно!",
          subtitle: "Успей!!! Успей!!!Успей!!!Успей!!!Успей!!!",
          background: require("../assets/img/home/home_bg3.png"),
          button: {
            title: "Участвовать",
            route: "catalog",
          },
        },
      ],
      cards: [
        {
          id: 0,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 1,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 2,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 3,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 4,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 5,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 6,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 7,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
      ],
      swiper: {
        breakpoints: {
          320: {
            slidesPerView: 2,
          },
          770: {
            slidesPerView: 4,
          },
          991: {
            slidesPerView: 5,
          },
          1200: {
            slidesPerView: 6,
          },
        },
        breakpoint_main: {
          320: {
            slidesPerView: 1,
          },
        },
        breakpoints_good_card: {
          320: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 5,
          },
        },
        brandNavigation: {
          prevEl: ".home__brands-left",
          nextEl: ".home__brands-right",
        },
        shopNavigation: {
          prevEl: ".home__shop-left",
          nextEl: ".home__shop-right",
        },
        salesNavigation: {
          prevEl: ".home__sales-left",
          nextEl: ".home__sales-right",
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination_main: {
          type: "bullets",
          clickable: "true",
          bulletActiveClass: "home__swiper-pagination-bullet-active",
        },
      },
    };
  },
  async created() {
    await this.setProductList();
  },
  mounted() {
    this.$axios
      .get("/main-page")
      .then((res) => {
        this.mainData = res.data;
        console.log(this.mainData);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    routeToShopFullPage(id) {
      console.log("routeToShopFullPage");
      this.$router.push("/single-shop/" + id);
    },
    async setProductList() {
      await this.get_product_list();
      this.cards = this.$store.state.product;
    },
    ...mapActions({
      add_basket_product: "ADD_PRODUCT_TO_BASKET",
      get_product_list: "GET_PRODUCT_LIST",
    }),
  },
  components: { Swiper, SwiperSlide, Card },
};
</script>

<style lang="scss">
@import "../assets/style/home.scss";
</style>