<template>
    <div>
        <div class="successful-modal">
            <div class="successful-modal__bg" @click="closeModal()"></div>
            <div class="successful-modal__wrapper">
                <div class="successful-modal__close" @click="closeModal()">
                    <img src="../assets/icons/components_icon/close.svg" alt="">
                </div>
                <div class="successful-modal__top">
                    <div class="successful-modal__icon">
                        <img src="../assets/icons/modal/successful.svg" alt="">
                    </div>
                    <div class="successful-modal__title">
                        Товар успешно добавлен в корзину
                    </div>
                </div>
                <div class="successful-modal__card">
                    <BasketOrderItemVue :isCounter="false" :icon="false" :rating="4" :img="item.img" :count="item.count"
                        :title="item.name" :cost="item.cost" :feedback="12">
                    </BasketOrderItemVue>
                </div>
                <div class="successful-modal__buttons">
                    <button class="successful-modal__button successful-modal__button-white" @click="closeModal()">
                        Продолжить покупки
                    </button>
                    <button class="successful-modal__button successful-modal__button-orange"
                        @click="$router.push('/basket')">
                        Оформить заказ
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BasketOrderItemVue from './BasketOrderItem.vue';
export default {
    props: [
        'item'
    ],
    methods: {
        closeModal() {
            this.$emit('close-modal')
        }
    },
    created() {
    },
    components: {
        BasketOrderItemVue
    }
}
</script>

<style lang="scss" scoped>
.successful-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0;
    z-index: 99999999;

    @media (max-width: 991px) {
        width: 100%;
        left: 50%;
        transform: translateX(-50%)
    }

    &__card {
        display: block;

        @media (max-width: 576px) {
            display: none;
        }

    }


    &__wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px 50px 50px 50px;
        height: auto;
        width: auto;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        z-index: 101;
        transition: all 1s;
        box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border-radius: 10px;

        @media (max-width: 991px) {
            width: 90%;
        }

        @media (max-width: 576px) {
            width: 100%;
            padding: 10px 30px;
        }
    }

    &__close {
        width: 20px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    &__top {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

    }

    &__icon {
        width: 20px
    }

    &__bg {
        position: fixed;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.36);
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__title {
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        color: #2C3131;

        @media (max-width: 576px) {
            font-size: 20px;
            line-height: 22px;
        }
    }

    &__buttons {
        margin-top: 30px;
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: 576px) {
            flex-direction: column;
            width: 100%;
        }
    }

    &__button {
        border: none;
        width: 250px;
        height: 51px;
        background: #FE8326;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;

        @media (max-width: 576px) {
            width: 100%;
        }

        &-white {
            background: #fff;
            color: #FE8326;
            border: 1px solid #FE8326;
        }
    }
}
</style>