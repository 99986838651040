<template>
  <div class="app">
    <MainHeader></MainHeader>
    <div class="content">
      <div class="acc-layout">
        <div class="acc-layout__menu">
          <div class="acc-layout__member">
            <div class="acc-layout__logo" @click="$router.push('/entity')">
              <img :src="member.logo" alt="" />
            </div>
            <div class="acc-layout__name">
              <div class="acc-layout__status">
                {{ member.status }}
              </div>
              {{ member.name }}
            </div>
            <div
              class="acc-layout__member-arrow"
              @click="isMenuOpen = !isMenuOpen"
              :class="{ 'acc-layout__member-arrow-reverse': isMenuOpen }"
            >
              <span></span>
              <span></span>
            </div>
          </div>
          <div
            class="acc-layout__items"
            :class="{ 'acc-layout__items-active': isMenuOpen }"
          >
            <div
              class="acc-layout__item"
              v-for="(item, i) in acc_menu"
              :key="i"
              @click="itemClick(item)"
              :class="{ acc__active: acc_active === item.id }"
            >
              <div class="acc-layout__icon">
                <img :src="item.icon" alt="" />
              </div>
              <div class="acc-layout__page">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="acc-layout__slot">
          <slot />
        </div>
      </div>
    </div>

    <MainFooter></MainFooter>
  </div>
</template>

<script>
import MainHeader from "../../components/Header.vue";
import MainFooter from "../../components/Footer.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isMenuOpen: false,
      acc_active: 0,
      acc_menu: [
        {
          id: 0,
          text: "Профиль",
          link: "/profile",
        },
        {
          id: 1,
          text: "Мои заказы",
          link: "/orders",
        },
        {
          id: 4,
          text: "Мои отзывы",
          link: "/comments",
        },
        {
          id: 5,
          text: "Мои бонусы ",
          link: "/bonuses",
        },
        {
          id: 6,
          text: "Мои подписки",
          link: "/subscribes",
        },
        {
          id: 7,
          text: "Мои избранные",
          link: "/liked",
        },
        {
          id: 8,
          text: "Профиль",
          link: "/profile",
        },
        {
          id: 9,
          text: "Выйти",
          link: "/",
        },
      ],
      member: {
        logo: require("../../assets/img/home/brand.png"),
        status: "Пользователь",
        name: "Иван Иванов”",
      },
    };
  },
  components: {
    MainHeader,
    MainFooter,
  },
  methods: {
    ...mapActions(["requestUser"]),
    itemClick(item) {
      if (item.id === 9) {
        localStorage.setItem("access_token", null);
        this.$router.push("/");
        this.requestUser();
        if (this.$router.history.current.path.includes("/acc")) {
          this.$router.push("/");
        }
      }
      this.acc_active = item.id;
      this.$router.push(`/acc${item.link}`);
      this.isMenuOpen = false;
    },
  },
};
</script>
