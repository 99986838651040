<template>
    <div class="app">
        <div class="auth-header">
            <div class="content">
                <div class="auth-header__wrapper">
                    <div class="auth-header__logo" @click="$router.push('/')">
                        <img src="../../assets/img/main_logo.png" alt="Main logo">
                    </div>
                    <div class="auth-header__close" @click="$router.push('/')">
                        <img src="../../assets/icons/components_icon/close.svg" alt="">
                    </div>
                </div>

            </div>
        </div>
        <div>
            <slot />
        </div>

        <div class="auth-footer">
            <div class="content">
                <div class="auth-footer__wrapper">
                    <div class="auth-footer__label">
                        © 2022 TOO Pharmart. Все права защищены
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.auth-header {
    height: 100px;
    background: #FFFFFF;
    padding: 20px 0 0 0;


    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        width: 15%;
        @media (max-width: 991px) {
            width: 30%;
        }
    }

    &__item {
        &:nth-child(1) {
            font-weight: 600;
            color: #202020;
        }
    }

}

.auth-footer {
    height: 100px;
    background: #F2F2F2;
    padding: 30px 0;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    &__label {
        font-weight: 400;
        font-size: 14px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #202020;

        @media (max-width: 576px) {
            font-size: 12px;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        gap: 0 16px;
    }

    &__item {
        width: 40px;
    }
}
</style>