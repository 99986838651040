<template>
    <div>
        <div class="acc-analytics">
            <div class="acc-analytics__title">
                Аналитика
            </div>
            <div class="acc-analytics__tabs">
                <div class="acc-analytics__tab" @click="tab = 0" :class="{'acc-analytics__tab-active': tab == 0}">
                    Аналитика по заказам
                </div>
                <div class="acc-analytics__tab" @click="tab = 1" :class="{'acc-analytics__tab-active': tab == 1}">
                    Аналитика по акциям
                </div>
                <div class="acc-analytics__tab" @click="tab = 2" :class="{'acc-analytics__tab-active': tab == 2}">
                    Отчеты
                </div>
            </div>
            <div class="acc-analytics__chart" v-show="tab == 0">
                <LineChartVue :label="Цена" :height="500" :width="900" />
            </div>
            <div class="acc-analytics__chart" v-show="tab == 1">
                <LineChartVue :label="Цена" :height="500" :width="900" />
            </div>
            <div class="acc-analytics__chart" v-show="tab == 2">
                <LineChartVue :label="Цена" :height="500" :width="900" />
            </div>
        </div>
    </div>
</template>

<script>
import LineChartVue from '../../components/LineChart.vue';
export default {
    data() {
        return {
            tab: 0,
            material: {
                product: "",
                area: '',
                year: '',
            },
            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                datasets: [
                    {
                        label: 'Data One',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true
            }
        }
    },
    components: {
        LineChartVue
    },
}
</script>

<style lang="scss" scoped>
.acc-analytics {
    &__title {
        font-weight: 600;
        font-size: 24px;
        color: #2C3131;
    }
    &__chart {
        margin-top: 30px;
    }
    &__tabs {
        margin-top: 30px;
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        @media (max-width: 576px) {
            align-items: center;
            justify-content: center;
        }
    }
    &__tab {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #626262;
        width: 213px;
        height: 42px;
        background: #FFFFFF;
        border: 1px solid #EFEFEF;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &-active {
            color: #2AC1CE;
            border: 1px solid #2AC1CE;
        }
    }
}
</style>