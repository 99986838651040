<template>
    <div class="order">
        <div class="content">
            <div class="order-header__wrapper">
                <div class="order-header__back" @click="$router.push('/basket')">
                    <div class="order-header__logo">
                        <img src="../../assets/icons/auth/arrow_back.svg" alt="Main logo">
                    </div>
                    Вернуться в корзину
                </div>
                <div class="auth-header__steps">
                    <div class="auth-header__icon">
                        <img src="../../assets/icons/auth/registration_second.svg" alt="">
                    </div>
                    <div class="auth-header__items">
                        <div class="auth-header__item">
                            1. Авторизация
                        </div>
                        <div class="auth-header__item">
                            2. Оформление заказа
                        </div>
                        <div class="auth-header__item">
                            3. Отправка заявки
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <div class="order__title">
                Оформление заказа
            </div>
            <div class="order__wrapper">

                <div class="order__main">

                    <div class="order__items">
                        <div class="order__item" v-for="item in items" :key="item.id">
                            <div class="order__item-title">
                                <div class="order__item-order">
                                    Заказ № {{ item.id }}
                                </div>
                                <div class="order__item-vendor">
                                    Поставщик: Кто-то
                                </div>
                            </div>

                            <div class="desctop_order">
                                <div class="order__info ">
                                    <div class="order__info-img">
                                        <img :src="item.img" alt="IMG">
                                    </div>
                                    <div class="order__info-about">
                                        <div class="order__info-name">
                                            {{ item.name }}
                                        </div>
                                        <div class="order__info-amount">
                                            {{ item.amount }} шт
                                        </div>
                                        <div class="order__item-delivery">
                                            <div class="order__item-delivery-info">
                                                <div class="order__item-delivery-icon">
                                                    <img src="../../assets/icons/basket/box.svg" alt="">
                                                </div>
                                                <div class="order__item-delivery-text">Самовывоз - Уральск,
                                                    Аксай,
                                                    Атырау</div>
                                            </div>
                                            <div class="order__item-delivery-info">
                                                <div class="order__item-delivery-icon">
                                                    <img src="../../assets/icons/basket/car.svg" alt="">
                                                </div>
                                                <div class="order__item-delivery-text">Завтра 21 мая, от 1 000 ₸
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="order__way">
                            <div class="order__subtitile">
                                Способ доставки
                            </div>
                            <div class="order__way-choise">
                                <div class="order__way-item" :class="{ active_way: isWay === 'pickup' }"
                                    @click="isWay = 'pickup'">
                                    Самовывоз
                                </div>
                                <div class="order__way-item" :class="{ active_way: isWay === 'courier' }"
                                    @click="isWay = 'courier'">
                                    Доставка курьером
                                </div>
                            </div>
                        </div>

                        <div class="order__pickup" v-show="isWay === 'pickup'">
                            <div class="order__pickup-place">
                                <div class="order__pickup-place_label">
                                    Выберите пункт выдачи
                                </div>
                                <select class="order__pickup-place_input">
                                    <option class="order__pickup-place_list">г. Уральск, ул. Байтурсынова 231,
                                        офис
                                        5б
                                    </option>
                                    <option class="order__pickup-place_list">г. Уральск, ул. Байтурсынова 231,
                                        офис
                                        5б
                                    </option>
                                </select>
                            </div>

                            <div class="order__data">
                                <div class="order__subtitile">
                                    Данные получателя
                                </div>
                                <div class="order__data_items">
                                    <div class="order__data_item">
                                        <div class="order__data_name">
                                            Наименование организации
                                        </div>
                                        <div class="order__data_input">
                                            <input type="text" disabled placeholder="Название организации">
                                        </div>

                                    </div>
                                    <div class="order__data_item">
                                        <div class="order__data_name">
                                            БИН
                                        </div>
                                        <div class="order__data_input">
                                            <input type="text" disabled placeholder="012345678901">
                                        </div>
                                    </div>
                                    <div class="order__data_item">
                                        <div class="order__data_name">
                                            E-mail
                                        </div>
                                        <div class="order__data_input">
                                            <input type="text" disabled placeholder="pharmart@gmail.com">
                                        </div>
                                    </div>
                                    <div class="order__data_item">
                                        <div class="order__data_name">
                                            Телефон номер
                                        </div>
                                        <div class="order__data_input">
                                            <input type="text" placeholder="+7 (777) 777 77-77">
                                        </div>
                                    </div>
                                </div>
                                <div class="order__data_address">
                                    <div class="order__data_name">
                                        Адрес
                                    </div>
                                    <div class="order__data_biginput">
                                        <input type="text" disabled
                                            placeholder="г. Уральск, ул. Байтурсынова 231, офис 5б">
                                        <span>Изменить адрес</span>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="order__courier" v-show="isWay === 'courier'">
                            <div class="order__data_address">
                                <div class="order__data_name">
                                    Адрес
                                </div>
                                <div class="order__data_biginput">
                                    <input type="text" disabled placeholder="г. Уральск, ул. Байтурсынова 231, офис 5б">
                                    <span>Изменить адрес</span>
                                </div>

                            </div>
                            <div class="order__courier-comment">
                                <div class="order__data_name">
                                    Комментарий к заказу
                                </div>
                                <div class="order__courier-input">
                                    <input type="text">
                                </div>
                            </div>
                            <div class="order__data">
                                <div class="order__subtitile">
                                    Данные получателя
                                </div>
                                <div class="order__data_items">
                                    <div class="order__data_item">
                                        <div class="order__data_name">
                                            Наименование организации
                                        </div>
                                        <div class="order__data_input">
                                            <input type="text" disabled placeholder="Название организации">
                                        </div>

                                    </div>
                                    <div class="order__data_item">
                                        <div class="order__data_name">
                                            БИН
                                        </div>
                                        <div class="order__data_input">
                                            <input type="text" disabled placeholder="012345678901">
                                        </div>
                                    </div>
                                    <div class="order__data_item">
                                        <div class="order__data_name">
                                            E-mail
                                        </div>
                                        <div class="order__data_input">
                                            <input type="text" disabled placeholder="pharmart@gmail.com">
                                        </div>
                                    </div>
                                    <div class="order__data_item">
                                        <div class="order__data_name">
                                            Телефон номер
                                        </div>
                                        <div class="order__data_input">
                                            <input type="text" placeholder="+7 (777) 777 77-77">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order__bill order__desctop">
                        <Bill></Bill>
                    </div>
                </div>

            </div>
            <div class="order__bill order__mobile">
                <Bill></Bill>
            </div>
        </div>


    </div>


</template>

<script>
import Bill from '../../components/OrderBill.vue'
export default {
    data() {
        return {
            isWay: 'pickup',
            isChecked: false,
            sale: 1000000,
            cost_with_sale: 122222,
            goods_count: 122,
            total_cost: 2222222,
            item_count: 12,
            items: [
                {
                    id: 0,
                    name: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    amount: 12,
                    cost: 19390,
                    count: 12,
                    img: require('../../assets/img/good.png'),
                    isChecked: false,

                },
                {
                    id: 1,
                    name: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    amount: 12,
                    cost: 19390,
                    count: 12,
                    img: require('../../assets/img/good.png'),
                    isChecked: false,
                },
                {
                    id: 2,
                    name: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    amount: 12,
                    cost: 19390,
                    count: 12,
                    img: require('../../assets/img/good.png'),
                    isChecked: false,
                },
            ]
        }
    },
    async created() {
        this.setOrderCards()
        console.log(this.items);
    },
    methods: {
        async setOrderCards() {
            this.items = []
            this.items = this.$store.state.card
            console.log(this.items, 'itemsitemsitems');
        },
    },
    components: {
        Bill
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/order.scss';

.active_way {
    background-color: #EFEDED;
}

.order__desctop {
    display: block;

    @media (max-width: 991px) {
        display: none;
    }
}

.order__mobile {
    display: none;

    @media (max-width: 991px) {
        display: block;
    }
}

.order__info {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0 37px;

    &-about {
        width: 100%;
    }
}


.auth-header {
    height: 100px;
    background: #FFFFFF;
    padding: 20px 0 0 0;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        width: 80px;
    }

    &__steps {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px 0;
    }

    &__icon {
        width: 577px;
    }

    &__items {
        display: flex;
        gap: 0 118px;
        align-items: center;

        font-size: 16px;
        font-weight: 600;
        color: #202020;


    }

    &__item {
        &:nth-child(3) {
            font-weight: 500;
            color: #C2C2C2;
        }
    }

}
</style>