<template>
    <div class="contact">
        <div class="content">
            <div class="singleCategory__breadcrumbs">
                <span><a href="/">Главная</a></span>
                <span><a href=""> • Контакты</a></span>
            </div>
            <div class="contact__title">
                Контакты
            </div>
            <div class="contact__wrapper">
                <div class="contact__info">
                    <div class="contact__time">
                        Мы на связи ежедневно с 9:00 до 18:00
                    </div>
                    <div class="contact__items">
                        <div class="contact__item">
                            <div class="contact__icon">
                                <img src="../assets/icons/location_black.png" alt="">
                            </div>
                            <span>
                                Адрес компании
                            </span>
                        </div>
                        <div class="contact__item">
                            <div class="contact__icon">
                                <img src="../assets/icons/phone_black.png" alt="">
                            </div>
                            <span>
                                +7 (777) 777 77 - 77
                            </span>
                        </div>
                        <div class="contact__item">
                            <div class="contact__icon">
                                <img src="../assets/icons/mail_black.png" alt="">
                            </div>
                            <span>
                                ges@gmail.com
                            </span>
                        </div>
                    </div>
                    <div class="contact__text">
                        Следите за акциями в соцсетях!
                    </div>
                    <div class="contact__social">
                        <div class="contact__media">
                            <img src="../assets/icons/media/inst.svg" alt="">
                        </div>
                        <div class="contact__media">
                            <img src="../assets/icons/media/fb.svg" alt="">
                        </div>
                        <div class="contact__media">
                            <img src="../assets/icons/media/vk.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="contact__map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.718394903058!2d71.41920011547488!3d51.132102179575256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x424586a1e0d09783%3A0x7f842cf525336fd7!2z0YPQuy4g0JTQuNC90LzRg9GF0LDQvNC10LTQsCDQmtGD0L3QsNC10LLQsCA1LCDQndGD0YAt0KHRg9C70YLQsNC9IDAyMDAwMA!5e0!3m2!1sru!2skz!4v1658095013993!5m2!1sru!2skz"
                        width="100%" height="100%" style="border:0;" allowfullscreen="true" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '../assets/style/contact.scss';
</style>