<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import MainLayout from "@/views/layout/main";
import AuthLayout from "@/views/layout/auth";
import OrderLayout from "@/views/layout/order";
import AccLayout from "@/views/layout/acc";
import EntityLayout from "@/views/layout/entity";
export default {
  name: "App",
  components: { MainLayout, AuthLayout, OrderLayout, AccLayout, EntityLayout },
  data: () => ({}),
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },

  methods: {},
};
</script>



<style lang="scss">
@import "../node_modules/swiper/swiper-bundle.css";
// @import '../node_modules/swiper/swiper.scss';
// @import '../node_modules/swiper/swiper.min.css';
@import "assets/style/main.scss";
</style>

