<template>
    <div>
        <div class="service-modal">
            <div class="service-modal__background" @click="isShowModal()"></div>
            <div class="service-modal__wrapper">
                <div class="service-modal__close" @click="isShowModal()">
                    <img src="../assets/icons/components_icon/close.svg" alt="Close Icon">
                </div>
                <div class="service-modal__about">
                    <div class="service-modal__about-icon">
                        <img :src="item.icon" alt="">
                    </div>
                    <div class="service-modal__about-title">
                        {{ item.name }}
                    </div>
                </div>

                <div class="service-modal__about-text">
                    {{ item.description }} <span>(в течение 30 дней)</span>
                </div>


                <slot v-if="item.name == 'Пакет 3 в одном'"></slot>

                <div class="service-modal__price">
                    К оплате <span>{{ item.price }} тг</span>
                </div>
                <div class="service-modal__divider"></div>
                <button class="service-modal__submit" @click="isShowModal()">
                    Подтвердить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            confirm: {
                confirm: true
            }
        }
    },
    methods: {
        isShowModal() {
            this.$emit('show-modal')
        },
        submit() {
            this.$emit('get-user-solution', this.confirm)
        }
    },
    props: ['item']
}   
</script>

<style lang="scss" scoped>
.service-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;

    &__background {
        position: fixed;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.36);
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 20px;
    }

    &__wrapper {
        position: relative;
        border-radius: 10px;
        padding: 30px 30px;
        height: auto;
        width: 400px;
        background-color: #fff;

        @media (max-width: 576px) {
            width: 95%;
        }
    }

    &__about {
        display: flex;
        align-items: center;
        gap: 20px;

        &-icon {
            width: 20px;
        }

        &-title {
            font-size: 24px;
            font-weight: 500;
        }

        &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            margin-top: 25px;

            span {
                color: #727272;
                font-size: 13px;
            }
        }
    }

    &__price {
        position: relative;
        margin-top: 10px;
        padding: 10px 0;
        text-align: right;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #626262;
        margin-top: 30px;

        span {
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #2C3131;
        }
    }

    &__divider {
        height: 1px;
        width: 100%;
        background-color: #727272;
        margin-top: 10px;
        opacity: 0.5;
    }

    &__submit {
        margin-top: 10px;
        width: 100%;
        height: 40px;
        background: #2AC1CE;
        color: #fff;
        border: none;

        &:hover {
            background: #fff;
            border: 1px solid #2AC1CE;
        }
    }
}
</style>