<template>
    <div class="application">
        <div class="content">
            <div class="application-header">
                <div class="auth-header__icon">
                    <img src="../../assets/icons/auth/registration_end.svg" alt="">
                </div>

            </div>
            <div class="application__title">
                Заявка отправлена!
            </div>
            <div class="application__wrapper">
                <div class="application__background">
                    <img src="../../assets/img/application.png" alt="">
                </div>
                <div class="application__text">
                    Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент
                    проверки анализа существующих паттернов поведения.
                </div>
                <div class="application__bill">
                    <div class="application__doc">
                        <img src="../../assets/icons/auth/doc.svg" alt="">

                    </div>
                    Скачать счет на оплату
                </div>
                <button class="button button_application" @click="$router.push('/')">
                    <div class="application__back">
                        <img src="../../assets/icons/auth/arrow_back_white.svg" alt="">

                    </div>
                    Вернуться на главную страницу
                </button>
            </div>

        </div>
    </div>

</template>



<style lang="scss" scoped>
@import '../../assets/style/application.scss';
</style>