<template>
    <div class="shop-card">
        <div class="shop-card__up">
            <div class="shop-card__img">
                <img :src="image" alt="">
            </div>
            <div class="shop-card__info" @click="openFullShop(id)">
                <div class="shop-card__title" @click="openFullShop(id)">
                    {{name}}
                </div>
                <div class="shop-card__subtitle">
                    {{category}}
                </div>
                <div class="shop-card__rating">
                    <div class="shop-card__icon">
                        <img src="../assets/icons/card/start_yellow.svg" alt="">
                    </div>
                    <span>
                        {{rating}} / 5
                    </span>
                </div>
            </div>
            <div class="card__like" @click="pressLikeIcon(id)" :class="{ liked: productData.isLiked == true }">
            </div>
        </div>
        <div class="shop-card__items">
            <div class="shop-card__item">
                <div class="shop-card__icon">
                    <img src="../assets/icons/card/cube.svg" alt="">
                </div>
                <div class="shop-card__text">
                    Мин.сумма доставки: {{minAmount}}
                </div>
            </div>
            <div class="shop-card__item">
                <div class="shop-card__icon">
                    <img src="../assets/icons/card/cube.svg" alt="">
                </div>
                <div class="shop-card__text">
                    Доставка - {{deliveryTime}}, от {{deliveryCost}}
                </div>
            </div>
            <div class="shop-card__item">
                <div class="shop-card__icon">
                    <img src="../assets/icons/card/sale.svg" alt="">
                </div>
                <div class="shop-card__text">
                    Акция “3+1” на все товары
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            productData: {
                id: this.id,
                img: this.image,
                name: this.name,
                category: this.category,
                rating: this.rating,
                minAmount: this.minAmount,
                deliveryTime: this.deliveryTime,
                deliveryCost: this.deliveryCost,
                city: this.city,
                isLiked: this.isLiked,
            },
        }
    },
    props: ['image', 'isLiked', 'id', 'name', 'category', 'rating', 'minAmount', 'deliveryTime', 'deliveryCost', 'city'],
    methods: {
        openFullShop(id) {
            console.log('open fullshop is work', this.productData);
            this.GET_MARKET_ITEM_SINGLE(this.productData)
            this.$router.push('/single-shop/' + id)
        },
        ...mapActions([
            'GET_MARKET_ITEM',
            'GET_MARKET_ITEM_SINGLE',
            'DELETE_MARKET_ITEM',
        ]),
        pressLikeIcon(id) {
            this.productData.isLiked = !this.productData.isLiked
            if (this.productData.isLiked == true) {
                this.$emit('addLikeCard', this.productData)
                this.GET_MARKET_ITEM(this.productData)
            } else {
                this.DELETE_MARKET_ITEM(id)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.shop-card {
    position: relative;
    padding: 24px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

    @media (max-width: 991px) {
        padding: 9px;
    }

    &__up {
        display: flex;
        align-items: flex-start;
        gap: 20px;
    }

    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #202020;

        &:hover {
            cursor: pointer;
        }
    }

    &__subtitle {
        margin-top: 2px;
        font-weight: 400;
        font-size: 16px;
        color: #727272;
    }

    &__rating {
        margin-top: 2px;
        display: flex;
        align-items: center;
        gap: 0 5px;
        font-weight: 400;
        font-size: 16px;
        color: #727272;
    }

    &__img {
        width: 80px;
    }

    &__icon {
        width: 20px;
    }

    &__item {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 12px;
    }

}
</style>