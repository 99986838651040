<template>
  <div class="footer">
    <div class="content">
      <div class="footer__wrapper desctop">
        <div class="footer__content">
          <ul class="footer__items">
            Категория товаров
            <li
              class="footer__item"
              v-for="item in allCategories"
              :key="item.id"
              @click="routeTo(item.slug)"
            >
              {{ item.name }}
            </li>
          </ul>
          <ul class="footer__items">
            Информация
            <li
              class="footer__item"
              v-for="item in list"
              :key="item.id"
              @click="$router.push('/' + item.link)"
            >
              {{ item.text }}
            </li>
          </ul>
          <ul class="footer__items" style="max-width: 190px">
            Правовое инфо
            <li
              class="footer__item"
              v-for="item in help"
              :key="item.id"
              @click="$router.push('/' + item.link)"
            >
              {{ item.text }}
            </li>
          </ul>
          <ul class="footer__items">
            Контакты
            <li class="footer__contact">
              <div class="footer__icon">
                <img src="../assets/icons/location_black.svg" alt="" />
              </div>
              <p>Адрес компании</p>
            </li>
            <li class="footer__contact">
              <div class="footer__icon">
                <img src="../assets/icons/phone_black.svg" alt="" />
              </div>
              <p>+7 (777) 777 77 - 77</p>
            </li>
            <li class="footer__contact">
              <div class="footer__icon">
                <img src="../assets/icons/mail_black.svg" alt="" />
              </div>
              <a href="mailto: pharmart@gmail.com">pharmart@gmail.com</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer-menu mobile">
        <div class="footer-menu__titles">
          <template v-for="title in titles" :key="title.id">
            <div class="footer-menu__title" @click="title.open = !title.open">
              <div class="footer-menu__text">
                {{ title.text }}
              </div>
              <div class="footer-menu__icon">
                <img
                  src="../assets/icons/mobile/arrow.svg"
                  alt="Icon"
                  v-bind:class="{ open: title.open }"
                />
              </div>
            </div>
            <div
              class="footer-menu__items"
              v-for="item in title.menu_items"
              :key="item.id"
              v-show="title.open"
            >
              <div class="footer-menu__item" @click="routeTo(item.link)">
                <span>{{ item.text }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="footer__social">
          <div class="footer__social-item">
            <img src="../assets/icons/insta_black.png" alt="" />
          </div>
          <div class="footer__social-item">
            <img src="../assets/icons/vk_black.png" alt="" />
          </div>
          <div class="footer__social-item">
            <img src="../assets/icons/fb_black.png" alt="" />
          </div>
        </div>

        <div class="footer__rules">© 2022 TOO Pharmart. Все права защищены</div>
      </div>
    </div>
  </div>
  <div class="footer__rules content">
    <div>© 2022 TOO Pharmart. Все права защищены</div>
    <div>Разработка сайта от Studio Nomad</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      item_count: null,
      mobile_menu_open: 0,
      isOpen: false,
      list: [
        {
          id: 0,
          text: "Аптеки",
          link: "market-shop",
        },
        {
          id: 1,
          text: "Доставка ",
          link: "",
        },
        {
          id: 2,
          text: "Оплата ",
          link: "",
        },
        {
          id: 3,
          text: "О нас ",
          link: "about",
        },
        {
          id: 4,
          text: "Сотрудничество ",
          link: "entity",
        },
        {
          id: 5,
          text: "Личный кабинет",
          link: "acc",
        },
      ],
      help: [
        {
          id: 0,
          text: "Оферта",
          link: "",
        },
        {
          id: 1,
          text: "Конфиденциальность",
          link: "confidentiality",
        },
        {
          id: 2,
          text: "Политика обработки персональных данных",
          link: "",
        },
        {
          id: 3,
          text: "Контроль качества товаров и работы продавца",
          link: "",
        },
      ],
      titles: [
        {
          id: 0,
          text: "Каталог",
          open: false,
          menu_items: [
            {
              id: 0,
              text: "Аптеки",
              link: "/market-shop",
            },
            {
              id: 1,
              text: "Акции",
              link: "",
            },
            {
              id: 2,
              text: "Доставка",
              link: "",
            },
            {
              id: 3,
              text: "Оплата",
              link: "",
            },
            {
              id: 4,
              text: "О нас",
              link: "",
            },
            {
              id: 5,
              text: "Новости",
              link: "",
            },
            {
              id: 0,
              text: "Контакты",
              link: "/contact",
            },
          ],
        },
        {
          id: 1,
          text: "Компания",
          open: false,
          menu_items: [
            {
              id: 0,
              text: "О нас",
              link: "/about",
            },
            {
              id: 1,
              text: "Аптеки",
              link: "",
            },
            {
              id: 2,
              text: "Сотруднечество",
              link: "entity",
            },
            {
              id: 3,
              text: "Личный кабинет",
              link: "acc",
            },
            {
              id: 4,
              text: "О Магазины",
              link: "",
            },
            {
              id: 5,
              text: "Аптеки",
              link: "",
            },
            {
              id: 0,
              text: "Контакты",
              link: "",
            },
          ],
        },
        {
          id: 2,
          text: "Клиентам",
          open: false,
          menu_items: [
            {
              id: 0,
              text: "Аккаунт",
              link: "/acc",
            },
            {
              id: 1,
              text: "Мои избранные",
              link: "/acc/liked",
            },
            {
              id: 2,
              text: "Мой профиль",
              link: "/acc/profile",
            },
            {
              id: 3,
              text: "Мои заказы",
              link: "/acc/orders",
            },
            {
              id: 4,
              text: "Мои продажи",
              link: "/acc/sales",
            },
            {
              id: 5,
              text: "Комменты",
              link: "/acc/comments",
            },
            {
              id: 6,
              text: "Мессенджер",
              link: "/acc/messanger",
            },
          ],
        },
        {
          id: 3,
          text: "Партнерам",
          open: false,
          menu_items: [
            {
              id: 0,
              text: "Контакты",
              link: "",
            },
            {
              id: 1,
              text: "Контакты",
              link: "",
            },
            {
              id: 2,
              text: "Контакты",
              link: "",
            },
            {
              id: 3,
              text: "Контакты",
              link: "",
            },
            {
              id: 4,
              text: "О Контакты",
              link: "",
            },
            {
              id: 5,
              text: "Контакты",
              link: "",
            },
            {
              id: 0,
              text: "Контакты",
              link: "",
            },
          ],
        },
        {
          id: 4,
          text: "Контакты",
          open: false,
          menu_items: [
            {
              id: 0,
              text: "Контакты",
              link: "",
            },
            {
              id: 1,
              text: "Контакты",
              link: "",
            },
            {
              id: 2,
              text: "Контакты",
              link: "",
            },
            {
              id: 3,
              text: "Контакты",
              link: "",
            },
            {
              id: 4,
              text: "О Контакты",
              link: "",
            },
            {
              id: 5,
              text: "Контакты",
              link: "",
            },
            {
              id: 0,
              text: "Контакты",
              link: "",
            },
          ],
        },
      ],
      items: [
        {
          id: 0,
          text: "Магазины",
          link: "",
        },
        {
          id: 1,
          text: "Акции",
          link: "",
        },
        {
          id: 2,
          text: "Доставка",
          link: "",
        },
        {
          id: 3,
          text: "Оплата",
          link: "",
        },
        {
          id: 4,
          text: "О нас",
          link: "about",
        },
        {
          id: 5,
          text: "Новости",
          link: "news",
        },
        {
          id: 0,
          text: "Контакты",
          link: "contact",
        },
      ],
    };
  },
  methods: {
    onMenu() {
      this.isOpen = !this.isOpen;
      console.log("its work");
    },
    routeTo(link) {
      this.$router.push(`/catalog/${link}`);
      console.log("123123");
    },
  },
  computed: {
    ...mapGetters(["allCategories"]),
  },
};
</script>


<style lang="scss">
@import "../assets/style/footer.scss";
</style>