<template>
  <div class="error">
    <div class="row content row-max">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div id="center">
              <h1
                class="text-center"
                style="text-align: center; margin-bottom: 20px; font-size: 24px"
              >
                Конфиденциальность
              </h1>
              <div class="text">
                <p>
                  <strong
                    ><span style="font-size: 16px"
                      >1. Общие положения</span
                    ></strong
                  >
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style="font-size: 16px"
                    >1.2. Пользователь (субъект персональных данных), при
                    регистрации на сайте pharmart.kz, заполнении заявок,
                    оформлении заказа, а также при любых других действиях для
                    которых нужен статус регистрированного пользователя,
                    принимает настоящие Условия конфиденциальности и обработку
                    персональных данных.</span
                  >
                </p>
                <p>
                  <br /><strong
                    ><span style="font-size: 16px"
                      >2. Сбор и обработка персональных данных</span
                    ></strong
                  >
                </p>
                <p>
                  <br /><span style="font-size: 16px"
                    >2.1. При организации и осуществлении обработки персональных
                    данных, Компания руководствуется требованиями законов
                    Республики Казахстан и другими нормативно-правовыми
                    актами.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >2.2. Под термином «персональные данные» понимаются любая
                    информация, предоставленная пользователем через портал
                    Pharmart.kz во время регистрации и (или) подключении к
                    личному кабинету персональных страниц из социальных
                    сетей.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >2.3. Компания не несет ответственности за предоставление
                    недостоверных или заведомо ложных данных со стороны
                    пользователей (субъектов персональных данных).</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >2.4. Компания принимает меры по удалению или корректировке
                    не точных или ложных персональных данных по мере их
                    обнаружения.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >2.5. Компания осуществляет сбор персональных данных,
                    которые вводят в поля данных на сайте Pharmart.kz сами
                    пользователи или иные лица по их поручению.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >2.6. Обработка персональных данных осуществляется на
                    законной основе и ограничивается достижением конкретных и
                    законных целей.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >2.7. Компания может предоставлять и (или) поручать
                    обработку персональных данных пользователей другому
                    лицу.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >2.8. Обязательным условием предоставления и (или) поручения
                    обработки персональных данных другому лицу является
                    обязанность сторон по соблюдению конфиденциальности при их
                    обработке.</span
                  >
                </p>
                <p>
                  <br /><strong
                    ><span style="font-size: 16px"
                      >3. Субъект персональных данных имеет право</span
                    ></strong
                  >
                </p>
                <p>
                  <br /><span style="font-size: 16px"
                    >3.1. Требовать уточнения своих персональных данных или
                    удаления, если персональные данные являются неполными,
                    устаревшими, недостоверными, а также редактировать или
                    удалить свои данные самостоятельно из личного
                    кабинета.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >3.2. Увидеть перечень своих персональных данных,
                    обрабатываемых Компанией в личном кабинете.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >3.3. В судебном порядке обжаловать неправомерные действия
                    при обработке персональных данных.</span
                  >
                </p>
                <p>
                  <br /><strong
                    ><span style="font-size: 16px"
                      >4. Реализация требований к защите персональных
                      данных</span
                    ></strong
                  >
                </p>
                <p>
                  <br /><span style="font-size: 16px"
                    >4.1. С целью поддержания деловой репутации и обеспечения
                    выполнения требований законов Республики Казахстан, Компания
                    считает важной задачей обеспечение надлежащего уровня
                    безопасности обрабатываемых персональных данных.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >4.2. Компания требует от иных лиц, получивших доступ к
                    персональным данным, не раскрывать третьим лицам и не
                    распространять персональные данные без согласия субъекта
                    персональных данных.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >4.3. С целью обеспечения безопасности персональных данных
                    Компания принимает необходимые технические меры для защиты
                    от неправомерного или случайного доступа к ним.</span
                  >
                </p>
                <p>
                  <span style="font-size: 16px"
                    >4.4. По вопросам изменения или удаления персональных
                    данных, которые были заполнены при регистрации, можете
                    связаться по электронной почте:
                    <a href="mailto:help@pharmart.kz">help@pharmart.kz</a></span
                  >
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<style lang="scss" scoped>
p {
  line-height: 30px;
  margin-bottom: 0px !important;
}
</style>