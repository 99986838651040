<template>
    <div class="app">
        <MainHeader></MainHeader>
        <div class="content">
            <div class="acc-layout">
                <div class="acc-layout__menu">
                    <div class="acc-layout__member">
                        <div class="acc-layout__logo" @click="$router.push('/acc')">
                            <img :src="member.logo" alt="">
                        </div>
                        <div class="acc-layout__name">
                            <div class="acc-layout__status">
                                {{ member.status }}
                            </div>
                            {{ member.name }}
                            <div class="acc-layout__balance">
                                Баланс: <span>{{balance}} тг</span>
                            </div>
                            <button class="acc-layout__balance-button">
                                Пополнить
                            </button>
                        </div>

                        <div class="acc-layout__member-arrow" @click="isMenuOpen = !isMenuOpen"
                            :class="{'acc-layout__member-arrow-reverse': isMenuOpen}">
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="acc-layout__items" :class="{'acc-layout__items-active': isMenuOpen}">
                        <div class="acc-layout__item" v-for="(item, i) in acc_menu" :key="i" @click="itemClick(item)"
                            :class="{ acc__active: acc_active === item.id }">
                            <div class="acc-layout__icon">
                                <img :src="item.icon" alt="">
                            </div>
                            <div class="acc-layout__page">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="acc-layout__slot">
                    <slot />
                </div>
            </div>
        </div>
        <MainFooter></MainFooter>
    </div>
</template>

<script>
import MainHeader from '../../components/Header.vue'
import MainFooter from '../../components/Footer.vue'
export default {
    data() {
        return {
            balance: 20000,
            isMenuOpen: false,
            acc_active: 0,
            acc_menu: [
                {
                    id: 0,
                    text: 'Сведения об аптеке',
                    link: '/',
                },
                {
                    id: 1,
                    text: 'Заказы',
                    link: '/orders',

                },
                {
                    id: 4,
                    text: 'Cообщения',
                    link: '/messenger',

                },
                {
                    id: 5,
                    text: 'Аналитика ',
                    link: '/analytics',

                },
                {
                    id: 6,
                    text: 'Управление акциями',
                    link: '/subscribes',

                },
                {
                    id: 7,
                    text: 'Продвижение товаров',
                    link: '/promotion',

                },
                {
                    id: 8,
                    text: 'Рейтинг',
                    link: '/liked',

                },
                {
                    id: 9,
                    text: 'Отзывы',
                    link: '/profile',

                },
                {
                    id: 10,
                    text: 'Выйти',
                    link: '/',

                },

            ],
            member: {
                logo: require('../../assets/img/home/brand.png'),
                status: 'Личный кабинет',
                name: 'EUROPHARMA.KZ',
            }
        }
    },
    components: {
        MainHeader, MainFooter
    },
    methods: {
        itemClick(item) {
            this.acc_active = item.id
            this.$router.push(`/entity${item.link}`)
            this.isMenuOpen = false
        }
    }
};
</script>

<style lang="scss">
.acc__active {
    background: #2AC1CE !important;
    color: #FFFFFF;

}

.acc-layout {
    padding: 60px 0 160px;
    display: flex;
    align-items: flex-start;
    gap: 60px;

    @media (max-width: 991px) {
        padding: 50px 0;
        flex-direction: column;
    }

    &__balance {
        font-weight: 400;
        margin-top: 10px;
        font-size: 15px;
        color: #727272;


        span {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &-button {
            margin-top: 10px;
            width: 120px;
            height: 30px;
            border-radius: 8px;
            color: #2AC1CE;
            border: 1px solid #2AC1CE;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.4s;

            &:hover {
                background-color: #2AC1CE;
                color: #fff;
            }
        }
    }

    &__items {
        border-radius: 10px;
    }

    &__menu {
        @media (max-width: 991px) {
            width: 100%;
        }
    }

    &__member {
        padding: 18px 16px;
        width: 273px;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 8px;

        &-arrow {
            display: none;

            span {
                display: inline-block;
                height: 15px;
                width: 2px;
                background-color: #282828;
                position: relative;

                &:nth-child(1) {
                    transform: rotate(-45deg);
                }

                &:nth-child(2) {
                    left: 8px;
                    transform: rotate(45deg);
                }

            }

            @media (max-width: 991px) {

                display: block;
                width: 50px;
                height: 20px;
                position: absolute;
                top: 45%;
                right: 10px;
            }

            &-reverse {
                transform: rotate(180deg);
                right: 49px;
                top: 40%;
            }
        }

        @media (max-width: 991px) {
            width: 100%;
            justify-content: start;
            gap: 20px;
        }

    }

    &__logo {
        width: 60px;
    }

    &__name {
        display: flex;
        align-items: start;
        flex-direction: column;
        font-weight: 500;
        font-size: 18px;
        color: #202020;

    }

    &__status {
        font-weight: 400;
        font-size: 14px;
        color: #727272;
        margin-bottom: 5px;
    }

    &__items {
        margin-top: 32px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

        @media (max-width: 991px) {
            display: none;
        }

        &-active {
            @media (max-width: 991px) {
                display: flex;
                border-radius: 8px;
            }
        }
    }

    &__item {
        padding: 18px 16px;
        width: 273px;
        height: 58px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        @media (max-width: 991px) {
            width: 100%;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__slot {
        @media (max-width: 991px) {
            width: 100%;
        }
    }
}
</style>
 