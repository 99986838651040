<template>
    <Line :chart-options="chartOptions" :label="label" :chart-data="chartData" :chart-id="chartId"
        :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
        :height="height" />
</template>
  
<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, PointElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Line },
    data() {
        return {
            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                datasets: [{
                    label: "",
                    backgroundColor: '#f87979',
                    borderColor: "#282828",
                    data: [25, 22, 11, 34, 56, 11, 13, 123, 43, 12, 42, 33,],

                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            },
        }
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        label: {
            type: String,
            default: 'Product',
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 600
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        label(newValue) {
            this.chartData.datasets[0].label = newValue
        }
    }

}
</script>