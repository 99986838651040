<template>
    <div class="about">
        <div class="content">
            <div class="singleCategory__breadcrumbs">
                <span><a href="/">Главная</a></span>
                <span><a href=""> • Часто задаваемые вопросы</a></span>
            </div>
            <section class="about__welcome">
                <div class="about__title">
                    Добро пожаловать в Pharmart!
                </div>
                <div class="about__subtitle">
                    В своём стремлении улучшить пользовательский опыт мы упускаем, что независимые государства
                </div>
            </section>
            <section class="about__info">
                <div class="about__discription">
                    <span>О нас</span>
                    Есть над чем задуматься: базовые сценарии поведения пользователей неоднозначны и будут указаны как
                    претенденты на роль ключевых факторов. Внезапно, активно развивающиеся страны третьего мира, которые
                    представляют собой яркий пример континентально-европейского типа политической культуры, будут
                    объективно рассмотрены соответствующими инстанциями. Однозначно, представители современных
                    социальных резервов являются только методом политического участия и функционально разнесены на
                    независимые элементы. Но стремящиеся вытеснить традиционное производство, нанотехнологии указаны как
                    претенденты на роль ключевых факторов!
                </div>
                <div class="about__picture">
                    <img src="../assets/img/bg/about_pic.png" alt="">
                </div>
            </section>

            <section class="about__how">
                <div class="about__section-title">
                    Как это работает?
                </div>
                <div class="about__cards">
                    <div class="about__card">
                        <div class="about__card-img">
                            <img src="../assets/img/about_card-1.png" alt="">
                        </div>
                        <div class="about__card-text">
                            <span>Мы ежедневно обновляем предложения</span>
                            <p>Акции и промо от поставщиков по разным категориях</p>
                        </div>
                    </div>
                    <div class="about__card">
                        <div class="about__card-img">
                            <img src="../assets/img/about_card-2.png" alt="">
                        </div>
                        <div class="about__card-text">
                            <span>Мы ежедневно обновляем предложения</span>
                            <p>Акции и промо от поставщиков по разным категориях</p>
                        </div>
                    </div>
                    <div class="about__card">
                        <div class="about__card-img">
                            <img src="../assets/img/about_card-3.png" alt="">
                        </div>
                        <div class="about__card-text">
                            <span>Мы ежедневно обновляем предложения</span>
                            <p>Акции и промо от поставщиков по разным категориях</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="home__brands">
                <div class="about__section-title">Магазины</div>
                <div class="home__slider">
                    <div class="home__brands-swiper">
                        <swiper class="home__brands-item" :breakpoints="breakpoints" :lazy="true"
                            :navigation="videosNavigation" :loop="true" :autoplay="autoplay" @swiper="onSwiper"
                            @slideChange="onSlideChange">
                            <swiper-slide v-for="item in brands" :key="item.id">
                                <img :src="item.img" alt="Img">
                            </swiper-slide>
                        </swiper>
                        <div class="home__brands-left">
                            <img src="../assets/icons/arrow_left.png" alt="Arrow">
                        </div>
                        <div class="home__brands-right">
                            <img src="../assets/icons/arrow_right.png" alt="Arrow">
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</template>
<script>
// import { Swiper, SwiperSlide } from 'swiper/vue';

import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";

export default {
    data() {
        return {
            brands: [
                {
                    id: 0,
                    img: require('../assets/img/home/brand.png')
                },
                {
                    id: 1,
                    img: require('../assets/img/home/brand.png')
                },
                {
                    id: 2,
                    img: require('../assets/img/home/brand.png')
                },
                {
                    id: 3,
                    img: require('../assets/img/home/brand.png')
                },
            ],

            breakpoints: {
                320: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 8,
                }
            },
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        }
    },
    created() {
        window.scroll(0, 0)
    },
    methods: {
        onSwiper(swiper) {
            console.log(swiper)
        },
        onSlideChange() {
            console.log('slide change')
        },

    },
    components: { Swiper, SwiperSlide, },
    // components: { Card, },
}
</script>

<style lang="scss">
.about__welcome {
    background-image: url('../assets/img/bg/bg.png');
    background-position: center;
    background-size: cover;
}

@import '../assets/style/about.scss';
</style>