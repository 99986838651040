<template>
    <div class="acc-liked__main">
        <div class="acc__title">
            Мои избранные
        </div>
        <div class="liked__buttons">
            <div class="liked__button" @click="liked_btn = 1" :class="{ liked__button_active: liked_btn === 1 }">
                Избранные товары
            </div>
            <div class="liked__button" @click="liked_btn = 2" :class="{ liked__button_active: liked_btn === 2 }">
                Избранные магазины
            </div>
        </div>
        <div class="singleCategory__main-items" v-show="liked_btn === 1">
            <div class="singleCategory__main-item" v-for="item in cards" :key="item.id">
                <Card :image="item.image" :discription="item.discription" :salePersents="item.sale_persents"
                    :count="item.count" :price="item.price" :salePrice="item.old_price" :link="item.link">
                </Card>
            </div>
        </div>
        <div class="singleCategory__main-items" v-show="liked_btn === 2">
            <div class="singleCategory__main-item" v-for="(item, index) in 12" :key="index">
                <MarketCard :id="index">
                </MarketCard>
            </div>
        </div>
        <div class="singleCategory__main-pagination">
            <div class="singleCategory__main-count">
                <span class="active_page">1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
            </div>
            <div class="singleCategory__main-icon">
                <img src="../../assets/icons/components_icon/next_page.svg" alt="">
            </div>
        </div>
    </div>
</template>


<script>
import Card from '../../components/GoodsCard.vue'
import MarketCard from '../../components/MarketShopCard.vue'
export default {
    data() {
        return {
            minPrice: 10,
            maxPrice: 1000,
            min_cost: '',
            max_cost: '',
            liked_btn: 1,
            cards: [
                {
                    id: 0,
                    image: require('../../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product'
                },
                {
                    id: 1,
                    image: require('../../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product'

                },
                {
                    id: 2,
                    image: require('../../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product',


                },
                {
                    id: 3,
                    image: require('../../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product'

                },
                {
                    id: 4,
                    image: require('../../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product'

                },
            ]
        }
    },
    components: {
        Card, MarketCard
    }

}
</script>

<style lang="scss">
.liked {
    &__buttons {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__button {
        padding: 16px 18px;
        width: 217px;
        height: 54px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}

@import '../../assets/style/singleCategory.scss';
</style>
