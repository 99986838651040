<template>
    <div class="acc-main">
        <div class="acc-main__wrapper">
            <div class="acc__title">
                Главная страница
            </div>
            <div class="acc-main__noted">
                <div class="acc-main__title">
                    Уведомления
                </div>
                <div class="acc-main__items">
                    <div class="acc-main__item" v-for="item in notes" :key="item.id">
                        <div class="acc-main__item-info">
                            <div class="acc-main__time">
                                {{ item.date }}, {{ item.time }}
                            </div>
                            <div class="acc-main__info">
                                Заказ №{{ item.number }} от {{ item.start_time }} готов к оплате, документы для оплаты
                                прикреплены к заказу и также
                                высланы на Ваш e-mail. Крайний срок оплаты заказа - {{ item.last_time }}
                            </div>
                        </div>
                        <div class="acc-main__icon">
                            <img src="../../assets/icons/basket/delete.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="acc-main__blocks">
                    <div class="acc-main__block" v-for="block in blocks" :key="block.id">
                        {{ block.text }}
                        <div class="acc-main__count">
                            {{ block.count }}
                        </div>
                        <div class="acc-main__total">
                            {{ block.total }} ₸
                        </div>
                    </div>
                </div>
                <div class="acc-main__graph">
                    <img src="../../assets/img/acc/table.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            notes: [
                {
                    id: 0,
                    date: '22.05.2022',
                    time: '16:30',
                    number: '36541',
                    start_time: '15.05.2022',
                    last_time: '29.05.2022'
                },
                {
                    id: 1,
                    date: '22.05.2022',
                    time: '15:30',
                    number: '355541',
                    start_time: '16.05.2022',
                    last_time: '30.05.2022'
                },
            ],
            blocks: [
                {
                    id: 0,
                    text: 'Заказов за июнь',
                    count: '5 864',
                    total: '100 000 000 ₸',
                },
                {
                    id: 1,
                    text: 'Заказов на 2022 год',
                    count: '45 000',
                    total: '100 000 000 ₸',
                },
                {
                    id: 2,
                    text: 'Средний заказ за июнь',
                    count: '15 454',
                    total: '100 000 000 ₸',
                },
                {
                    id: 3,
                    text: 'Всего заказов',
                    count: '59 000',
                    total: '100 000 000 ₸',
                },
            ],
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/style/acc/main.scss';
</style>