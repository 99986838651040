<template>
    <div class="acc-mes">
        <div class="acc__title">
            Мои сообщения
        </div>

        <div class="acc-mes__wrapper acc-mes__wrapper-desktop">
            <div class="acc-mes__contacts">
                <div class="acc-mes__title">
                    Мессенджер
                </div>
                <div class="acc-mes__members">
                    <div class="acc-mes__member" v-for="member in members" :key="member.id"
                        :class="{ active_member: isActiveUser === member.id }" @click="chatUserClickActive(member)">
                        <div class="acc-mes__logo">
                            <img src="../../assets/img/main_logo_black.png" alt="">
                        </div>
                        <div class="acc-mes__name">
                            {{ member.name }}
                            <div class="acc-mes__last">
                                {{ member.last_message }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="acc-mes__chat">
                <div class="acc-mes__header">
                    <div class="acc-mes__company">
                        <div class="acc-mes__img">
                            <img src="../../assets/img/main_logo_black.png" alt="">
                        </div>
                        {{ header }}
                    </div>
                    <div class="acc-mes__icon">
                        <img src="../../assets/icons/acc/market.svg" alt="">
                    </div>
                </div>
                <div class="acc-mes__main">
                    <div class="acc-mes__date">
                        24 июня
                    </div>
                    <div class="acc-mes__messages">
                        <div class="acc-mes__message" v-for="message in messages" :key="message.id">
                            <div class="acc-mes__info" :class="{ message: message.isMe }">
                                <div class="acc-mes__text">
                                    <div> {{ message.text }}</div>
                                </div>
                                <div class="acc-mes__time">
                                    {{ message.time }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="acc-mes__write">
                        <div class="acc-mes__icon">
                            <img src="../../assets/icons/acc/add.svg" alt="">
                        </div>
                        <label for="asd">

                        </label>
                        <input type="text" id="asd" v-model="message">
                        <div class="acc-mes__icon" @click="sendMessage()">
                            <img src="../../assets/icons/acc/send.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="acc-mes__wrapper acc-mes__wrapper-mobile">
            <div class="acc-mes__wrapper-full" v-show="isOpenMembers">
                <div class="acc-mes__contacts">
                    <div class="acc-mes__title">
                        Мессенджер
                    </div>
                    <div class="acc-mes__members">
                        <div class="acc-mes__member" v-for="member in members" :key="member.id"
                            :class="{ active_member: isActiveUser === member.id }" @click="chatUserClickActive(member)">
                            <div class="acc-mes__logo">
                                <img src="../../assets/img/main_logo_black.png" alt="">
                            </div>
                            <div class="acc-mes__name">
                                {{ member.name }}
                                <div class="acc-mes__last">
                                    {{ member.last_message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="!isOpenMembers">
                <div class="acc-mes__chat">
                    <div class="acc-mes__header">
                        <div class="acc-mes__back" @click="isOpenMembers = true">
                            <img src="../../assets/icons/arrow_left.png" alt="">
                        </div>
                        <div class="acc-mes__company">

                            <div class="acc-mes__img">
                                <img src="../../assets/img/main_logo_black.png" alt="">
                            </div>
                            <div>{{ header }}</div>
                        </div>
                        <!-- <div class="acc-mes__icon">
                            <img src="../../assets/icons/acc/market.svg" alt="">
                        </div> -->
                        <div class="acc-mes__icon">
                            <img src="../../assets/icons/acc/market.svg" alt="">

                        </div>
                    </div>
                    <div class="acc-mes__main">
                        <div class="acc-mes__date">
                            24 июня
                        </div>
                        <div class="acc-mes__messages">
                            <div class="acc-mes__message" v-for="message in messages" :key="message.id">
                                <div class="acc-mes__info" :class="{ message: message.isMe }">
                                    <div class="acc-mes__text">
                                        <div> {{ message.text }}</div>
                                    </div>
                                    <div class="acc-mes__time">
                                        {{ message.time }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="acc-mes__write">
                            <div class="acc-mes__icon">
                                <img src="../../assets/icons/acc/add.svg" alt="">
                            </div>
                            <label for="asd">

                            </label>
                            <input type="text" id="asd" v-model="message">
                            <div class="acc-mes__icon" @click="sendMessage()">
                                <img src="../../assets/icons/acc/send.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpenMembers: true,
            isActiveUser: 0,
            message: '',
            header: 'MSTERA',
            members: [
                {
                    id: 0,
                    name: 'MSTERA',
                    last_message: 'Сообщение',
                    img: require('../../assets/img/main_logo_black.png'),
                },
                {
                    id: 1,
                    name: 'BRAER',
                    last_message: 'Сообщение',
                    img: require('../../assets/img/card/image.png'),
                },
                {
                    id: 2,
                    name: 'BRAER',
                    last_message: 'Сообщение',
                    img: require('../../assets/img/card/image.png'),
                },
                {
                    id: 3,
                    name: 'BRAER',
                    last_message: 'Сообщение',
                    img: require('../../assets/img/card/image.png'),
                },
            ],
            messages: [
                {
                    id: 0,
                    text: 'Повседневная практика показывает, что начало повседневной работы по формированию позиции влечет за собой процесс внедрения и модернизации новых предложений.',
                    time: '12:39',
                    isMe: true,
                },
                {
                    id: 1,
                    text: 'Повседневная практика показывает, что начало повседневной работы по формированию позиции влечет за собой процесс внедрения и модернизации новых предложений.',
                    time: '12:39',
                    isMe: false,
                },
                {
                    id: 2,
                    text: 'Повседневная практика показывает, что начало повседневной работы по формированию позиции влечет за собой процесс внедрения и модернизации новых предложений.',
                    time: '12:39',
                    isMe: false,
                },
                {
                    id: 3,
                    text: 'Повседневная практика показывает, что начало повседневной работы по формированию позиции влечет за собой процесс внедрения и модернизации новых предложений.',
                    time: '12:39',
                    isMe: false,
                },
                {
                    id: 4,
                    text: 'Повседневная практика показывает, что начало повседневной работы по формированию позиции влечет за собой процесс внедрения и модернизации новых предложений.',
                    time: '12:39',
                    isMe: false,
                },
            ],
        }
    },
    methods: {
        chatUserClickActive(member) {
            this.isActiveUser = member.id
            this.isOpenMembers = false
            this.isOpenChat = true

        },
        sendMessage() {
            if (this.message) {
                let date = new Date()
                this.messages.push(
                    {
                        id: this.message.length + 1,
                        text: this.message,
                        time: `${date.getHours()}:${date.getMinutes()}`,
                        isMe: true
                    }
                )
                this.message = ''
            }
        },
    },
}
</script>
<style lang="scss">
@import '../../assets/style/acc/messanger.scss';

.acc-mes__wrapper {
    &-desktop {
        display: flex;

        @media (max-width: 991px) {
            display: none;
        }
    }

    &-mobile {
        display: none;

        @media (max-width: 991px) {
            display: flex;
        }
    }
}
</style>