<template>
  <div class="singleCategory">
    <div class="content">
      <div class="singleCategory__breadcrumbs">
        <span><a href="/">Главная </a></span>
        <span><a href=""> • Каталог </a></span>
      </div>
      <div class="singleCategory__title">Каталог</div>
      <div class="singleCategory__wrapper">
        <div class="singleCategory__menu">
          <div class="singleCategory__menu-title">Категория</div>
          <div class="singleCategory__tool">
            <div class="singleCategory__tool-name" @click="backAllCategories">
              <div
                class="singleCategory__tool-icon"
                v-if="currentCategories.name !== 'Все категорий'"
              >
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <span>{{ currentCategories.name }}</span>
            </div>
            <div class="singleCategory__tool-items">
              <template v-if="currentCategories.slug">
                <router-link
                  :to="'/catalog/' + `${currentCategories.slug}/${item.slug}`"
                  v-for="(item, i) in currentCategories.sub_category"
                  :key="i"
                  class="singleCategory__tool-item"
                  :class="{ active_category: item.slug == $route.params.id }"
                >
                  {{ item.name }}
                </router-link>
                <div
                  class="singleCategory__tool-item"
                  style="text-decoration: underline; font-weight: 500"
                >
                  Посмотреть все
                </div></template
              >
              <template v-else>
                <router-link
                  :to="'/catalog/' + item.slug"
                  v-for="(item, i) in currentCategories.sub_category"
                  :key="i"
                  class="singleCategory__tool-item"
                >
                  {{ item.name }}
                </router-link>
              </template>
            </div>
          </div>
          <div class="singleCategory__type">
            <div class="singleCategory__type-title">Тип</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Шлифмашина угловая</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель-шуруповерт</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Перфоратор</span>
              </div>
            </div>
            <div class="singleCategory__more">Посмотреть все</div>
          </div>
          <div class="singleCategory__type">
            <div class="singleCategory__type-title">Бренды</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Шлифмашина угловая</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель-шуруповерт</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Перфоратор</span>
              </div>
            </div>
            <div class="singleCategory__more">Посмотреть все</div>
          </div>
          <div class="singleCategory__cost">
            <div class="singleCategory__type-title">Цена</div>
            <div class="singleCategory__cost-slider">
              <!-- <input type="range" v-modal.number="minPrice"> -->
              <!-- <input type="range" v-modal.number="maxPrice"> -->
            </div>
            <div class="singleCategory__cost-input">
              <input type="text" v-model="min_cost" />
              <input type="text" v-model="max_cost" />
            </div>

            <div class="singleCategory__additional">
              <div class="singleCategory__additional-title">
                Напряжение аккумулятора
              </div>
              <div class="singleCategory__type-items">
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="singleCategory__main">
          <div class="singleCategory__main-input">
            <select>
              <optgroup>
                <option value="Цена по возрастанию">Цена по возрастанию</option>
                <option value="Цена по убыванию">Цена по убыванию</option>
              </optgroup>
            </select>
            <div
              class="singleCategory__main-input-filter"
              @click="filter = true"
            >
              <div class="singleCategory__main-input-icon">
                <img src="../assets/icons/mobile/filters.svg" alt="" />
              </div>
              Фильтр
            </div>

            <!-- mobile filter modal -->
            <div class="singleCategory__filter" v-show="filter">
              <div class="singleCategory__filter-header">
                <div class="singleCategory__filter-header-title">Фильтр</div>
                <div
                  class="singleCategory__filter-header-close"
                  @click="filter = false"
                >
                  <img src="../assets/icons/components_icon/close.svg" alt="" />
                </div>
              </div>
              <div class="singleCategory__filter-main">
                <div class="singleCategory__filter-categories">
                  <div
                    class="
                      singleCategory__filter-category
                      singleCategory__filter-category-cost
                    "
                  >
                    <p>Цена</p>
                    <div class="singleCategory__filter-category-inputs">
                      <input type="number" />
                      <input type="number" />
                    </div>
                  </div>
                  <div
                    class="singleCategory__filter-category"
                    v-for="(item, i) in filter_items"
                    :key="i"
                  >
                    <div
                      class="singleCategory__filter-category-top"
                      @click="item.isOpen = !item.isOpen"
                    >
                      <p>{{ item.title }}</p>
                      <div
                        class="singleCategory__filter-category-arrow"
                        :class="{
                          'singleCategory__filter-category-arrow-reverse':
                            item.isOpen,
                        }"
                      >
                        <img
                          src="../assets/icons/home/arrow_right.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div v-if="item.isOpen">
                      <div
                        class="singleCategory__filter-category-bottom"
                        v-for="i in item.sub_titles"
                        :key="i.id"
                      >
                        <div
                          class="
                            singleCategory__filter-category-bottom-checkbox
                            basket__info-input
                          "
                          v-bind:class="{
                            filter_checkbox_checked: i.isChecked,
                          }"
                        >
                          <input
                            type="checkbox"
                            value="newsletter"
                            @click="i.isChecked = !i.isChecked"
                          />
                        </div>
                        <p>{{ i.title }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- mobile filter modal end! -->
          </div>
          <div class="singleCategory__main-items">
            <div
              class="singleCategory__main-item"
              v-for="item in products"
              :key="item.id"
            >
              <Card
                :is-liked="false"
                :fixcount="item.articul"
                :image="$cdn + item.image"
                :id="item.id"
                :discription="item.name"
                :salePersents="item.articul"
                :count="item.count"
                :price="item.pharmacy_inventories[0].price"
                :salePrice="item.prCategoryId"
                :link="item.slug"
              ></Card>
            </div>
          </div>
          <div class="singleCategory__main-pagination">
            <div class="singleCategory__main-count">
              <span class="active_page">1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
            <div class="singleCategory__main-icon">
              <img src="../assets/icons/components_icon/next_page.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Card from "../components/GoodsCard.vue";
export default {
  data() {
    return {
      isCheckboxChecked: false,
      filter: false,
      minPrice: 10,
      maxPrice: 1000,
      currentId: null,
      min_cost: "",
      products: [],
      max_cost: "",
      currentCategories: {},
      cards: [
        {
          id: 0,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 1,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 2,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 3,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 4,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 5,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 6,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 7,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
      ], // get with api
      filter_items: [
        {
          id: 0,
          title: "Категории",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Лекарственные средства ",
              isChecked: false,
            },
            {
              id: 1,
              title: "Витамины и БАД",
              isChecked: false,
            },
            {
              id: 2,
              title: "Оптика",
              isChecked: false,
            },
            {
              id: 3,
              title: "Ортопедия",
              isChecked: false,
            },
            {
              id: 4,
              title: "Медицинские приборы",
              isChecked: false,
            },
            {
              id: 4,
              title: "Медицинские изделия",
              isChecked: false,
            },
          ],
        },
        {
          id: 2,
          title: "Бренды",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Боли в горле",
              isChecked: false,
            },
            {
              id: 1,
              title: "Отхаркивающие ",
              isChecked: false,
            },
            {
              id: 2,
              title: "От вирусов и микробов",
              isChecked: false,
            },
            {
              id: 3,
              title: "Простуда и грипп",
              isChecked: false,
            },
          ],
        },
        {
          id: 3,
          title: "Медицинские приборы",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Простуда и грипп",
              isChecked: false,
            },
            {
              id: 1,
              title: "От температуры ",
              isChecked: false,
            },
            {
              id: 2,
              title: "Насморк",
              isChecked: false,
            },
          ],
        },
      ],
    };
  },
  async created() {
    window.scrollTo(0, 0);
    await this.setProductList();
  },
  async mounted() {
    this.$route.name !== "catalog" ? this.allCateg() : this.backAllCategories();
  },
  methods: {
    ...mapActions(["GET_PRODUCT_LIST"]),
    async setProductList() {
      await this.GET_PRODUCT_LIST();
      this.cards = this.STATE.product;
    },
    backAllCategories() {
      var newArray = [];
      this.allCategories.map((item) => {
        newArray.push({
          id: item.id,
          slug: item.slug,
          name: item.name,
        });
      }),
        (this.currentCategories = {
          name: "Все категорий",
          sub_category: newArray,
        });
      this.getProducts();
    },
    allCateg() {
      this.allCategories.map((item) => {
        item.slug == this.$route.fullPath.split("/")[2]
          ? ((this.currentCategories = item), (this.currentId = item.id))
          : null;
        item.sub_category.map((elem) => {
          elem.slug == this.$route.params.id
            ? (this.currentId = elem.id)
            : null;
        });
      });
    },
    async getProducts(category_id) {
      let data = new FormData();
      data.append("category_id", category_id);
      await this.$axios
        .post(`products/category`, data)
        .then((res) => {
          this.products = res.data.products;
        })
        .catch(() => {});
    },
  },
  watch: {
    $route() {
      this.$route.name !== "catalog"
        ? this.allCateg()
        : this.backAllCategories();
    },
    allCategories() {
      this.$route.name !== "catalog"
        ? this.allCateg()
        : this.backAllCategories();
    },
    currentId() {
      this.getProducts(this.currentId);
    },
  },
  computed: {
    ...mapGetters(["STATE", "allCategories"]),
  },
  components: {
    Card,
  },
};
</script>

<style lang="scss">
@import "../assets/style/singleCategory.scss";
</style>