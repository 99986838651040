<template>
    <div class="faq">
        <div class="content">
            <div class="singleCategory__breadcrumbs">
                <span><a href="/">Главная</a></span>
                <span><a href=""> • Часто задаваемые вопросы</a></span>
            </div>
            <div class="faq__main">
                <div class="faq__menu">
                    <div class="faq__menu-item" @click="isActiveMenu = 1" :class="{ active_menu: isActiveMenu == 1 }">
                        Часто задаваемые вопросы
                    </div>
                    <div class="faq__menu-item" @click="isActiveMenu = 2" :class="{ active_menu: isActiveMenu == 2 }">
                        О сервисе
                    </div>
                    <div class="faq__menu-item" @click="isActiveMenu = 3" :class="{ active_menu: isActiveMenu == 3 }">
                        Оформление заказа
                    </div>
                    <div class="faq__menu-item" @click="isActiveMenu = 4" :class="{ active_menu: isActiveMenu == 4 }">
                        Доставка
                    </div>
                    <div class="faq__menu-item" @click="isActiveMenu = 5" :class="{ active_menu: isActiveMenu == 5 }">
                        Оплата
                    </div>
                    <div class="faq__menu-item" @click="isActiveMenu = 6" :class="{ active_menu: isActiveMenu == 6 }">
                        Юридические документы
                    </div>
                    <div class="faq__menu-item" @click="isActiveMenu = 7" :class="{ active_menu: isActiveMenu == 7 }">
                        Поставщики
                    </div>
                    <div class="faq__menu-item" @click="isActiveMenu = 8" :class="{ active_menu: isActiveMenu == 8 }">
                        Дополнительные сервисы
                    </div>
                </div>
                <div class="faq__content">
                    <div class="faq__title">
                        Часто задаваемые вопросы
                    </div>
                    <div class="faq__items">
                        <div class="faq__item" v-for="item in answer" :key="item.id" @click="active_answer = item.id">
                            <div class="faq__devider"></div>
                            <div class="faq__item-title" :class="{ active_title: active_answer === item.id }">
                                {{ item.title }}
                                <span :class="{ active_answer: active_answer === item.id }">
                                    <img src="../assets/icons/mobile/arrow.svg" alt="">
                                </span>
                            </div>
                            <div class="faq__item-text" v-show="active_answer === item.id">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActiveMenu: 1,
            active_answer: 0,
            answer: [
                {
                    id: 0,
                    title: 'Как отменить доставку?',
                    text: 'Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.'
                },
                {
                    id: 1,
                    title: 'Какая минимальная сумма заказа?',
                    text: 'Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.'
                },
                {
                    id: 2,
                    title: 'Как отменить доставку?',
                    text: 'Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.'
                },
                {
                    id: 3,
                    title: 'Как отменить доставку?',
                    text: 'Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.'
                },
                {
                    id: 4,
                    title: 'Как отменить доставку?',
                    text: 'Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.'
                },
            ]
        }
    }
}
</script>

<style lang="scss">
@import '../assets/style/FAQ.scss';

.active_answer {
    transform: rotate(180deg);
}
.active_title {
    margin: 32px 0;
}
.active_menu {
    background: #EFEDED;
}
</style>