<template>
  <div class="card">
    <Teleport to="body">
      <SuccessfulProductAdditionModal
        @close-modal="closeModal"
        :item="productData"
        v-if="isModal"
      >
      </SuccessfulProductAdditionModal>
    </Teleport>
    <div
      class="card__like"
      @click="pressLikeIcon(id)"
      :class="{ liked: isLike === true }"
    ></div>
    <div class="card__profit" v-if="!profit">Выгодно</div>
    <div class="card__image" @click="sendSingleProduct(link)">
      <img :src="image" alt="Image" />
    </div>
    <div class="card__info">
      <div class="card__price">
        <div class="card__price-now">{{ price }} ₸</div>
        <div class="card__rating">
          <img src="../assets/icons/card/start_yellow.svg" alt="" />
          5.0
        </div>
      </div>
      <div class="card__discr" @click="sendSingleProduct(link)">
        <p>{{ discription }}</p>
      </div>
      <div class="card__service">
        <div class="card__service-pharm" v-if="service == 'vip'">
          <!-- Аптека: <span>{{ pharmacy }}</span> -->
          Аптека:
          <span @click="$router.push('/single-shop/5')">europharma</span>
        </div>
        <div v-else></div>
        <div class="card__service-icon" v-if="service">
          <img :src="service_logo" alt="" />
        </div>
      </div>
      <div class="card__bottom">
        <div class="card__bottom-counter">
          <button @click="minusItemCount()">-</button>
          <div class="card__bottom-counter-number">{{ productData.count }}</div>
          <button @click="plusItemCount()">+</button>
        </div>
        <button class="card__button" @click="addBasketCard()">
          <img src="../assets/icons/card/basket.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SuccessfulProductAdditionModal from "./SuccessfulProductAdditionModal";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isModal: false,
      count: 1,
      service_logo: "",
      productData: {
        id: this.id,
        img: this.image,
        count: 1,
        salePersents: this.salePersents,
        cost: this.price,
        name: this.discription,
        salePrice: this.salePrice,
        link: this.link,
      },
      basket_status: "В корзину",
      isCardInBasket: true,
      isLike: null,
    };
  },
  created() {
    this.setService(this.service);
    this.isCardInBasket = this.$store.state.card.find(
      (i) => i.id === this.productData.id
    );
  },
  mounted() {
    this.productData.count = this.isCardInBasket
      ? this.isCardInBasket.count
      : (this.productData.count = 1);
    this.isLike = this.$store.state.liked.some((i) =>
      i.id === this.productData.id ? true : false
    );
    this.basket_status = !this.isCardInBasket
      ? "В корзину"
      : "Удалить из корзины";
  },
  props: [
    "service",
    "pharmacy",
    "profit",
    "id",
    "image",
    "hot",
    "salePersents",
    "price",
    "discription",
    "salePrice",
    "link",
    "isLiked",
  ],
  methods: {
    setService(service) {
      if (service) {
        this.service_logo = require("../assets/icons/acc/" + service + ".svg");
      } else {
        return;
      }
    },
    ...mapActions([
      "ADD_PRODUCT_TO_BASKET",
      "ADD_LIKED_PRODUCT",
      "DELETE_CARD_ITEM",
      "GET_ALL_PRODUCTS_INFO",
    ]),
    async sendSingleProduct(id) {
      await this.$store.commit("SET_SHOP_CARD", this.productData);
      await this.GET_ALL_PRODUCTS_INFO();

      this.linkTo(id);
    },

    minusItemCount() {
      let count = this.productData.count;
      if (count <= 1) {
        return;
      } else {
        this.productData.count = count - 1;
      }
    },
    plusItemCount() {
      console.log("1 plus", this.productData.count);
      this.productData.count = this.productData.count + 1;
      console.log("2 plus", this.productData.count);
    },
    deleteItem(item) {
      this.$store.commit("DELETE_BASKET_ITEM", item.id);
    },
    getSingleProduct(id) {
      let arr = this.$store.state.allProductInfo;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          this.$store.commit("SET_SINGLE_PRODUCT_INFO", arr[i]);
          console.log(
            "this.$store.state.singleProductInfo",
            this.$store.state.singleProductInfo
          );
        } else {
          return;
        }
      }
    },
    addBasketCard() {
      this.$emit("addBasketCard", this.productData);
      this.ADD_PRODUCT_TO_BASKET(this.productData);
      this.isModal = true;
    },
    closeModal() {
      this.isModal = false;
    },
    pressLikeIcon(id) {
      this.isLike = !this.isLike;
      if (this.isLike == true) {
        console.log("!!product data", this.productData);
        this.$emit("addLikeCard", this.productData);
        this.ADD_LIKED_PRODUCT(this.productData);
      } else {
        this.$emit("deleteLikeCard", id);
        this.DELETE_CARD_ITEM(id);
      }
    },
    linkTo(id) {
      this.$router.push("/product/" + id);
    },
  },
  components: {
    SuccessfulProductAdditionModal,
  },
};
</script>

<style lang="scss">
.liked {
  background-image: url("../assets/icons/card/like_filled.svg") !important;
}

.card {
  margin: 10px 0;
  position: relative;
  padding: 20px 10px 32px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  &__service {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-pharm {
      font-size: 14px;

      span {
        color: #2ac1ce;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-icon {
      width: 30px;
    }
  }

  &__hot {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 40px;
  }

  &:hover {
    cursor: pointer;
  }

  &__like {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 16px;
    width: 20px;
    background-image: url("../assets/icons/card/like.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__profit {
    position: absolute;
    width: 75px;
    height: 24px;
    background: #2ac1ce;
    left: 16px;
    top: 16px;
    border-radius: 15px;
    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    margin: 0 auto;
    margin-top: 25px;
    max-width: 100%;
    min-height: 160px;
    max-height: 160px;

    img {
      object-fit: contain;
      max-height: 160px;
    }
  }

  &__info {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__discr {
    margin-top: 10px;
    width: 100%;
    height: calc(23px * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #626262;
  }

  &__rating {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    line-height: 17px;
    color: #a9a9a9;
  }

  &__price {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;

    &-now {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #2ac1ce;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    &-counter {
      border: 1px solid #a9a9a9;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60%;
      height: 46px;

      @media (max-width: 991px) {
        width: 100%;
      }

      &-number {
        padding: 0 10px;
        border-left: 1px solid #a9a9a9;
        border-right: 1px solid #a9a9a9;
      }

      button {
        width: 50px;
        font-size: 22px;
        height: 30px;
        border: none;
        background-color: #fff;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    width: 50px;
    height: 46px;
    background: #fe8326;
    border-radius: 4px;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 576.98px) {
  .card {
    position: relative;
    padding: 24px 8px;
    width: 100%;
    max-height: auto;

    &__bottom {
      flex-direction: column;
    }

    &__like {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    &__sale {
      position: absolute;
      width: 48px;
      height: 25px;
      left: 16px;
      top: 133px;
      font-size: 12px;
    }

    &__image {
      margin: 0 auto;
      width: 100%;
      min-height: 130px;
      max-height: 130px;

      img {
        object-fit: contain;
        max-height: 130px;
      }
    }

    &__info {
      margin-top: 10px;
    }

    &__discr {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 10px;
    }

    &__price {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 5px;

      &-now {
        font-size: 13px;
      }

      &-sale {
        font-size: 13px;
      }
    }

    &__button {
      margin-top: 12px;
      padding: 12px 16px;
      width: 135px;
      height: 36px;

      font-weight: 500;
      font-size: 13px;

      img {
        width: 30%;
      }
    }
  }
}
</style>