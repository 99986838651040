<template>
  <div class="app">
    <MainHeader></MainHeader>
    <div style="flex-grow: 1">
      <slot />
    </div>
    <MainFooter></MainFooter>
  </div>
</template>

<script>
import MainHeader from "../../components/Header.vue";
import MainFooter from "../../components/Footer.vue";
export default {
  components: {
    MainHeader,
    MainFooter,
  },
};
</script>

<style lang="scss" scoped>
</style>
 