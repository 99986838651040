<template>
  <div class="search">
    <div class="content">
      <div class="singleCategory__breadcrumbs">
        <span><a href="/">Главная </a></span>
        <span><a href=""> • Результат поиска </a></span>
      </div>
      <div class="singleCategory__title">Результат поиска</div>
      <div class="search__subtitle">
        По запросу “{{ $route.params.result }}” найдено
        {{ resultSearch.length > 0 ? resultSearch.length : 0 }} товаров
      </div>
      <div class="search__filters">
        Сортировать по
        <select class="search__input">
          <option value="all">Все</option>
          <option value="min">Цена по возрастанию</option>
          <option value="max">Цена по убыванию</option>
        </select>
      </div>
      <div class="search__wrapper" v-if="resultSearch.length">
        <div class="search__result" v-for="item in resultSearch" :key="item.id">
          <Card
            :is-liked="false"
            :fixcount="item.fix_count"
            :image="$cdn + item.image"
            :id="item.id"
            :discription="item.name"
            :salePersents="item.sale_persents"
            :count="item.count"
            :price="3000"
            :salePrice="item.old_price"
            :link="item.slug"
          ></Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import { mapActions, mapGetters } from "vuex";
import Card from "../components/GoodsCard.vue";
export default {
  data() {
    return {
      product_amount: null,
      result: [],
      resultSearch: [],
    };
  },
  watch: {
    "$route.params.result"() {
      this.setResult();
    },
  },
  async created() {
    await this.GET_PRODUCT_LIST();
    this.setResult();
  },
  mounted() {
    this.setResult();
  },
  methods: {
    setResult() {
      this.$axios
        .get(`/search?text=${this.$route.params.result}`)
        .then((res) => {
          this.resultSearch = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ...mapActions(["GET_PRODUCT_LIST"]),
  },
  computed: {
    ...mapGetters(["STATE"]),
  },
  components: {
    Card,
  },
};
</script>

<style lang="scss">
.search {
  padding: 100px 0;
  &__filters {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    align-items: center;

    select {
      padding: 10px 12px;
      width: 273px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #e3e5e6;
    }
  }

  &__subtitle {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #727272;
  }

  &__wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__result {
    width: 24%;

    @media (max-width: 1200px) {
      width: 32%;
    }

    @media (max-width: 768px) {
      width: 50%;
    }
  }
}

@import "../assets/style/singleCategory.scss";
</style>