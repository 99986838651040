<template>
    <div>
        <div class="fb-block">
            <div class="fb-block__wrapper">
                <div class="fb-block__rating">
                    <div class="fb-block__rating-average">
                        {{rating}} / 5
                    </div>
                    <div class="fb-block__rating-stars">
                        <StarsRatingVue :rating="rating" :starsLimit="5" :numbers="false"></StarsRatingVue>
                    </div>
                    <div class="fb-block__rating-all">
                        {{allFeedback}} отзывов
                    </div>
                </div>
                <div class="fb-block__lines">
                    <div class="fb-block__line" v-for="item in feedbacks_items" :key="item.id">
                        <div class="fb-block__line-star">
                            {{item.number}}
                        </div>
                        <div class="fb-block__line-icon">
                            <img src="../assets/icons/card/star_filled.svg" alt="">
                        </div>
                        <div class="fb-block__line-rating">
                            <div class="fb-block__line-rating-empty"></div>
                            <div class="fb-block__line-rating-filled" :style="`width: ${item.persernts}%;`"></div>
                            <!-- <div class="fb-block__line-rating-filled" :style="`width: ${raitingWidthStyle(item.id)}%;`"></div> -->
                        </div>
                        <div class="fb-block__line-number">
                            {{item.count}}
                        </div>
                    </div>
                </div>
            </div>
            <button class="fb-block__button" @click="$emit('open-feedback-modal')">
                Оставить отзыв
            </button>
        </div>
    </div>
</template>

<script>
import StarsRatingVue from './StarsRating.vue';
export default {
    data() {
        return {
            feedbacks_items: [
                {
                    id: 0,
                    number: 5,
                    count: 258,
                    persernts: 15,
                },
                {
                    id: 1,
                    number: 4,
                    count: 325,
                    persernts: 20,
                },
                {
                    id: 2,
                    number: 3,
                    count: 123,
                    persernts: 35,
                },
                {
                    id: 3,
                    number: 2,
                    count: 199,
                    persernts: 20,
                },
                {
                    id: 4,
                    number: 1,
                    count: 95,
                    persernts: 10,
                },
            ]
        }
    },
    computed: {
        raitingWidth(id) {
            return this.feedbacks_items[id].count / this.all_feedback * 100
            // return 22
        },
        // raitingWidthStyle(raitingWidth) {
        //     return `width: ${raitingWidth}%;`
        // }
        raitingWidthStyle(id) {
            return `width: ${this.raitingWidth(id)}%;`
        }
    },
    components: {
        StarsRatingVue
    },
    props: {
        rating: {
            type: Number,
            default: 0,
        },
        allFeedback: {
            type: Number,
            default: 1,
        },
        feedbacks: {
            type: Array,
            default: function () {
                return []
            }
        }
    }
    // props: [
    //     'rating', 'allFeedback', 'feedbacks'
    // ]
}
</script>

<style lang="scss" scoped>
.fb-block {
    padding-bottom: 30px;

    &__wrapper {
        display: flex;
        align-items: start;
        gap: 20px;

    }

    &__rating {
        &-average {
            font-weight: 400;
            font-size: 44px;
            margin-bottom: 10px;
            @media (max-width: 576px) {
                font-size: 32px;
            }
        }

        &-all {
            margin-top: 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #727272;
        }
    }

    &__line {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;

        &-rating {
            position: relative;
            display: inline-flex;
            width: 230px;
            margin-bottom: 15px;
            @media (max-width: 576px) {
                width: 169px;
            }

            &-empty {
                position: absolute;
                width: 100%;
                height: 18px;
                left: 0px;
                top: 0px;
                background: #F7F7F7;
            }

            &-filled {
                position: absolute;
                height: 18px;
                left: 0px;
                top: 0px;
                background: #282828;
            }
        }
    }

    &__button {
        margin-top: 20px;
        width: 222px;
        height: 52px;
        border-radius: 8px;
        background: #FE8326;
        border: none;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        color: #fff;
        transition: all 0.5s;

        &:hover {
            cursor: pointer;
            border: 1px solid #FE8326;
            background-color: #fff;
            color: #282828;
        }
    }
}
</style>