<template>
    <div class="singleCategory">
        <div class="content">
            <div class="singleCategory__breadcrumbs">
                <span><a href="/">Главная
                    </a></span>
                <span><a href=""> • Акции
                    </a></span>
            </div>
            <div class="singleCategory__title">
                Акции
            </div>
            <div class="singleCategory__wrapper">
                <div class="singleCategory__menu">
                    <div class="singleCategory__menu-title">
                        Категория
                    </div>
                    <div class="singleCategory__tool">
                        <div class="singleCategory__tool-name">
                            <div class="singleCategory__tool-icon">
                                <img src="../assets/icons/home/arrow_left.png" alt="">

                            </div>
                            <span>Электроинструменты</span>
                        </div>
                        <div class="singleCategory__tool-items">
                            <div class="singleCategory__tool-item active_category">
                                Перфораторы
                            </div>
                            <div class="singleCategory__tool-item">
                                Шлифовальные и полировальные машины
                            </div>
                            <div class="singleCategory__tool-item">
                                Пиление
                            </div>
                        </div>
                        <div class="singleCategory__more">
                            Посмотреть все
                        </div>

                    </div>
                    <div class="singleCategory__type">
                        <div class="singleCategory__type-title">Тип</div>
                        <div class="singleCategory__type-items">
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Шлифмашина угловая</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Дрель-шуруповерт</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Дрель</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Перфоратор</span>
                            </div>
                        </div>
                        <div class="singleCategory__more">
                            Посмотреть все
                        </div>
                    </div>
                    <div class="singleCategory__type">
                        <div class="singleCategory__type-title">Бренды</div>
                        <div class="singleCategory__type-items">
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Шлифмашина угловая</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Дрель-шуруповерт</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Дрель</span>
                            </div>
                            <div class="singleCategory__type-item">
                                <input type="checkbox">
                                <span>Перфоратор</span>
                            </div>
                        </div>
                        <div class="singleCategory__more">
                            Посмотреть все
                        </div>
                    </div>
                    <div class="singleCategory__cost">
                        <div class="singleCategory__type-title">Цена</div>
                        <div class="singleCategory__cost-slider">
                            <!-- <input type="range" v-modal.number="minPrice"> -->
                            <!-- <input type="range" v-modal.number="maxPrice"> -->
                        </div>
                        <div class="singleCategory__cost-input">
                            <input type="text" v-model="min_cost">
                            <input type="text" v-model="max_cost">
                        </div>

                        <div class="singleCategory__additional">
                            <div class="singleCategory__additional-title">Напряжение аккумулятора</div>
                            <div class="singleCategory__type-items">
                                <div class="singleCategory__type-item">
                                    <input type="checkbox">
                                    <span>18 В</span>
                                </div>
                                <div class="singleCategory__type-item">
                                    <input type="checkbox">
                                    <span>18 В</span>
                                </div>
                                <div class="singleCategory__type-item">
                                    <input type="checkbox">
                                    <span>18 В</span>
                                </div>
                                <div class="singleCategory__type-item">
                                    <input type="checkbox">
                                    <span>18 В</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="singleCategory__main">
                    <div class="singleCategory__main-input">
                        <select>
                            <option value="Цена по возрастанию">Цена по возрастанию</option>
                            <option value="Цена по убыванию">Цена по убыванию</option>
                        </select>
                    </div>
                    <div class="singleCategory__main-items">
                        <div class="singleCategory__main-item" v-for="item in cards" :key="item.id">
                            <Card :image="item.image" :discription="item.discription" :salePersents="item.sale_persents"
                                :count="item.count" :price="item.price" :salePrice="item.old_price" :link="item.link">
                            </Card>
                        </div>
                    </div>
                    <div class="singleCategory__main-pagination">
                        <div class="singleCategory__main-count">
                            <span class="active_page">1</span>
                            <span>2</span>
                            <span>3</span>
                            <span>4</span>
                        </div>
                        <div class="singleCategory__main-icon">
                            <img src="../assets/icons/components_icon/next_page.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Card from '../components/GoodsCard.vue'
export default {
    data() {
        return {
            minPrice: 10,
            maxPrice: 1000,
            min_cost: '',
            max_cost: '',
            cards: [
                {
                    id: 0,
                    image: require('../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product'
                },
                {
                    id: 1,
                    image: require('../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product'

                },
                {
                    id: 2,
                    image: require('../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product',


                },
                {
                    id: 3,
                    image: require('../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product'

                },
                {
                    id: 4,
                    image: require('../assets/img/card/image.png'),
                    sale_persents: '30',
                    discription: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE...',
                    price: '19 390 ₸/шт',
                    old_price: '33 490 ₸',
                    count: '12 шт',
                    link: 'product'

                },
            ]
        }
    },
    components: {
        Card
    }

}
</script>

<style lang="scss">
@import '../assets/style/singleCategory.scss';
</style>