import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Basket from '../views/Basket.vue'
import Product from '../components/ProductPage.vue'
import Catalog from '../views/CatalogPage.vue'
// import Liked from '../views/Liked.vue'
import Enter from '../views/auth/Enter.vue'
import Registration from '../views/auth/Registration.vue'
import Order from '../views/order/GetOrder.vue'
import EndOrder from '../views/order/EndOrder.vue'
import SingleCategory from '../views/SingleCategory.vue'
import SingleShop from '../views/SingleShop.vue'
import MarketShop from '../views/Market.vue'
import FAQ from '../views/FAQ.vue'
import Error from '../views/ErrorPage.vue'
import Confidentiality from '../views/Confidentiality.vue'
import Contact from '../views/Contact.vue'
import About from '../views/About.vue'
import News from '../views/News.vue'
import Sales from '../views/SalesPage.vue'
import Search from '../views/SearchPage.vue'
import Liked from '../views/Liked.vue'

//acc
import AccMain from '../views/acc/Main.vue'
import AccSubscribes from '../views/acc/Subscribes.vue'
import AccLiked from '../views/acc/Liked.vue'
import AccProfile from '../views/acc/Profile.vue'
import AccOrders from '../views/acc/Orders.vue'
import AccSales from '../views/acc/Sales.vue'
import AccComments from '../views/acc/Comments.vue'
import AccMessanger from '../views/acc/Messanger.vue'
import AccBonuses from '../views/acc/Bonuses.vue'

//entity
import EntityProfile from '../views/entity/Profile.vue'
import EntityOrders from '../views/entity/Orders.vue'
import EntityMessenger from '../views/entity/Messanger.vue'
import EntityPromotion from '../views/entity/Promotion.vue'
import EntityAnalytics from '../views/entity/Analytics.vue'



const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: Enter,
    meta: {
      layout: 'auth'
    },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: {
      layout: 'auth'
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/likeds',
    name: 'Liked',
    component: Liked,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/search/:result',
    name: 'Search',
    component: Search,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/my-order',
    name: 'Order',
    component: Order,
    meta: {
      layout: 'order'
    },
  },
  {
    path: '/single-shop/:id',
    name: 'SingleShop',
    component: SingleShop,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/end-order',
    name: 'EndOrder',
    component: EndOrder,
    meta: {
      layout: 'order'
    },
  },
  {
    path: '/market-shop',
    name: 'MarketShop',
    component: MarketShop,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/confidentiality',
    name: 'Confidentiality',
    component: Confidentiality,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: FAQ,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: Catalog,
    meta: {
      layout: 'main'
    },
  },
  {
    path: "/catalog/:category",
    name: "catalogId",
    component: Catalog,
    meta: {
      layout: "main",
    },
  },
  {
    path: '/catalog/:category/:id',
    name: 'catalogIdId',
    component: Catalog,
    meta: {
      layout: 'main'
    },
  },
  {
    path: '/single-category',
    name: 'SingleCategory',
    component: SingleCategory,
    meta: {
      layout: 'main'
    },
  },

  {
    path: '/single-category/:category/:id',
    name: 'SingleCategory',
    component: SingleCategory,
    meta: {
      layout: 'main'
    },
  },

  //acc 
  {
    path: '/acc',
    name: 'AccMain',
    component: AccMain,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },
  {
    path: '/acc/bonuses',
    name: 'AccBonuses',
    component: AccBonuses,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },
  {
    path: '/acc/subscribes',
    name: 'AccSubscribes',
    component: AccSubscribes,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },
  {
    path: '/acc/liked',
    name: 'AccLiked',
    component: AccLiked,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },
  {
    path: '/acc/profile',
    name: 'AccProfile',
    component: AccProfile,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },
  {
    path: '/acc/orders',
    name: 'AccOrders',
    component: AccOrders,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },
  {
    path: '/acc/sales',
    name: 'AccSales',
    component: AccSales,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },
  {
    path: '/acc/comments',
    name: 'AccComments',
    component: AccComments,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },
  {
    path: '/acc/messanger',
    name: 'AccMessanger',
    component: AccMessanger,
    meta: {
      requiresAuth: true,
      layout: 'acc'
    },
  },

  //entity 
  {
    path: '/entity',
    name: 'EntityMain',
    component: EntityProfile,
    meta: {
      layout: 'entity'
    },
  },
  {
    path: '/entity/orders',
    name: 'EntityOrder',
    component: EntityOrders,
    meta: {
      layout: 'entity'
    },
  },
  {
    path: '/entity/messenger',
    name: 'EntityMessenger',
    component: EntityMessenger,
    meta: {
      layout: 'entity'
    },
  },
  {
    path: '/entity/subscribes',
    name: 'EntitySubscribes',
    component: AccSubscribes,
    meta: {
      layout: 'entity'
    },
  },
  {
    path: '/entity/analytics',
    name: 'EntityAnalytics',
    component: EntityAnalytics,
    meta: {
      layout: 'entity'
    },
  },
  {
    path: '/entity/promotion',
    name: 'EntityPromotion',
    component: EntityPromotion,
    meta: {
      layout: 'entity'
    },
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      next();
    } else {
      next({ name: "auth" });
    }
  } else {
    next();
  }
});

export default router