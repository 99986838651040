<template>
    <div>
        <div class="cities">
            <div class="cities__background" @click="openCitiesModal()"></div>
            <div class="cities__block">
                <h3 class="cities__title">Выберите ваш город</h3>
                <div class="cities__items">
                    <div class="cities__item" v-for="item in cities" :key="item.id" @click="selectCity(item)">
                        {{item.city_name}}
                    </div>
                </div>
                <div class="cities__close" @click="openCitiesModal()">
                    <img src="../assets/icons/components_icon/close.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cities: [
                {
                    id: 0,
                    city_name: 'Алма-Аты',
                    city: 'ala',
                },
                {
                    id: 1,
                    city_name: 'Абай',
                    city: 'abay',
                },
                {
                    id: 2,
                    city_name: 'Астана',
                    city: 'astana',
                },
                {
                    id: 3,
                    city_name: 'Атырау',
                    city: 'atyray',
                },
            ]
        }
    },
    methods: {
        selectCity(item) {
            localStorage.setItem('city', item.city)
            this.$emit('select-city', item.city_name)
        },
        openCitiesModal() {
            this.$emit('open-cities-modal')
        },

    }
}
</script>

<style lang="scss" scoped>
.cities {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0%;
    left: 0%;
    z-index: 110;

    &__background {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.4;
        height: 100vh;
        width: 100vw;
    }

    &__block {
        position: absolute;
        border-radius: 24px;
        top: 50%;
        left: 50%;
        height: 80vh;
        width: 90vw;
        transform: translate(-50%, -50%);
        padding: 30px 50px;
        background-color: #fff;
    }

    &__title {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
    }

    &__items {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: start;
        flex-wrap: wrap;
        gap: 10px 20px;
    }

    &__item {
        font-size: 18px;
        font-weight: 500;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__close {
        cursor: pointer;
        width: 20px;
        position: absolute;
        right: 30px;
        top: 30px;
    }

}
</style>