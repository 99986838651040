<template>
  <div class="header-modal">
    <div class="header-modal__bg" @click="$emit('openModal')"></div>
    <CitiesModal
      v-show="isCitiesModal"
      @select-city="selectCity"
      @open-cities-modal="openCitiesModal()"
    >
    </CitiesModal>
    <div class="header-modal__header">
      <div class="header-modal__header-close" @click="openModal()">
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="desctop w-100">
      <div class="header-modal__wrapper content">
        <div class="header-modal__products">
          <div
            class="header-modal__products-item"
            v-for="item in allCategories"
            :key="item.id"
            @click="(additionaly = item), (activeCategory = item.id)"
          >
            <div class="header-modal__products-icon">
              <img :src="$cdn + item.image" alt="" />
            </div>
            <div class="header-modal__products-title">
              {{ item.name }}
            </div>
            <div class="header-modal__products-icon-right">
              <img
                :style="
                  activeCategory === item.id ? 'transform: rotate(270deg)' : ''
                "
                :src="require('@/assets/icons/rightArrow.png')"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="header-modal-hr"></div>
        <div class="header-modal__additionaly">
          <div class="header-modal__additionaly-title">
            {{ additionaly.name }}
          </div>
          <div class="header-modal__additionaly-items">
            <div
              class="header-modal__additionaly-item"
              @click="routeTo(item.slug)"
              v-for="item in additionaly.sub_category"
              :key="item.id"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="header-modal__mobile">
        <div class="header-modal__products">
          <div
            class="header-modal__products-item"
            v-for="(item, i) in products"
            :key="item.id"
            @click="additionaly = item"
          >
            <div class="header-modal__products-list">
              <div class="header-modal__products-name" @click="openMenu(item)">
                <div class="header-modal__products-icon">
                  <img :src="item.icon" alt="" />
                </div>
                <div class="header-modal__products-title">
                  {{ item.title }}
                </div>
              </div>
              <div class="header-modal__products-arrow" @click="openMenu(item)">
                <img
                  src="../assets/icons/mobile/arrow.svg"
                  alt=""
                  v-bind:class="{ open: item.open }"
                />
              </div>
            </div>
            <div class="header-modal__additionaly" v-show="item.open === true">
              <div class="header-modal__additionaly-items">
                <div
                  class="header-modal__additionaly-item"
                  v-for="item in products[i].sub_menu"
                  :key="item.id"
                  @click="routeTo(item.id)"
                >
                  {{ item.title }}s
                </div>
              </div>
            </div>
          </div>
          <div class="header-modal__activity">
            <div class="header__city" @click="isCitiesModal = true">
              <div class="header__icon">
                <img
                  src="../assets/icons/location_black.png"
                  alt="Location Icon"
                />
              </div>
              <div class="header__name">
                {{ city }}
              </div>
            </div>

            <div class="header__phone">
              <div class="header__icon">
                <img src="../assets/icons/phone_black.png" alt="Phone Icon" />
              </div>
              <div class="header__number">+7 (777) 777 77 - 77</div>
            </div>
          </div>
          <ul class="header__list">
            <li
              class="header__item"
              v-for="item in list"
              :key="item.id"
              @click="routeToAndCloseMenu(item.link)"
            >
              {{ item.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CitiesModal from "./Cities.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      city: "Атырауская область",
      isCitiesModal: false,
      additionaly: [],
      activeCategory: null,
      modal: false,
      list: [
        {
          id: 0,
          text: "Аптеки",
          link: "market-shop",
        },
        {
          id: 1,
          text: "Доставка ",
          link: "",
        },
        {
          id: 2,
          text: "Оплата ",
          link: "",
        },
        {
          id: 3,
          text: "Сотрудничество ",
          link: "",
        },
        {
          id: 4,
          text: "Новости ",
          link: "news",
        },
      ],
      products: [
        {
          id: 0,
          category: "construction-materials",
          icon: require("../assets/icons/modal/modal.svg"),
          title: "Лекарственные средства",
          open: false,
          sub_menu: [
            {
              id: 0,
              title: "Боли в горле",
            },
            {
              id: 1,
              title: "Отхаркивающие  ",
            },
            {
              id: 2,
              title: "От вирусов и микробов",
            },
            {
              id: 3,
              title: "Простуда и грипп",
            },
            {
              id: 4,
              title: "От температуры ",
            },
            {
              id: 5,
              title: "Насморк",
            },
          ],
        },
        {
          id: 1,
          icon: require("../assets/icons/modal/modal.svg"),
          category: "power-tools",
          title: "Витамины и БАД",
          open: false,
          sub_menu: [
            {
              id: 0,
              title: "Боли в горле",
            },
            {
              id: 1,
              title: "Отхаркивающие  ",
            },
            {
              id: 2,
              title: "От вирусов и микробов",
            },
            {
              id: 3,
              title: "Простуда и грипп",
            },
            {
              id: 4,
              title: "От температуры ",
            },
            {
              id: 5,
              title: "Насморк",
            },
          ],
        },
        {
          id: 2,
          icon: require("../assets/icons/modal/modal.svg"),
          category: "hand-tools",
          title: "Оптика",
          open: false,
          sub_menu: [
            {
              id: 0,
              title: "Боли в горле",
            },
            {
              id: 1,
              title: "Отхаркивающие  ",
            },
            {
              id: 2,
              title: "От вирусов и микробов",
            },
          ],
        },
        {
          id: 3,
          icon: require("../assets/icons/modal/modal.svg"),
          category: "expendable-materials",
          title: "Ортопедия",
          open: false,
          sub_menu: [
            {
              id: 0,
              title: "Боли в горле",
            },
            {
              id: 1,
              title: "Отхаркивающие  ",
            },
            {
              id: 2,
              title: "От вирусов и микробов",
            },
          ],
        },
        {
          id: 4,
          icon: require("../assets/icons/modal/modal.svg"),
          category: "auto-parts",
          title: "Медицинские приборы",
          open: false,
          sub_menu: [
            {
              id: 0,
              title: "Простуда и грипп",
            },
            {
              id: 1,
              title: "От температуры ",
            },
            {
              id: 2,
              title: "Насморк",
            },
          ],
        },
        {
          id: 5,
          icon: require("../assets/icons/modal/modal.svg"),
          category: "consumer-electronics",
          title: "Медицинские изделия",
          open: false,
          sub_menu: [
            {
              id: 0,
              title: "Простуда и грипп",
            },
            {
              id: 1,
              title: "От температуры ",
            },
            {
              id: 2,
              title: "Насморк",
            },
          ],
        },
      ],
    };
  },
  async created() {
    await this.getCategories();
    this.additionaly = this.allCategories[4];
    this.activeCategory = this.allCategories[4].id;
  },
  methods: {
    routeToAndCloseMenu(link) {
      this.$router.push("/" + link);
      this.openModal();
    },
    selectCity(my_city) {
      this.city = my_city;
      this.isCitiesModal = false;
    },
    openCitiesModal() {
      this.isCitiesModal = false;
    },
    openMenu(item) {
      item.open = !item.open;
    },
    ...mapActions(["getCategories", "GET_SUB_CATEGORY"]),
    async routeTo(id) {
      let category = this.additionaly.slug;
      await this.getCategories();
      this.$router.push("/catalog/" + category + "/" + id);
      this.openModal();
    },
    routeToHome() {
      this.$router.push("/");
    },
    openModal() {
      this.$emit("openModal");
    },
  },
  computed: {
    ...mapGetters(["allCategories"]),
  },
  components: {
    CitiesModal,
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
  margin-top: 133px;
}

.header {
  &-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 99999999;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: all 1s;

    &-hr {
      height: 100%;
      width: 2px;
      background: #e5e5e5;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    &__header {
      display: none;
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__mobile {
      width: 100vw;
    }

    &__bg {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &__wrapper {
      margin: 0 auto;
      padding-top: 37px;
      padding-bottom: 33px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 700px;
      gap: 0 32px;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 0px 0px 18px 18px;

      @media (max-width: 1600px) {
        height: 550px;
        padding: 32px 100px 32px 100px;
      }

      @media (max-width: 1200px) {
        height: 450px;
        padding: 16px 50px 16px 100px;
      }
    }

    &__products {
      display: flex;
      align-items: start;
      flex-direction: column;
      gap: 40px 0;

      @media (max-width: 1600px) {
        gap: 30px;
      }

      @media (max-width: 1200px) {
        gap: 20px;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0 21px;
        width: 100%;

        &:hover {
          cursor: pointer;
        }
      }

      &-icon {
        img {
          width: 21px;
          height: 20px;
        }
      }

      &-title {
        font-weight: 400;
        font-size: 18px;
        flex-grow: 1;
        color: #202020;
      }
    }

    &__additionaly {
      @media (max-width: 1200px) {
        width: 80%;
      }

      &-title {
        width: 788px;
        height: 45px;
        background: #f9f9f9;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: #202020;

        @media (max-width: 1200px) {
          width: 100%;
        }
      }

      &-items {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px 166px;
        flex-wrap: wrap;
        height: 410px;

        @media (max-width: 1200px) {
          height: 320px;
          margin-top: 10px;
          gap: 12px 40px;
          overflow: auto;
        }
      }

      &-item {
        font-weight: 400;
        font-size: 16px;
        color: #202020;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .header {
    &-modal {
      padding: 50px;
      position: fixed;
      width: 100%;
      height: 100%;
      overflow: auto;
      top: 0;
      left: 0;
      z-index: 99999999;
      display: flex;
      align-items: start;
      justify-content: start;
      background-color: #fff;
      flex-direction: column;
      overflow-x: hidden;

      @media (max-width: 768px) {
        padding: 30px;
      }

      &__bg {
        display: none;
      }

      &__header {
        position: relative;
        display: flex;
        min-height: 56px;
        width: 100%;
        align-items: center;

        &-logo {
          width: 80px;
        }

        &-close {
          transform: rotate(-45deg);
          position: absolute;
          top: 33%;
          right: 35px;

          span {
            position: absolute;
            right: 0;
            height: 20px;
            width: 2px;
            background-color: #fe8326;

            &:nth-child(2) {
              transform: rotate(90deg);
            }
          }
        }
      }

      &__mobile {
        margin-top: 30px;
        width: 100%;
      }

      &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        z-index: 99999999;

        &-close {
          span {
            height: 1px;
            width: 25px;
            background-color: black;
          }
        }
      }

      &__wrapper {
        padding: 16px 32px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: auto;
        background: #ffffff;
        gap: 0 32px;
      }

      &__products {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 40px 0;
        height: 100%;
        overflow: auto;

        &-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0 21px;
          width: 320px;
        }

        &-item {
          display: flex;
          flex-direction: column;
          align-items: start;
          width: 100%;

          &:hover {
            cursor: pointer;
          }
        }

        &-icon {
          width: 21px;
        }

        &-title {
          font-weight: 400;
          font-size: 18px;
          color: #202020;
        }

        &-name {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      &__activity {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          height: 2px;
          width: 320px;
          top: -10px;
          left: 0px;
          background-color: #e3e5e6;
        }
      }

      &__additionaly {
        &-items {
          margin-top: 36px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 36px;
          flex-wrap: wrap;
          height: auto;
        }

        &-item {
          font-weight: 400;
          font-size: 16px;
          color: #202020;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    &__list {
      flex-direction: column;
      align-items: start;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-top: 16px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 2px;
        width: 320px;
        top: -20px;
        left: 0px;
        background-color: #e3e5e6;
      }
    }

    &__item {
      margin-top: 16px;
      color: #202020;
    }

    &__city,
    &__phone {
      display: flex;
      align-items: center;
      margin-top: 16px;
    }

    &__name,
    &__number {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #202020;
    }
  }
}
</style>
