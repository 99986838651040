import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n";
import store from './store'
import axios from 'axios'

const axiosInstance = axios.create({
 baseURL: 'https://admin.pharmart.kz/api/',
 params: {
  lang: store.getters.getLang
 }
})

axios.defaults.baseURL = 'https://admin.pharmart.kz/api/';
axios.defaults.params = {
  lang: store.getters.getLang
};

const app = createApp(App).use(store).use(router).use(i18n)
app.config.globalProperties.$cdn = 'https://admin.pharmart.kz'
app.config.globalProperties.$axios = { ...axiosInstance }
app.mount('#app')