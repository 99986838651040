<template>
    <div class="acc-order">
        <div class="acc-order__wrapper">
            <div class="acc__title">
                Мои продажи
            </div>
            <div class="acc-order__list">
                <select name="acc_order_list" id="" v-model="filter">
                    <option value="all">Все категории</option>
                    <option value="min_count">По кол-ву(от меньшей)</option>
                    <option value="max_count">По кол-ву(от большей)</option>
                    <option value="min_amount">По сумме(от меньшей)</option>
                    <option value="max_amount">По сумме(от большей)</option>
                    <option value="status">Статусу</option>
                </select>
            </div>
            <!-- <div class="acc-order__table">
                <div class="">

                </div> -->
            <!-- <div>
                <div class="acc-order__items">
                    <div class="acc-order__titles">
                        <div class="acc-order__title">
                            № заказа
                        </div>
                        <div class="acc-order__title">
                            Дата заказа
                        </div>
                        <div class="acc-order__title">
                            Наименование
                        </div>
                        <div class="acc-order__title">
                            Кол-во
                        </div>
                        <div class="acc-order__title">
                            Сумма
                        </div>
                        <div class="acc-order__title">
                            Статус
                        </div>
                        <div class="acc-order__empty">
                        </div>
                    </div>
                    <div class="acc-order__item" v-for="item in acc_order" :key="item.id">
                        <div class="acc-order__number">
                            {{ item.number }}
                        </div>
                        <div class="acc-order__date">
                            {{ item.date }}
                        </div>
                        <div class="acc-order__discription">
                            {{ item.discription }}
                        </div>
                        <div class="acc-order__count">
                            {{ item.count }} шт
                        </div>
                        <div class="acc-order__total">
                            {{ item.total }} Т
                        </div>
                        <div class="acc-order__status">
                            {{ item.status }}
                        </div>
                        <div class="acc-order__action">
                            {{ item.action }}
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="acc-order__info">
                <table class="acc-order__table">
                    <tr class="acc-order__table-header">
                        <th>№ заказа</th>
                        <th>Дата заказа</th>
                        <th>Наименование</th>
                        <th>Кол-во</th>
                        <th>Сумма</th>
                        <th>Статус</th>
                        <th></th>
                    </tr>
                    <tr class="acc-order__table-content" v-for="(order, i) in my_orders_showed" :key="i">
                        <td>{{order.order_id}}</td>
                        <td>{{order.date}}</td>
                        <td>{{order.name}}</td>
                        <td>{{order.count}}шт</td>
                        <td>{{order.amount}}тг</td>
                        <td class="acc-order__table-status" :class="getClassForStatus(order.status)">
                            {{getStatus(order.status)}}</td>
                        <td class="acc-order__table-cancel"><span v-show="order.status != 4">Отменить</span></td>
                    </tr>
                </table>
                <div class=" singleCategory__main-pagination">
                    <div class="singleCategory__main-count">
                        <span v-for="(page, i) in pages" :key="i" @click="clickedPage(i)"
                            :class="{active_page: isActivePage === i}">{{i+1}}</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            isActivePage: 0,
            my_orders: [], // all orders list
            my_orders_showed: [], // rendered on page list
            pages: null,
            nowActivePage: 1,
            numberOfPages: 10,
            filter: 'all',
        }
    },
    async created() {
        this.order()
    },
    watch: {
        filter(newValue) {
            switch (newValue) {
                case 'all':
                    this.setOrders()
                    this.clickedPage(0)
                    console.log('12123 all');
                    break;
                case 'max_count':
                    this.my_orders.sort(function (a, b) {
                        return b.count - a.count
                    })
                    this.clickedPage(0)
                    break;
                case 'min_count':
                    this.my_orders.sort(function (a, b) {
                        return a.count - b.count
                    })
                    this.clickedPage(0)
                    break;
                case 'min_amount':
                    this.my_orders.sort(function (a, b) {
                        return a.amount - b.amount
                    })
                    this.clickedPage(0)
                    break;
                case 'max_amount':
                    this.my_orders.sort(function (a, b) {
                        return b.amount - a.amount
                    })
                    this.clickedPage(0)
                    break;
                case 'status':
                    this.my_orders.sort(function (a, b) {
                        return a.status - b.status

                    })
                    this.clickedPage(0)
                    break;
            }
        }
    },
    methods: {
        getClassForStatus(status) {
            switch (status) {
                case 0:
                    return 'acc-order__table-status-green'
                case 1:
                    return 'acc-order__table-status-yellow'
                case 2:
                    return 'acc-order__table-status-yellow'
                case 3:
                    return 'acc-order__table-status-yellow'
                case 4:
                    return 'acc-order__table-status-red'
            }

        },
        getStatus(num) {
            let allStatus = {
                0: 'Завершен',
                1: 'В пункте самовывоза',
                2: 'Доставляется',
                3: 'Ожидает оплату',
                4: 'Отменeн',
            }
            if (num in allStatus) {
                return allStatus[num]
            } else {
                console.log('num have no', num);
            }
        },
        clickedPage(i) {
            this.isActivePage = i
            this.nowActivePage = i + 1
            this.setShowedOrders()
            console.log('now active page', this.nowActivePage);
        },
        order() {
            this.getOrders()
            this.setOrders()
            this.setPages()
        },
        async getOrders() {
            await this.GET_ORDERS_LIST()
        },
        setOrders() {
            this.my_orders = this.ACCOUNT_ORDERS

        },
        setPages() {
            this.setShowedOrders()
            let page = this.my_orders.length / this.numberOfPages
            this.pages = Math.ceil(page)
        },
        setShowedOrders() {
            let numberOfRenderedPages = this.numberOfPages * this.nowActivePage
            let start = numberOfRenderedPages - this.numberOfPages
            this.my_orders_showed = this.my_orders.slice(start, numberOfRenderedPages);
        },
        ...mapActions([
            'GET_ORDERS_LIST' // отправляю запрос для листа с заказми 
        ]),

    },
    computed: {
        ...mapGetters([
            'ACCOUNT_ORDERS' // получаю запрос
        ])
    }
}
</script>

<style lang="scss">
@import '../../assets/style/acc/orders.scss';
</style>