<template>
    <div>
        <div class="acc-bonuses">
            <div class="acc-bonuses__title">
                Мои бонусы
            </div>
            <div class="acc-bonuses__card">
                <div class="acc-bonuses__card-count">
                    <div class="acc-bonuses__card-bonus">
                        <div class="acc-bonuses__card-number">
                            {{bonuses}}
                        </div>
                        <div class="acc-bonuses__card-text">
                            бонусов накоплено
                        </div>
                    </div>
                    <div class="acc-bonuses__card-img">
                        <img src="../../assets/icons/acc/gift.svg" alt="">
                    </div>
                </div>
                <div class="acc-bonuses__card-additionally">
                    Начисляем 10% бонусов от стомиости полученного заказа, которыми можно оплатить следующую покупку
                </div>
            </div>

            <div class="acc-bonuses__history">
                <div class="acc-bonuses__subtitle">
                    История начисления
                </div>
                <table class="acc-bonuses__table">
                    <tr class="acc-bonuses__table-head">
                        <th>Сумма покупки</th>
                        <th>Начислено %</th>
                        <th>Описание</th>
                        <th>Дата</th>
                    </tr>
                    <tr class="acc-bonuses__table-item">
                        <th class="acc-bonuses__table-count">5 000₸</th>
                        <th class="acc-bonuses__table-presents">10%</th>
                        <th class="acc-bonuses__table-description">Начислена скидка за покупку на сумму 5 000₸</th>
                        <th class="acc-bonuses__table-date">27.08.2022</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bonuses: 213,
        }
    }
}
</script>

<style lang="scss" scoped>
.acc-bonuses {
    width: 100%;
    height: auto;
    padding: 15px 15px 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #2C3131;
    }

    &__history {
        margin-top: 30px;
    }

    &__subtitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #2C3131;
    }

    &__table {
        width: 700px;
        margin-top: 30px;

        &-head,
        &-count {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }

  

        &-presents {
            max-width: 53px;
            max-height: 26px;
            background: linear-gradient(147.28deg, #5AE1E1 -35.63%, #D4FCF2 151.87%);
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #157594;
        }

        &-description {
            width: 170px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
        }
    }

    &__card {
        margin-top: 30px;
        width: 308px;
        height: 185px;
        background: linear-gradient(263.39deg, #5AE1E1 -19.25%, #D4FCF2 99.55%);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;

        &-count {
            font-weight: 600;
            font-size: 20px;
            color: #FE8326;
            display: flex;
            align-items: center;
            gap: 20px;
        }

        &-bonus {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &-text {
            font-weight: 400;
            font-size: 16px;
        }

        &-additionally {
            margin-top: 10px;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #87878C;
        }
    }
}
</style>