<template>
    <div class="acc-profile">
        <div class="acc-profile__wrapper">
            <div class="acc__title">
                Сведения об аптеке
            </div>
            <div class="liked__buttons">
                <div class="liked__button" @click="liked_btn = 1" :class="{ liked__button_active: liked_btn === 1 }">
                    Личные данные
                </div>
                <div class="liked__button" @click="liked_btn = 2" :class="{ liked__button_active: liked_btn === 2 }">
                    Изменить пароль
                </div>
            </div>

            <div class="acc-profile__main" v-show="liked_btn == 1">
                <div class="acc-profile__logo">
                    <img src="../../assets/img/main_logo.png" alt="">
                </div>
                <div class="acc-profile__logo-change">
                    Сменить лого
                </div>
                <div class="acc-profile__items">
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Наименование организации*">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="БИН*">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="E-mail*">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Номер телефона*">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Юридический адрес*">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Фактический адрес*">
                        </div>
                    </div>
                    <button class="button button_auth">
                        Редактировать личные данные
                    </button>
                </div>
                <div class="acc-profile__certificate">
                    <div class="acc-profile__certificate-icon">
                        <img src="../../assets/icons/doc.svg" alt="">
                    </div>
                    Сертификат официального партнерства
                </div>

                <div class="acc-profile__subtitle">
                    Ответственный сотрудник
                </div>
                <div class="acc-profile__items">
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Фамилия">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Имя">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Отчество">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Должность">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="E-mail">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__input">
                            <input type="text" placeholder="Телефон">
                        </div>
                    </div>
                    <button class="button button_auth">
                        Редактировать личные данные
                    </button>
                </div>
            </div>
            <div class="acc-profile__main" v-show="liked_btn == 2">

                <div class="acc-profile__items2">
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Текущий пароль
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Новый пароль
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Новый пароль еще раз
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <button class="button button_auth">
                        Изменить пароль
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            liked_btn: 1,
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/style/acc/profile.scss';
</style>