<template>
  <div class="singleCategory">
    <div class="content">
      <div class="singleCategory__breadcrumbs">
        <span><a href="/">Главная </a></span>
        <span><a href=""> • Избранное </a></span>
      </div>
      <div class="singleCategory__title">Избранное</div>
      <div
        class="singleCategory__wrapper"
        v-if="items.length != 0 || shops.length != 0"
      >
        <div class="singleCategory__menu">
          <div class="singleCategory__menu-title">Категория</div>
          <div class="singleCategory__tool">
            <div class="singleCategory__tool-name">
              <div class="singleCategory__tool-icon">
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <span>Электроинструменты</span>
            </div>
            <div class="singleCategory__tool-items">
              <div class="singleCategory__tool-item active_category">
                Перфораторы
              </div>
              <div class="singleCategory__tool-item">
                Шлифовальные и полировальные машины
              </div>
              <div class="singleCategory__tool-item">Пиление</div>
            </div>
            <div class="singleCategory__more">Посмотреть все</div>
          </div>
          <div class="singleCategory__type">
            <div class="singleCategory__type-title">Тип</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Шлифмашина угловая</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель-шуруповерт</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Перфоратор</span>
              </div>
            </div>
            <div class="singleCategory__more">Посмотреть все</div>
          </div>
          <div class="singleCategory__type">
            <div class="singleCategory__type-title">Бренды</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Шлифмашина угловая</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель-шуруповерт</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Перфоратор</span>
              </div>
            </div>
            <div class="singleCategory__more">Посмотреть все</div>
          </div>
          <div class="singleCategory__cost">
            <div class="singleCategory__type-title">Цена</div>
            <div class="singleCategory__cost-slider">
              <!-- <input type="range" v-modal.number="minPrice"> -->
              <!-- <input type="range" v-modal.number="maxPrice"> -->
            </div>
            <div class="singleCategory__cost-input">
              <input type="text" v-model="min_cost" />
              <input type="text" v-model="max_cost" />
            </div>

            <div class="singleCategory__additional">
              <div class="singleCategory__additional-title">
                Напряжение аккумулятора
              </div>
              <div class="singleCategory__type-items">
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="singleCategory__main">
          <div class="liked__buttons">
            <div
              class="liked__button"
              @click="liked_btn = 1"
              :class="{ liked__button_active: liked_btn === 1 }"
            >
              Избранные товары
            </div>
            <div
              class="liked__button"
              @click="liked_btn = 2"
              :class="{ liked__button_active: liked_btn === 2 }"
            >
              Избранные аптеки
            </div>
          </div>

          <div class="singleCategory__main-items" v-show="liked_btn === 1">
            <div
              class="singleCategory__main-item"
              v-for="item in items"
              :key="item.id"
            >
              <Card
                @press-like-icon="add_basket_card()"
                @deleteLikeCard="deleteLikeCard"
                :fixcount="item.fix_count"
                :image="item.img"
                :id="item.id"
                :discription="item.name"
                :salePersents="item.salePersents"
                :count="item.count"
                :price="item.cost"
                :salePrice="item.salePrice"
              >
              </Card>
              <div class="basket__none" v-show="items.length == 0">
                В ваших избранных товарах пусто
              </div>
            </div>
          </div>
          <div class="singleCategory__main-items" v-show="liked_btn === 2">
            <div
              class="singleCategory__main-item"
              v-for="(item, index) in shops"
              :key="index"
            >
              <MarketCard
                :is-liked="item.isLiked"
                :name="item.name"
                :image="item.img"
                :id="item.id"
                :category="item.category"
                :minAmount="item.minAmount"
                :rating="item.rating"
                :delivery-time="item.deliveryTime"
                :delivery-cost="item.deliveryCost"
                :city="item.city"
              >
              </MarketCard>
              <div class="basket__none" v-show="shops.length == 0">
                В ваших избранных магазинах пусто
              </div>
            </div>
          </div>
          <div class="singleCategory__main-pagination">
            <div class="singleCategory__main-count">
              <span class="active_page">1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
            <div class="singleCategory__main-icon">
              <img src="../assets/icons/components_icon/next_page.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="basket__none" v-if="items.length == 0 && shops.length == 0">
        В ваших избранных пусто
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Card from "../components/GoodsCard.vue";
import MarketCard from "../components/MarketShopCard.vue";
export default {
  data() {
    return {
      minPrice: 10,
      maxPrice: 1000,
      min_cost: "",
      max_cost: "",
      liked_btn: 1,
      items: [],
      shops: [],
    };
  },
  async created() {
    window.scrollTo(0, 0);
    await this.setLikedCard();
    await this.setLikedShops();
    this.showCompletedSections();
  },
  methods: {
    ...mapActions(["DELETE_CARD_ITEM"]),
    showCompletedSections() {
      if (this.items.length == 0) {
        this.liked_btn = 2;
      } else {
        return;
      }
    },
    deleteLikeCard(data) {
      this.DELETE_CARD_ITEM(data);
      this.items = this.$store.state.liked;
    },
    async setLikedCard() {
      this.items = [];
      this.items = this.$store.state.liked;
      console.log("log this.itemsitas", this.items);
    },
    async setLikedShops() {
      this.shops = [];
      this.shops = this.$store.state.marketItem;
    },
  },
  components: {
    Card,
    MarketCard,
  },
};
</script>

<style lang="scss">
.liked {
  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__button {
    padding: 16px 18px;
    width: 217px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #c4c4c4;

    @media (max-width: 991px) {
      font-size: 12px;
    }

    &:hover {
      color: #2ac1ce;
      border: 1px solid #2ac1ce;
    }

    &_active {
      color: #2ac1ce;
      border: 1px solid #2ac1ce;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@import "../assets/style/singleCategory.scss";
</style>