<template>
    <div class="market">
        <div class="content">
            <div class="singleCategory__breadcrumbs">
                <span><a href="">Главная
                    </a></span>
                <span><a href=""> • Аптеки
                    </a></span>
            </div>
            <div class="market__title">
                Все аптеки
            </div>

            <section class="home__category">
                <div class="home__title-section">Популярные категории</div>


                <div class="market-slide">
                    <div class="market-slide-wrapper">
                        <swiper class="market-swiper" :breakpoints="breakpoints" :lazy="true" :space-between="30"
                            :navigation="marketSwiperNavigation" :loop="false" @swiper="onSwiper">
                            <swiper-slide v-for="item in category" :key="item.id">
                                <div class="market__category">
                                    <div class="market__category-item" @click="openCategory(item.id)">
                                        <div class="market__category-img">
                                            <img :src="item.img" alt="">
                                        </div>
                                        <div class="market__category-name">
                                            {{ item.text }}
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="market-swiper-left">
                        <img src="../assets/icons/arrow_left.png" alt="Arrow">
                    </div>
                    <div class="market-swiper-right">
                        <img src="../assets/icons/arrow_right.png" alt="Arrow">
                    </div>
                </div>


                <button class="button button_category" @click="$router.push('/catalog')">
                    Перейти в каталог
                </button>
            </section>
            <div>
                <div class="market__items" :class="{ market__items_left: shops[number].length < 4 }">
                    <div class="market__item" v-for="(item, index) in shops[number]" :key="index">
                        <Card :name="item.name" :id="item.id" :isLiked="item.isLiked" :image="item.image"
                            :category="item.category" :rating="item.rating" :min-amount="item.minAmount"
                            :deliveryTime="item.deliveryTime" :delivery-cost="item.deliveryCost" :city="item.city">
                        </Card>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import Card from '../components/MarketShopCard.vue'
export default {
    data() {
        return {
            breakpoints: {
                330: {
                    slidesPerView: 2,
                },
                576: {
                    slidesPerView: 3,
                },
                991: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 5,
                },
                1400: {
                    slidesPerView: 6,
                },
            },
            refresh_count: true,
            marketSwiperNavigation: {
                prevEl: '.market-swiper-left',
                nextEl: '.market-swiper-right',
            },
            category: [
                {
                    id: 0,
                    img: require('../assets/img/home/category1.1.png'),
                    text: 'Мед.техника',
                },
                {
                    id: 1,
                    img: require('../assets/img/home/category2.1.png'),
                    text: 'Защитные средства ',
                },
                {
                    id: 2,
                    img: require('../assets/img/home/category3.1.png'),
                    text: 'Для малышей ',
                },
                {
                    id: 3,
                    img: require('../assets/img/home/category4.1.png'),
                    text: 'Витамины',
                },
                {
                    id: 4,
                    img: require('../assets/img/home/category5.1.png'),
                    text: 'Оптика',
                },
                {
                    id: 5,
                    img: require('../assets/img/home/category1.1.png'),
                    text: 'Мед.техника',
                },
                {
                    id: 6,
                    img: require('../assets/img/home/category2.1.png'),
                    text: 'Защитные средства ',
                },
                {
                    id: 7,
                    img: require('../assets/img/home/category3.1.png'),
                    text: 'Для малышей ',
                },
                {
                    id: 8,
                    img: require('../assets/img/home/category4.1.png'),
                    text: 'Витамины',
                },
                {
                    id: 9,
                    img: require('../assets/img/home/category5.1.png'),
                    text: 'Оптика',
                },

            ],
            number: 1,
            shops: [
                [
                    {
                        id: 0,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 1,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 2,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 3,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 4,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },

                ],
                [
                    {
                        id: 5,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 6,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 7,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 8,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                ],
                [
                    {
                        id: 9,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 10,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 11,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },

                ],
                [
                    {
                        id: 12,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 13,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 14,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 15,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 16,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },

                ],
                [
                    {
                        id: 17,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 18,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 19,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 20,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                ],
                [
                    {
                        id: 21,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 22,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 23,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },

                ],
                [
                    {
                        id: 24,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 25,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 26,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 27,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 28,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },

                ],
                [
                    {
                        id: 29,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 30,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 31,
                        name: 'СтройБетон',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для стройки',
                        rating: 3,
                        minAmount: 250000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 32,
                        name: 'Мегастой',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Товары для дома',
                        rating: 2,
                        minAmount: 150000,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                ],
                [
                    {
                        id: 33,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 34,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },
                    {
                        id: 35,
                        name: 'ЭлектроМаг',
                        image: require('../assets/img/home/brand.png'),
                        category: 'Электроника',
                        rating: 5,
                        minAmount: 200100,
                        deliveryTime: '21 июня',
                        deliveryCost: '1000 тг',
                        city: 'Аксай, Уральск, Кордай',
                        isLiked: false,
                    },

                ],


            ],
        }
    },
    methods: {
        ...mapActions([
            'GET_MARKET_ITEM'
        ]),
        openFullShop(item) {
            this.GET_MARKET_ITEM(item)
            console.log('asdasd', item);
            this.$router.push('/single-shop/' + item.id)
        },
        onSwiper(swiper) {
            console.log(swiper)
        },
        onSlideChange() {
            console.log('slide change')
        },
        openCategory(id) {
            this.number = id
            console.log(id);
        },

    },
    created() {
        window.scrollTo(0, 0)
    },
    // Swiper, SwiperSlide,
    components: {
        Card, Swiper, SwiperSlide,
    }
}
</script>

<style lang="scss">
@import '../assets/style/market.scss';
</style>