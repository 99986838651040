<template>
    <div class="basket">
        <div class="content">
            <div class="basket__wrapper">
                <h2 class="basket__title">
                    Оформление заказа
                </h2>
            </div>

            <div class="basket__none" v-if="items.length == 0">
                В вашей корзине пусто
            </div>
            <div class="basket__main" v-else>

                <div class="basket__items">
                    <div class="basket__main-title">
                        Корзина
                    </div>
                    <div class="basket__item" v-for="item in items" :key="item.id">
                        <BasketOrderItem @delete-item="deleteItem(item)" :isCounter="true" :icon="true" :rating="4"
                            :img="item.img" :count="item.count" :title="item.name" :cost="item.cost" :feedback="12">
                        </BasketOrderItem>
                    </div>
                </div>
                <div class="basket__form">

                    <div class="basket__form-data">
                        <div class="basket__form-title">
                            Данные получателя
                        </div>
                        <div class="basket__form-inputs">
                            <div class="basket__form-input">
                                <input type="text" placeholder="Имя*" v-model="form.name">
                                <span v-if="v$.form.name.$error"> Имя должно быть больше чем 2 символа </span>
                            </div>
                            <div class="basket__form-input">
                                <input type="text" placeholder="Фамилия*" v-model="form.surname">
                                <span v-if="v$.form.surname.$error"> Имя должно быть больше чем 2 символа </span>
                            </div>
                            <div class="basket__form-input">
                                <input type="text" placeholder="E-mail*" v-model="form.email">
                                <span v-if="v$.form.email.$error"> Введите корректный Email </span>
                            </div>
                            <div class="basket__form-input">
                                <input type="text" v-model="form.number" @keypress="isNumber" v-imask="phoneMask"
                                    placeholder="Номер телефона*" @accept="onAccept" @complete="onComplete">
                                <!-- <span v-if=" v$.form.number.$error">Введите номер формата +7(777)777 77 77</span> -->
                            </div>
                        </div>

                    </div>
                    <div class="basket__form-delivery">
                        <div class="basket__form-title">
                            Способ оплаты
                        </div>
                        <div class="basket__form-buttons">
                            <button class="basket__form-delivery-button" @click="delivery_way = 0"
                                :class="{ 'basket__form-delivery-button-active': delivery_way == 0 }">Самовывоз</button>
                            <button class="basket__form-delivery-button" @click="delivery_way = 1"
                                :class="{ 'basket__form-delivery-button-active': delivery_way == 1 }">Доставка</button>
                        </div>
                    </div>
                    <div class="basket__form-address" v-if="delivery_way == 0">
                        <div class="basket__form-title">
                            Выберите пункт выдачи
                        </div>
                        <div class="basket__form-inputs">
                            <div class="basket__form-input basket__form-input-max">
                                <select name="" id="">
                                    <option value="">г. Алматы, ул. Байтурсынова 231, офис 21</option>
                                    <option value="">г. Алматы, ул. Байтурсынова 231, офис 33</option>
                                    <option value="">г. Алматы, ул. Байтурсынова 231, офис 5б</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div class="basket__form-address" v-if="delivery_way == 1">
                        <div class="basket__form-title">
                            Адрес доставки
                        </div>
                        <div class="basket__form-inputs">
                            <div class="basket__form-input">
                                <select name="" id="">
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                </select>
                            </div>
                            <div class="basket__form-input">
                                <select name="" id="">
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                </select>
                            </div>
                            <div class="basket__form-input">
                                <input type="text">
                            </div>
                            <div class="basket__form-input">
                                <input type="text">
                            </div>
                        </div>
                    </div>
                    <div class="basket__form-payment" v-if="delivery_way == 1">
                        <div class="basket__form-payment-item">
                            <div class="basket__form-title">
                                Способ доставки
                            </div>
                            <select name="" id="">
                                <option value="">card</option>
                                <option value="">cash</option>
                                <option value="">123</option>
                            </select>
                        </div>
                        <div class="basket__form-payment-item">
                            <div class="basket__form-title">
                                Дата доставки
                            </div>
                            <select name="" id="">
                                <option value="">card</option>
                                <option value="">cash</option>
                                <option value="">123</option>
                            </select>
                        </div>
                    </div>
                    <div class="basket__form-title-small">
                        Комментарии к заказу
                    </div>
                    <div class="basket__form-comments">
                        <input type="text" placeholder="Комментарии">
                    </div>
                    <div class="basket__form-coupon">
                        <div class="basket__form-title">
                            Использовать купон
                        </div>
                        <div class="basket__form-coupon-input">
                            <input type="text">
                            <button class="basket__form-coupon-button">Применить</button>
                        </div>
                        <div class="basket__form-coupon-info">
                            <div class="basket__form-coupon-info-item">
                                <div class="basket__form-coupon-info-text">
                                    Товары:
                                </div>
                                <div class="basket__form-coupon-info-text basket__form-coupon-info-text-bold">
                                    44 500 ₸
                                </div>
                            </div>
                            <div class="basket__form-coupon-info-item">
                                <div class="basket__form-coupon-info-text">
                                    Доставка курьером:
                                </div>
                                <div class="basket__form-coupon-info-text basket__form-coupon-info-text-bold">
                                    1000 ₸
                                </div>
                            </div>
                            <div class="basket__form-coupon-info-item">
                                <div class="basket__form-coupon-info-text">
                                    Скидка:
                                </div>
                                <div class="basket__form-coupon-info-text basket__form-coupon-info-text-bold">
                                    45%
                                </div>
                            </div>
                            <div class="basket__form-coupon-info-item basket__form-coupon-info-item-last">
                                <div class="basket__form-coupon-info-text basket__form-coupon-info-text-bold">
                                    Итого:
                                </div>
                                <div class="basket__form-coupon-info-text basket__form-coupon-info-text-bold">
                                    39 677 ₸
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="basket__form-agreement">
                        <input id="agreement" type="checkbox">
                        <label for="agreement">Я соглашаюсь с условиями <a href="">публичной оферты</a> и обработкой
                            моих персональных данных в порядке, предусмотренном публичной офертой.</label>
                    </div>
                    <button class="basket__form-send" @click="submitForm()">
                        Подтвердить заказ
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, email, } from '@vuelidate/validators'
import { IMaskDirective } from 'vue-imask';


import BasketOrderItem from '../components/BasketOrderItem.vue';
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            v$: useVuelidate(),
            delivery_way: 0,
            total_cost: 0,
            good_count: 1,
            isChecked: false,
            sale: 1000000,
            cost_with_sale: 122222,
            goods_count: 0,
            item_count: 12,
            items: [],
            form: {
                name: '',
                surname: '',
                email: '',
                phone: '',
                comments: '',

            },
            phoneMask: {
                mask: '+{7} ({000}) 000-00-00',
                lazy: true
            }
        }
    },
    async created() {
        if (this.$store.state.card == []) {
            console.log('111');
        } else {
            await this.setBacketCards()
            this.setAddMenu()
        }
        console.log('this.items', this.items);
    },
    computed: {
        formValid() {
            return this.v$.$invalid
        },
        ...mapGetters([
            'STATE'
        ])
    },
    validations() {
        // const mustBeCorrectPhoneNumber = (value) => value.include('+7')
        return {
            form: {
                name: { required, minLength: minLength(6) },
                surname: { required },
                email: { required, email },
                phone: { required },
            }
        }

    },
    methods: {
        isNumber(e) {
            let regex = /[0-9]/
            if (!regex.test(e.key)) {
                e.returnValue = false;
                if (e.preventDefault) e.preventDefault();
            }
        },
        onAccept(e) {
            const maskRef = e.detail;
            this.value = maskRef.value;
        },
        onComplete(e) {
            const maskRef = e.detail; // что бы в дальнейшем сохранить 
            console.log('complete', maskRef.unmaskedValue);
        },
        checkPassword(password) {
            if (password) {
                console.log(123123);
            }
        },
        checkPhoneNumber(value) {
            return value.include('+7')
        },
        submitForm() {
            this.v$.$validate()
            if (!this.v$.$error) {
                console.log('submit', this.v$);
            } else {
                console.log('Form not pass validation');
            }
        },
        goodsCount() {
            let total_count = 0;
            for (let i = 0; i < this.items.length; i++) {
                total_count += this.items[i].count
            }
            return total_count
        },
        setAddMenu() {
            this.total_cost = 0
            this.goods_count = 0
            this.cost_with_sale = 0
            this.sale = 0
            console.log(this.items, 'log items fron total cost');
            for (let i = 0; i < this.items.length; i++) {
                this.goods_count += this.items[i].count
                this.total_cost += this.items[i].salePrice * this.items[i].count
                this.cost_with_sale += this.items[i].cost * this.items[i].count
                this.sale = this.total_cost - this.cost_with_sale
            }
        },
        async setBacketCards() {
            this.items = []
            this.items = this.$store.state.card
            console.log(this.items, 'itemsitemsitems');
        },
        checkInformationAndRouterPush() {
            this.$router.push('/my-order')
        },

        minusItemCount(item) {
            if (item.count <= 0) {
                this.deleteItem(item)

            } else if (item.fixcount == item.count) {
                return
            } else {
                item.count = item.count - 1
                this.setAddMenu()
            }
        },
        plusItemCount(item) {
            item.count = item.count + 1
            this.setAddMenu()
        },
        deleteItem(item) {
            this.$store.commit('DELETE_BASKET_ITEM', item)

        },
        // totalCost() {
        //     let cost = 0;
        //     for (let i = 0; i < this.items.length; i++) {
        //         cost = this.items[i].cost * this.items[i].count
        //         cost += cost
        //         console.log('cost', cost);
        //     }
        //     this.total_cost = cost
        // }
    },
    components: {
        BasketOrderItem
    },
    directives: {
        imask: IMaskDirective
    }
}
</script>

<style lang="scss" >
.active_input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 18px;
    width: 18px;
    background: url(../assets/icons/basket/check.svg);
}

@import '../assets/style/basket.scss';
</style>