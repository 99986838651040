<template>
    <div class="header-modal">
        <div class="header-modal__bg" @click="$emit('openModal')">
            <div class="modal__take">
                <div class="modal__take-close">
                    <div class="modal__take-icon" @click="$emit('openModal')">
                        <img src="../assets/icons/components_icon/close.svg" alt="">
                    </div>
                </div>
                <div class="modal__take-wrapper">
                    <div class="modal__take-title">
                        Вы не приобретали этот товар
                    </div>
                    <div class="modal__take-subtitle">
                        Мы не нашли этот товар среди ваших покупок. Теперь вы можете оставлять отзывы только к тем
                        товарам,
                        которые были куплены на GES.
                    </div>
                    <button class="button button_take" @click="$emit('openModal')">
                        Закрыть
                    </button>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
export default {
    data() {
        return {
            modal: false,
        }
    },
    methods: {
        openModal() {
            this.$emit('openModal')
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    &-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0;
        z-index: 99999999;
        display: flex;
        align-items: center;
        justify-content: center;

        &__bg {
            position: fixed;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.36);
            z-index: -1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.modal__take {
    padding: 16px 16px 80px;
    width: 800px;
    height: 356px;
    background: #FFFFFF;

    &-close {
        display: flex;
        justify-content: flex-end;
    }

    &-wrapper {
        padding: 0 39px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 991px) {
            padding: 10px;
        }
    }

    &-icon {
        width: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &-title {
        margin-top: 40px;
        font-weight: 600;
        font-size: 32px;
        color: #202020;
        text-align: center;
    }

    &-subtitle {
        margin-top: 32px;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #727272;
    }

}
</style>
а