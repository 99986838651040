<template>
    <div class="app">

        <div>
            <slot />
        </div>

        <div class="auth-footer">
            <div class="content">
                <div class="auth-footer__wrapper">
                    <div class="auth-footer__label">
                        © 2022 TOO Pharmart. Все права защищены
                    </div>
                    <div class="auth-footer__social">
                        <div class="auth-footer-item">
                            <img src="../../assets/icons/insta_black.png" alt="">
                        </div>
                        <div class="auth-footer-item">
                            <img src="../../assets/icons/vk_black.png" alt="">
                        </div>
                        <div class="auth-footer-item">
                            <img src="../../assets/icons/fb_black.png" alt="">
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.auth-footer {
    height: 100px;
    background: #F2F2F2;
    padding: 30px 0;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__label {
        font-weight: 400;
        font-size: 14px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #202020;
    }

    &__social {
        display: flex;
        align-items: center;
        gap: 0 16px;
    }

    &__item {
        width: 40px;
    }
}
</style>