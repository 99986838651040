import {
  createStore
} from 'vuex';

import axios from "axios";
// import axios from "axios";
// import createPersistedState from "vuex-persistedstate";
// import api from '../api/index';
import productData from '../data/product_list.json';
import orders from '../data/account_orders.json';
import feedbacks from '../data/feedbacks.json';
import productAllInfo from '../data/all_single_product_info.json';
export default createStore({
  // plugins: [
  //   createPersistedState({
  //     storage: window.sessionStorage,
  //   }),
  // ],
  name: 'Product',
  namespaced: true,
  state: {
    goods: [],
    users: [],
    newUsers: [],
    product: [], // all goods from data
    card: JSON.parse(localStorage.getItem('basket')) || [],
    singleCard: [], //slot for clicked card
    liked: JSON.parse(localStorage.getItem('favourites')) || [],
    categories: [],
    subCategory: [],
    lang: localStorage.getItem('lang') || "ru",
    marketItem: [],
    shopCards: [],
    singleMarketShop: [],
    setUser: [],

    // orders list for account orders 
    orders: [],
    //site search
    searchValue: [],

    singleProductInfo: [],
    isAuth: false,
    unAuth: false,
    // JSON INFO 
    allFeedbacks: [],
    allProductInfo: [],
  },

  getters: {
    STATE(state) {
      return state;
    },    
    allCategories: (state) => state.categories,
    SEARCH_VALUE(state) {
      return state.searchValue;
    },
    ACCOUNT_ORDERS(state) {
      return state.orders;
    },
    getUser: (state) => state.setUser,
    getIsAuth: (state) => state.isAuth,
    getCountCard(state) {
      return state.card.length
    },
    getLang: (state) => state.lang,
    getCountLiked(state) {
      return state.liked.length
    }
  },

  mutations: {
    SET_SHOP_CARD(state, product) {
      console.log('log mutaution setshop');
      state.singleCard = product
    },    
    UPDATE_LANG(state, lang) {
      state.lang = lang
      localStorage.setItem("lang", lang);
    },
    SET_CARD(state, product) {
      state.card.push(product)
    },
    SET_LIKED(state, product) {
      console.log('set liked work');
      state.liked.push(product)
    },
    SET_CATEGORY(state, categories) {
      state.categories = categories
    },
    SET_SUB_CATEGORY(state, subCategory) {
      state.subCategory = subCategory
    },
    PRODUCT_LIST(state, payload) {
      state.product = payload
    },
    SET_MARKET_ITEM(state, item) {
      state.marketItem.push(item);

    },
    SET_MARKET_ITEM_SINGLE(state, item) {
      state.singleMarketShop = item;
    },
    DELETE_LIKED_GOOD(state, id) {
      state.liked = state.liked.filter(i => i.id !== id)
      localStorage.setItem('favourites', JSON.stringify(state.liked) )
    },
    DELETE_MARKET_ITEM(state, id) {
      let item = state.marketItem.find(it => it.id === parseInt(id));
      let index = state.marketItem.indexOf(item);
      state.marketItem.splice(index, 1);
      console.log(state.marketItem);
    },
    DELETE_BASKET_ITEM(state, item) {
      state.card = state.card.filter(i => i.id !== item.id)
      localStorage.setItem('basket', JSON.stringify(state.card) )
    },
    //SET SINGLE JSON PRODUCT 
    SET_SINGLE_PRODUCT_INFO(state, item) {
      state.singleProductInfo = item
    },
    //post single feedback
    POST_FEEDBACK(state, data) {
      state.allFeedbacks.push(data)
    },
    // JSON INFO SET
    SET_ALL_FEEDBACKS(state, res) {
      state.allFeedbacks = res
    },
    SET_ALL_PRODUCTS_INFO(state, res) {
      state.allProductInfo = res
    },
    SET_SEARCH_VALUE(state, value) {
      state.searchValue = value
    },
    SET_ORDERS_LIST(state, data) {
      state.orders = data
    },
    SET_AUTH(state, payload) {
      state.isAuth = payload;
      // console.log(isAuth,"state")
    },
    SET_UN_AUTH(state, resp) {
      state.unAuth = resp;
    },
    setUser(state, userI) {
      state.setUser = userI;
      // console.log("state",state.setUser)
    },
  },

  actions: {
    DELETE_BASKET_ITEM(context, id) {
      context.commit('DELETE_BASKET_ITEM', id)
    },
    DELETE_CARD_ITEM(context, id) {
      context.commit('DELETE_LIKED_GOOD', id)
    },
    DELETE_MARKET_ITEM(context, id) {
      context.commit('DELETE_MARKET_ITEM', id)
    },
    async GET_MARKET_ITEM({
      commit
    }, item) {
      commit('SET_MARKET_ITEM', item);
    },
    async GET_MARKET_ITEM_SINGLE({
      commit
    }, item) {
      commit('SET_MARKET_ITEM_SINGLE', item);
    },
    async getCategories({ commit }) {
      await axios
      .get(`/categories`)
      .then((response) => {
        commit("SET_CATEGORY", response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    },
    async GET_SUB_CATEGORY({
      commit
    }, subCategory) {
      commit('SET_SUB_CATEGORY', subCategory)
    },
    ADD_PRODUCT_TO_BASKET({ state
    }, product) {
      let item = state.card.find(i => i.id === product.id)
      if (item) {
          item.count = product.count
      }
      else state.card.push({...product})
      localStorage.setItem('basket', JSON.stringify(state.card) )
    },
    ADD_LIKED_PRODUCT({ state }, product) {
      let item = state.liked.find(i => i.id === product.id)
      if (!item) {
        console.log('item');
        state.liked.push({...product})
      }
      localStorage.setItem('favourites', JSON.stringify(state.liked) )
    },

    //json info
    async GET_ALL_PRODUCTS_INFO(context) {
      const response = productAllInfo
      context.commit("SET_ALL_PRODUCTS_INFO", response)
      return response;
    },

    async GET_ALL_FEEDBACKS(context) {
      const response = feedbacks
      context.commit("SET_ALL_FEEDBACKS", response)
      return response;
    },
    async POST_FEEDBACK(context, data) {
      context.commit("POST_FEEDBACK", data)
    },
    // site search 
    SET_SEARCH_VALUE_TO_VUEX(context, value) {
      context.commit('SET_SEARCH_VALUE', value)
    },
    async GET_PRODUCT_LIST(context) {
      const response = productData
      context.commit('PRODUCT_LIST', response); // Рабоает
      return response;
    },

    // json info
    async GET_ORDERS_LIST(context) {
      const response = orders;
      context.commit('SET_ORDERS_LIST', response);
      return response;
    },


    checkAuth({ commit }) {
      if (localStorage.getItem("access_token")) {
        commit("SET_AUTH", true);
      }
    },
    async requestUser({ commit }) {
      try {
        const response = await axios.get("client/info", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        commit("setUser", response.data);
        commit("SET_UN_AUTH", false);
        commit("SET_AUTH", true);
        // console.log('BBBBB',response.data.data)
      } catch (error) {
        // alert(error)
        console.log(error);
        commit("SET_UN_AUTH", true);
        commit("SET_AUTH", false);
      }
    },
    async logout({ commit }) {
      try {
        await axios.post(
          "V1/logout",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        localStorage.removeItem("access_token");
        
        commit("SET_AUTH", false);
        window.location.href = "/"
      } catch (error) {
        console.log(error);
      }
    },

  },

})