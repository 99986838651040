<template>
  <div>
    <div class="basket-order">
      <div class="basket-order__img">
        <img :src="img" alt="" />
      </div>
      <div class="basket-order__main">
        <div class="basket-order__item">
          <div class="basket-order-title">
            {{ title }}
          </div>
          <div class="basket-order-cost">{{ cost }} ₸</div>
          <div class="basket-order-delete" v-if="icon" @click="deleteItem">
            <img src="../assets/icons/basket/delete2.svg" alt="" />
          </div>
        </div>
        <div class="basket-order__item">
          <div class="basket-order__item-provider">Аптека</div>
          <div class="basket-order__item-rating">
            <div>
              <img src="../assets/icons/card/start_yellow.svg" alt="" />
            </div>
            {{ rating }}
          </div>
          <div class="basket-order__item-feedback">{{ feedback }} отзывов</div>
          <div class="basket-order__counter" v-if="isCounter">
            <button @click="minusItemCount()">-</button>
            <div class="basket-order__counter-number">
              {{ basketData.count }}
            </div>
            <button @click="plusItemCount()">+</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      basketData: {
        img: this.img,
        title: this.title,
        cost: this.cost,
        rating: this.rating,
        feedback: this.feedback,
        count: this.count,
      },
    };
  },
  props: [
    "img",
    "title",
    "cost",
    "rating",
    "feedback",
    "count",
    "icon",
    "isCounter",
  ],
  methods: {
    deleteItem() {
      this.$emit("delete-item");
    },
    minusItemCount() {
      let count = this.basketData.count;
      if (count <= 1) {
        return;
      } else {
        this.basketData.count = count - 1;
      }
    },
    // router !!!
    async routeTo(id) {
      let category = this.additionaly.category;
      await this.getCategories(this.additionaly);
      await this.GET_SUB_CATEGORY(this.additionaly.sub_menu[id]);
      this.$router.push("/single-category/" + category + "/" + id);
      this.openModal();
    },

    plusItemCount() {
      this.basketData.count = this.basketData.count + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.basket-order {
  width: 100%;
  height: 140px;
  border: 1px solid #efefef;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 15px;

  &__main {
    margin-left: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(2) {
      margin-top: 20px;
    }

    &-rating {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      line-height: 17px;
      color: #a9a9a9;
    }

    &-provider {
      color: #2ac1ce;
      opacity: 0.6;

      &:hover {
        opacity: 1;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &-feedback {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #626262;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &-cost {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #2c3131;
  }

  &-delete {
    margin-left: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    border: 1px solid #a9a9a9;
    width: 120px;

    &-number {
      border-left: 1px solid #a9a9a9;
      border-right: 1px solid #a9a9a9;
      padding: 0 10px;
    }

    button {
      background-color: #fff;
      border: none;
      height: 100%;
      width: 30px;
      cursor: pointer;
    }
  }

  &__img {
    max-height: 100px;
    max-width: 100px;
  }

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #2c3131;
    width: 300px;
  }
}
</style>