<template>
    <div class="stars">
        <div class="star-rating">
            <span class="star star-outlained" v-for="index in starsLimit" :key="index">
            </span>
            <div class="star-rating__colored" :style="raitingWidthStyle">
                <span class="star star-colored" v-for="index in starsLimit" :key="index">
                </span>
            </div>
        </div>

        <div class="star__count" v-if="numbers">
            {{ rating }} / {{ starsLimit }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'StarRating',
    props: {
        rating: {
            type: Number,
            default: 0
        },
        starsLimit: {
            type: Number,
            default: 5,
        },
        numbers: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        raitingWidth() {
            return this.rating / this.starsLimit * 100
        },
        raitingWidthStyle() {
            return `width: ${this.raitingWidth}%;`
        }
    }
}
</script>

<style lang="scss" scoped>
.stars {
    display: flex;
    align-items: center;
    gap: 0 10px;
}

.star-rating {
    position: relative;
    display: inline-flex;
    &__colored {
        position: absolute;
        display: inline-flex;
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    .star {
        display: inline-block;
        width: 16px;
        height: 16px;
        flex-shrink: 0;

        &-colored {
            background: url('../assets/icons/components_icon/filled_star.svg') center no-repeat;
        }

        &-outlained {
            background: url('../assets/icons/components_icon/empty_star.svg') center no-repeat;
        }
    }
}
</style>