<template>
  <div class="authorization">
    <div class="content">
      <div class="authorization-enter">
        <div class="authorization-enter__wrapper">
          <h2 class="authorization__title">Вход</h2>

          <div class="authorization-enter__items">
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">E-mail</div>
              <div class="authorization-enter__input">
                <input v-model="email" type="email" />
              </div>
            </div>
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">Пароль</div>
              <div
                class="authorization-enter__input authorization-enter__password"
              >
                <input
                  v-model="password"
                  type="password"
                  id="password-input"
                  placeholder="Введите пароль"
                  name="password"
                />
                <div class="authorization-enter__password-visibility"></div>
              </div>
            </div>
          </div>
          <div class="authorization-enter__activity">
            <div class="authorization-enter__active">
              <input type="checkbox" />
              <p>Запомнить меня</p>
            </div>
            <div class="authorization-enter__forgot">
              <p>Забыли пароль?</p>
            </div>
          </div>
          <button class="button button_auth" @click="login">Войти</button>

          <div class="authorization-enter__devider">
            <div class="authorization-enter__line"></div>
            <p>или</p>
            <div class="authorization-enter__line"></div>
          </div>

          <div class="authorization-enter__social">
            <div class="authorization-enter__link">
              <img src="../../assets/icons/insta_black.png" alt="" />
            </div>
            <div class="authorization-enter__link">
              <img src="../../assets/icons/vk_black.png" alt="" />
            </div>
            <div class="authorization-enter__link">
              <img src="../../assets/icons/fb_black.png" alt="" />
            </div>
          </div>

          <div class="authorization-enter__registration">
            Нет аккаунта?
            <span @click="$router.push('/registration')"
              >Зарегистрироваться</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import { createToaster } from "@meforma/vue-toaster";
import { mapActions } from "vuex";
const toaster = createToaster({ position: "top-right" });
export default {
  data() {
    return {
      v$: useVuelidate(),
      email: "",
      password: "",
    };
  },
  validations() {
    // const mustBeCorrectPhoneNumber = (value) => value.include('+7')
    return {
      email: { required, email },
      password: { required, minLength: minLength(8) },
    };
  },
  methods: {
    ...mapActions(["checkAuth"]),
    login() {
      this.v$.$validate();
      if (!this.v$.$invalid) {
        const article = {
          email: this.email,
          password: this.password,
        };
        this.$axios
          .post("/client/auth/login", article)
          .then((res) => {
            toaster.success("Вы успешно прошли регистрацию!");
            localStorage.setItem("access_token", res.data.accessToken);
            this.checkAuth();
            this.$router.push("/acc");
          })
          .catch(() => {
            toaster.warning("Произошло ошибка");
          });
      } else {
        toaster.warning("Проверьте заполненность полей");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/enter.scss";
</style>