<template>
  <div class="authorization">
    <div class="content">
      <div class="authorization-enter">
        <div class="authorization-enter__wrapper">
          <h2 class="authorization__title">Регистрация</h2>
          <div class="authorization-enter__items">
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">
                Фамилия <span>*</span>
              </div>
              <div class="authorization-enter__input">
                <input
                  type="text"
                  v-model="surname"
                  placeholder="Наименование организации"
                />
                <span v-if="v$.name.$error">
                  Имя должно быть больше чем 2 символа
                </span>
              </div>
            </div>
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">Имя <span>*</span></div>
              <div class="authorization-enter__input">
                <input
                  type="text"
                  v-model="name"
                  placeholder="Наименование организации"
                />
                <span v-if="v$.name.$error">
                  Имя должно быть больше чем 2 символа
                </span>
              </div>
            </div>
            <!-- <div class="authorization-enter__item">
                            <div class="authorization-enter__name">
                                БИН <span>*</span>
                            </div>
                            <div class="authorization-enter__input">
                                <input type="number" v-model="bin" id="bin-input" placeholder="БИН">
                                <span v-if="v$.bin.$error">Введите корректный БИН</span>
                            </div>
                        </div> -->
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">E-mail<span>*</span></div>
              <div class="authorization-enter__input">
                <input type="text" v-model="email" placeholder="Email" />
                <span v-if="v$.email.$error">Введите корректный Email</span>
              </div>
            </div>
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">
                Введите номер<span>*</span>
              </div>
              <div class="authorization-enter__input">
                <input
                  type="text"
                  v-model="number"
                  @keypress="isNumber"
                  v-imask="phoneMask"
                  placeholder="+7 (777) 777-77-77"
                  @accept="onAccept"
                  @complete="onComplete"
                />
                <span v-if="v$.number.$error"
                  >Введите номер формата +7(777)777 77 77</span
                >
              </div>
            </div>
            <!-- <div class="authorization-enter__item">
                            <div class="authorization-enter__name">
                                Юридический адрес<span>*</span>
                            </div>
                            <div class="authorization-enter__input">
                                <input type="text" v-model="address.legal">
                                <span v-if="v$.address.legal.$error">Введите корректный адрес</span>
                            </div>
                        </div>
                        <div class="authorization-enter__item">
                            <div class="authorization-enter__name">
                                Фактический адрес<span>*</span>
                            </div>
                            <div class="authorization-enter__input">
                                <input type="text" v-model="address.actual" id="password-input" name="password">
                                <span v-if="v$.address.actual.$error">Введите корректный адрес</span>
                            </div>
                        </div> -->
            <!-- <div class="authorization-enter__item">
                            <div class="authorization-enter__name">
                                Дополнительная информация
                            </div>
                            <div class="authorization-enter__input authorization-enter__more">
                                <input type="text" v-model="information">
                            </div>
                        </div> -->
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">Пароль<span>*</span></div>
              <div class="authorization-enter__input">
                <input
                  type="password"
                  v-model="password.password"
                  id="password-input"
                  name="password"
                />
                <span v-if="v$.password.confirm.$error"
                  >Пароли должны совпадать</span
                >
                <span v-if="!v$.password.password.minLength">
                  Пароль должен содержать минимум 6 символов</span
                >
              </div>
            </div>
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">
                Повторить пароль<span>*</span>
              </div>
              <div class="authorization-enter__input">
                <input
                  type="password"
                  v-model="password.confirm"
                  id="password-input1"
                  name="password"
                />
                <span v-if="v$.password.confirm.$error"
                  >Пароли должны совпадать</span
                >
              </div>
            </div>
          </div>
          <div class="authorization-enter__activity">
            <div class="authorization-enter__active">
              <input type="checkbox" v-model="agreed" />
              <p class="authorization-enter__personaldata">
                Я согласен на обработку
                <span @click="$router.push('/personal-data')"
                  >персональных данных</span
                >
                и ознакомился с
                <span @click="$router.push('/confidential')"
                  >политикой конфиденциальности</span
                >.
              </p>
            </div>
          </div>
          <button class="button button_auth" @click="submitForm()">
            Зарегистрироваться
          </button>

          <div class="authorization-enter__registration">
            Уже есть аккаунт? <span @click="$router.push('/auth')">Войти</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { directive } from '@babel/types'
import useVuelidate from "@vuelidate/core";
import { required, minLength, email, sameAs } from "@vuelidate/validators";
import { IMaskDirective } from "vue-imask";
import { createToaster } from "@meforma/vue-toaster";
import { mapActions } from "vuex";
const toaster = createToaster({ position: "top-right" });
export default {
  data() {
    return {
      v$: useVuelidate(),
      surname: "",
      name: "",
      email: "",
      number: "",
      password: {
        password: "",
        confirm: "",
      },
      agreed: "",
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
    };
  },
  computed: {
    formValid() {
      return this.v$.$invalid;
    },
  },
  validations() {
    // const mustBeCorrectPhoneNumber = (value) => value.include('+7')
    return {
      name: { required, minLength: minLength(2) },
      surname: { required, minLength: minLength(2) },
      email: { required, email },
      number: { required },
      password: {
        password: { required, minLength: minLength(8) },
        confirm: { required, sameAs: sameAs(this.password.password) },
      },
      agreed: { required },
    };
  },
  methods: {
    ...mapActions(["checkAuth"]),
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; // что бы в дальнейшем сохранить
      console.log("complete", maskRef.unmaskedValue);
    },
    checkPassword(password) {
      if (password) {
        console.log(123123);
      }
    },
    checkPhoneNumber(value) {
      return value.include("+7");
    },
    submitForm() {
      this.v$.$validate();
      if (!this.v$.$invalid) {
        const article = {
          name: this.name,
          surname: this.surname,
          phone: this.number.replace(/\D/g, ""),
          email: this.email,
          password: this.password.password,
        };
        this.$axios
          .post("/client/auth/register", article)
          .then((res) => {
            toaster.success("Вы успешно прошли регистрацию!");
            localStorage.setItem("access_token", res.data.accessToken);
            this.checkAuth();
          })
          .catch(() => {
            toaster.warning("Произошло ошибка");
          });
        console.log("submit", this.v$);
      } else {
        console.log("Form not pass validation");
      }
    },
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/enter.scss";
</style>