<template>
    <div class="acc-profile">
        <div class="acc-profile__wrapper">
            <div class="acc__title">
                Мои избранные
            </div>
            <div class="liked__buttons">
                <div class="liked__button" @click="liked_btn = 1" :class="{ liked__button_active: liked_btn === 1 }">
                    Личные данные
                </div>
                <div class="liked__button" @click="liked_btn = 2" :class="{ liked__button_active: liked_btn === 2 }">
                    Изменить пароль
                </div>
            </div>

            <div class="acc-profile__main" v-show="liked_btn == 1">
                <div class="acc-profile__logo">

                </div>
                <div class="acc-profile__items">
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Наименование организации
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            БИН
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            E-mail
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Номер телефона
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Юридический адрес
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Фактический адрес
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <button class="button button_auth">
                        Сохранить
                    </button>
                </div>
            </div>
            <div class="acc-profile__main" v-show="liked_btn == 2">

                <div class="acc-profile__items2">
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Текущий пароль
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Новый пароль
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Новый пароль еще раз
                        </div>
                        <div class="acc-profile__input">
                            <input type="text">
                        </div>
                    </div>
                    <button class="button button_auth">
                        Изменить пароль
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            liked_btn: 1,
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/style/acc/profile.scss';
</style>