<template>
    <div class="acc-comments">
        <div class="acc__title">
            Мои отзывы
        </div>
        <div class="liked__buttons">
            <div class="liked__button" @click="liked_btn = 1" :class="{ liked__button_active: liked_btn === 1 }">
                Все отзывы
            </div>
            <div class="liked__button" @click="liked_btn = 2" :class="{ liked__button_active: liked_btn === 2 }">
                Ожидают отзыва
            </div>
        </div>
        <div class="acc-comments__main" v-show="liked_btn === 1">
            <div class="acc-comments__items">
                <div class="acc-comments__item" v-for="comment in comments" :key="comment.id">
                    <div class="acc-comments__good">
                        <div class="acc-comments__img">
                            <img src="../../assets/img/good.png" alt="">
                        </div>
                        <div class="acc-comments__discr">
                            <div class="acc-comments__name">
                                {{ comment.name }}
                            </div>
                            <div class="acc-comments__count">
                                {{ comment.count }}
                            </div>
                        </div>

                    </div>
                    <div class="acc-comments__comment">
                        <div class="acc-comments__mark">
                            <StarsRatingVue :rating="3" :numbers="false"></StarsRatingVue>
                            <div class="acc-comments__company">
                                {{ comment.company }}
                            </div>
                            <div class="acc-comments__time">
                                {{ comment.time }}
                            </div>
                        </div>
                        <div class="acc-comments__text">
                            <div class="acc-comments__title">
                                Комментарий:
                            </div>
                            <div class="acc-comments__text">
                                {{ comment.text }}
                            </div>
                        </div>
                    </div>
                    <div class="acc-comments__status">
                        <div class="acc-comments__icon">
                            <img src="../../assets/icons/acc/view.svg" alt="">
                        </div>
                        Отзыв опубликован
                    </div>
                </div>
            </div>
        </div>
        <div class="acc-comments__main" v-show="liked_btn == 2">
            <div class="acc-comments__waiting">
                <div class="acc-comments__block" v-for="comment in comments" :key="comment.id">
                    <div class="acc-comments__info">
                        <div class="acc-comments__img">
                            <img src="../../assets/img/good.png" alt="">
                        </div>
                        <div class="acc-comments__name">
                            {{ comment.name }}
                            <span>
                                {{ comment.count }}
                            </span>
                        </div>
                    </div>
                    <div class="acc-comments__write">
                        <div class="acc-comments__icon">
                            <img src="../../assets/icons/acc/pen.svg" alt="">
                        </div>
                        Написать отзыв
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StarsRatingVue from '../../components/StarsRating.vue'
export default {
    data() {
        return {
            liked_btn: 1,
            comments: [
                {
                    id: 0,
                    name: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE',
                    count: '12 шт в упаковке',
                    company: 'Мегастрой',
                    time: '06.02.2022',
                    text: 'Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.',
                },
                {
                    id: 1,
                    name: 'Аккумуляторная дрель-шуруповерт Makita DF330DWE',
                    count: '12 шт в упаковке',
                    company: 'Мегастрой',
                    time: '06.02.2022',
                    text: 'Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.',
                },
            ],
        }
    },
    components: {
        StarsRatingVue
    }
}
</script>

<style lang="scss">
@import '../../assets/style/acc/comments.scss';
</style>