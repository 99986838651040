<template>
    <div>
        <div class="bill">
            <div class="bill__title">
                Готово к заказу:
            </div>
            <div class="bill__items">
                <div class="bill__item" v-for="(item, index) in 3" :key="index">
                    <label class="checkbox-google">
                        <input type="checkbox" checked>
                        <span class="checkbox-google-switch"></span>
                    </label>
                    <div class="bill__content">
                        <div class="bill__summary">
                            <div class="bill__number">
                                Заказ № {{ index + 1 }}
                            </div>
                            <div class="bill__way">
                                Доставка
                            </div>
                        </div>
                        <div class="bill__delivery">
                            <div class="bill__cost">
                                232 680 ₸
                            </div>
                            <div class="bill__cost">
                                1 000 ₸
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bill__promo">
                <input type="text" placeholder="Введите промокод">
                <button class="bill__promo-button">></button>
            </div>
            <div class="bill__total">
                <span>
                    Итого
                </span>
                <span>
                    700 000 ₸
                </span>
            </div>
            <div class="bill__goods">
                <span>
                    Товары, 36 шт
                </span>
                <div class="bill__goods-cost">
                    698 040 ₸
                </div>
            </div>
            <div class="bill__sale">
                <span>
                    Скидка
                </span>
                <div class="bill__sale-red">
                    - 1 040 ₸
                </div>
            </div>
            <div class="bill__sale">
                <span>
                    Общая сумма доставки
                </span>
                <div class="bill__sale-cost">
                    3 000 ₸
                </div>
            </div>
            <div class="bill__check">
                Выставить КП
            </div>
            <button class="bill__button" @click="$router.push('/end-order')">
                Отправить заявку
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.bill {
    padding: 24px 20px;
    width: 431px;
    height: 670px;
    background: #FFFFFF;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

    &__title {
        font-weight: 700;
        font-size: 26px;
        color: #202020;
    }

    &__item {
        margin-top: 24px;
        display: flex;
        gap: 0 10px;
        align-items: flex-start;

    }

    &__content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &__summary {
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 12px 0;
        font-size: 16px;
        line-height: 20px;
        color: #202020;
    }

    &__delivery {
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 12px 0;
        font-size: 16px;
        line-height: 20px;
        color: #202020;
    }

    &__promo {
        margin-top: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
            padding: 16px 24px 16px 16px;
            width: 339px;
            height: 52px;
            border: 1px solid #C2C2C2;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            background: #282828;
            color: #FFFFFF;
            font-size: 26px;
        }
    }

    &__total {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        font-size: 26px;
        color: #202020;
    }

    &__goods {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-cost {
            font-weight: 500;
            font-size: 18px;
            color: #202020;
        }

        span {
            font-weight: 400;
            font-size: 18px;
            color: #727272;
        }
    }

    &__sale {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-cost {
            font-weight: 500;
            font-size: 18px;
            color: #202020;
        }

        span {
            font-weight: 400;
            font-size: 18px;
            color: #727272;
        }
    }

    &__check {
        margin-top: 24px;
        font-weight: 400;
        font-size: 18px;
        text-decoration-line: underline;
        color: #202020;
    }

    &__button {
        margin-top: 24px;
        padding: 16px 24px;
        width: 100%;
        height: 51px;
        background: #282828;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
    }
}

.checkbox-google {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    margin-right: 10px;
    position: relative;
    vertical-align: middle;
    font-size: 14px;
    user-select: none;
}

.checkbox-google .checkbox-google-switch {
    display: inline-block;
    width: 36px;
    height: 14px;
    border-radius: 20px;
    position: relative;
    top: 6px;
    vertical-align: top;
    background: #9f9f9f;
    transition: .2s;
}

.checkbox-google .checkbox-google-switch:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -3px;
    left: -1px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: .15s;
}

.checkbox-google input[type=checkbox] {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.checkbox-google input[type=checkbox]:checked+.checkbox-google-switch {
    background: #282828;
}

.checkbox-google input[type=checkbox]:checked+.checkbox-google-switch:before {
    background: #E3E5E6;
    ;
    transform: translateX(18px);
}

/* Hover */
.checkbox-google input[type="checkbox"]:not(:disabled)+.checkbox-google-switch {
    cursor: pointer;
    border-color: rgba(0, 0, 0, .3);
}

/* Active/Focus */
.checkbox-google input[type="checkbox"]:not(:disabled):active+.checkbox-google-switch:before,
.checkbox-google input[type="checkbox"]:not(:disabled):focus+.checkbox-google-switch:before {
    animation: checkbox-active-on 0.5s forwards linear;
}

@keyframes checkbox-active-on {
    0% {
        box-shadow: 0 0 0 0 rgba(212, 212, 212, 0);
    }

    99% {
        box-shadow: 0 0 0 10px rgba(212, 212, 212, 0.5);
    }
}

.checkbox-google input[type="checkbox"]:not(:disabled):checked:active+.checkbox-google-switch:before,
.checkbox-google input[type="checkbox"]:not(:disabled):checked:focus+.checkbox-google-switch:before {
    animation: checkbox-active-off 0.5s forwards linear;
}

@keyframes checkbox-active-off {
    0% {
        box-shadow: 0 0 0 0 rgba(154, 190, 247, 0);
    }

    99% {
        box-shadow: 0 0 0 10px rgba(154, 190, 247, 0.5);
    }
}

/* Disabled */
.checkbox-google input[type=checkbox]:disabled+.checkbox-google-switch {
    filter: grayscale(60%);
    border-color: rgba(0, 0, 0, .1);
}

.checkbox-google input[type=checkbox]:disabled+.checkbox-google-switch:before {
    background: #eee;
}
</style>