<template>
    <div class="promotion">
        <BuyServiceModalVue v-show="isModal" @get-user-solution="checkConfirm()" @show-modal="isModal = false"
            :item="unique_tariff">
            <div class="promotion__tariff promotion__tariff-inside" v-for="tariff in tariffs" :key="tariff.id">
                <div class="promotion__tariff-icon">
                    <img :src="tariff.icon" alt="">
                </div>
                <div class="promotion__tariff-info">
                    <div class="promotion__tariff-name">
                        {{ tariff.name }}
                    </div>
                    <div class="promotion__tariff-description">
                        {{ tariff.description }}
                    </div>
                </div>
                <button class="promotion__tariff-button" @click="setTariff(tariff)">
                    {{ tariff.price }} ₸
                </button>
                <div class="promotion__tariff-divider"></div>
            </div>
        </BuyServiceModalVue>
        <div class="promotion__title">
            Продвижение товаров
        </div>
        <div class="promotion__divider"></div>
        <div class="promotion__services">
            <div class="promotion__tariffs">
                <div class="promotion__subtitle">
                    Единичный статусы
                </div>
                <div class="promotion__tariff" v-for="tariff in tariffs" :key="tariff.id">
                    <div class="promotion__tariff-icon">
                        <img :src="tariff.icon" alt="">
                    </div>
                    <div class="promotion__tariff-info">
                        <div class="promotion__tariff-name">
                            {{ tariff.name }}
                        </div>
                        <div class="promotion__tariff-description">
                            {{ tariff.description }}
                        </div>
                    </div>
                    <button class="promotion__tariff-button" @click="setTariff(tariff)">
                        {{ tariff.price }} ₸
                    </button>
                    <div class="promotion__tariff-divider"></div>
                </div>
            </div>
            <div class="promotion__package">
                <div class="promotion__subtitle">
                    Пакеты
                </div>
                <div class="promotion__tariff" v-for="item in packages" :key="item.id">
                    <div class="promotion__tariff-icon">
                        <img :src="item.icon" alt="">
                    </div>
                    <div class="promotion__tariff-info">
                        <div class="promotion__tariff-name">
                            {{ item.name }}
                        </div>
                        <div class="promotion__tariff-description">
                            {{ item.description }}
                        </div>
                    </div>
                    <button class="promotion__tariff-button" @click="setTariff(item)">
                        {{ item.price }} ₸
                    </button>
                    <div class="promotion__tariff-divider"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BuyServiceModalVue from '../../components/BuyServiceModal.vue'
export default {
    data() {
        return {
            tariffs: [
                {
                    id: 0,
                    icon: require('../../assets/icons/acc/vip.svg'),
                    name: 'Стать VIP  ',
                    description: 'Первые в списке',
                    price: 1500,
                },
                {
                    id: 1,
                    icon: require('../../assets/icons/acc/hot.svg'),
                    name: 'Отправить в горящие',
                    description: 'На главной странице Pharmart.kz',
                    price: 1000,
                },
                {
                    id: 2,
                    icon: require('../../assets/icons/acc/top.svg'),
                    name: 'Отправить в ТОП',
                    description: 'Выше аптек без статуса',
                    price: 500,
                },
            ],
            packages: [
                {
                    id: 0,
                    icon: require('../../assets/icons/acc/crown.svg'),
                    name: 'Пакет 3 в одном',
                    description: 'В 10 раз больше просмотров',
                    price: 4500,
                },
            ],
            unique_tariff: '',
            isModal: false,
        }
    },
    methods: {
        setTariff(item) {
            this.isModal = true
            this.unique_tariff = item
        },
        checkConfirm(confirm) {
            if (confirm) {
                alert('спасбио за покупку ')
                this.isModal = false
            }
        }
    },
    components: {
        BuyServiceModalVue
    }


}
</script>

<style lang="scss" scoped>
.promotion {
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #2C3131;
    }

    &__subtitle {
        font-weight: 600;
        font-size: 18px;
        color: #2C3131;
    }

    &__divider {
        width: 60vw;
        height: 1px;
        background-color: #c4c4c4;
        margin: 30px 0;
    }

    &__services {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 991px) {
            flex-direction: column;
        }
    }

    &__tariffs,
    &__package {
        width: 48%;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    &__package {
        @media (max-width: 991px) {
            margin-top: 60px;
        }
    }

    &__tariff {

        margin-top: 50px;
        width: 80%;
        display: flex;
        align-items: center;
        gap: 40px;
        position: relative;

        &-inside {
            width: 100%;
        }

        @media (max-width: 991px) {
            width: 100%;
        }

        &-divider {
            position: absolute;
            bottom: -30px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #E3E5E6;
        }

        &-icon {
            width: 50px;
        }

        &-info {
            width: 200px;
        }

        &-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #2C3131;
            margin-top: 5px;
        }

        &-name {
            font-weight: 500;
            font-size: 16px;
            color: #2C3131;
        }

        &-button {
            width: 125px;
            height: 28px;
            border: none;
            color: #fff;
            background: #2AC1CE;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                color: #2AC1CE;
                background: #fff;
                border: 1px solid #2AC1CE;
            }
        }
    }

}
</style>