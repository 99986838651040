<template>
    <div class="news">
        <div class="content">
            <div class="singleCategory__breadcrumbs">
                <span><a href="/">Главная</a></span>
                <span><a href=""> • Новости</a></span>
            </div>
            <div class="news__title">
                Новости
            </div>
            <div class="news__items">
                <div class="news__item" v-for="item in news" :key="item.id">
                    <NewsCardVue :background="item.img" :time="item.time" :views="item.views" :title="item.title"
                        :discription="item.discription" :link="item.link"></NewsCardVue>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewsCardVue from '../components/NewsCard.vue'
export default {
    data() {
        return {
            news: [
                {
                    id: 0,
                    img: require('../assets/img/card/img.png'),
                    time: '22.01.22',
                    views: '1111',
                    title: 'Задача организации, в особенности же укрепление и развитие структуры',
                    discription: 'Повседневная практика показывает, что дальнейшее развитие различных форм деятельности требуют от нас...',
                    link: 'full-news',
                    category: 'Цена'
                },
                {
                    id: 1,
                    img: require('../assets/img/card/img-1.png'),
                    time: '22.01.22',
                    views: '1111',
                    title: 'Задача организации, в особенности же укрепление и развитие структуры',
                    discription: 'Повседневная практика показывает, что дальнейшее развитие различных форм деятельности требуют от нас...',
                    link: 'full-news',
                    category: 'Цена'
                },
                {
                    id: 2,
                    img: require('../assets/img/card/img-2.png'),
                    time: '22.01.22',
                    views: '1111',
                    title: 'Задача организации, в особенности же укрепление и развитие структуры',
                    discription: 'Повседневная практика показывает, что дальнейшее развитие различных форм деятельности требуют от нас...',
                    link: 'full-news',
                    category: 'Цена'
                },
                {
                    id: 3,
                    img: require('../assets/img/card/img.png'),
                    time: '22.01.22',
                    views: '1111',
                    title: 'Задача организации, в особенности же укрепление и развитие структуры',
                    discription: 'Повседневная практика показывает, что дальнейшее развитие различных форм деятельности требуют от нас...',
                    link: 'full-news',
                    category: 'Потребительский рынок'
                },
                {
                    id: 4,
                    img: require('../assets/img/card/img.png'),
                    time: '22.01.22',
                    views: '1111',
                    title: 'Задача организации, в особенности же укрепление и развитие структуры',
                    discription: 'Повседневная практика показывает, что дальнейшее развитие различных форм деятельности требуют от нас...',
                    link: 'full-news',
                    category: 'Цена'
                },
                {
                    id: 5,
                    img: require('../assets/img/card/img-1.png'),
                    time: '22.01.22',
                    views: '1111',
                    title: 'Задача организации, в особенности же укрепление и развитие структуры',
                    discription: 'Повседневная практика показывает, что дальнейшее развитие различных форм деятельности требуют от нас...',
                    link: 'full-news',
                    category: 'Цена'
                },
                {
                    id: 6,
                    img: require('../assets/img/card/img-2.png'),
                    time: '22.01.22',
                    views: '1111',
                    title: 'Задача организации, в особенности же укрепление и развитие структуры',
                    discription: 'Повседневная практика показывает, что дальнейшее развитие различных форм деятельности требуют от нас...',
                    link: 'full-news',
                    category: 'Цена'
                },
                {
                    id: 7,
                    img: require('../assets/img/card/img.png'),
                    time: '22.01.22',
                    views: '1111',
                    title: 'Задача организации, в особенности же укрепление и развитие структуры',
                    discription: 'Повседневная практика показывает, что дальнейшее развитие различных форм деятельности требуют от нас...',
                    link: 'full-news',
                    category: 'Потребительский рынок'
                },

            ],
        }
    },
    components: {
        NewsCardVue
    }
}
</script>

<style lang="scss">
@import '../assets/style/news.scss';
</style>